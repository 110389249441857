import React from 'react';
import { useRouteError } from "react-router-dom";
import Lottie from "lottie-react";
import fourofore from "./lottie/404.json";

export default function ErrorPage() {
  const error = useRouteError();
  //console.error(error);

  return (
    <Lottie animationData={fourofore} loop={true} style={{ height: '100vh', marginTop: '30px' }} />
  );
}