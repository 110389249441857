import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import 'react-loading-skeleton/dist/skeleton.css'
import Grid2 from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import { resolveYear } from '../utils.js';

import {
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

import toast from 'react-hot-toast';

import { BookmarkIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';

import { trackPageview, trackEvent } from "./common/plausible.js";

import BasicSection from "./district/basic/basicSection.js";
import SqmPriceSection from "./district/basic/sqmPriceSection.js";
import TransactionCalendarSection from "./district/basic/transactionCalendarSection.js";
import TopSellerSection from "./district/basic/topSellersSection.js";
import PriceDistributionSection from "./district/analytics/priceDistributionSection.js";
import ResaleSection from "./district/analytics/ResaleSection.js";
import UpcomingProjectsSection from "./district/projects/upcomingProjectsSection.js";
import DevelopersDistributionSection from "./district/developers/developersDistributionSection.js";
import DevelopersRatingsSection from "./district/developers/developersRatingsSection.js";
import DelayedProjectsSection from "./district/projects/delayedProjectsSection.js";
import NewProjectsSection from "./district/projects/newProjectsSection.js";

import FilterAltIcon from '@mui/icons-material/FilterAlt';

import CircularProgress from '@mui/material/CircularProgress';


import CustomizedTooltips from "./common/tooltip.js";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';

import StickyBox from "react-sticky-box";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import apiInstance from '../controllers/secureApi.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const District = (props) => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [tab, setTab] = React.useState(0);

  const scrollRef = useRef(null);
  const [year, setYear] = React.useState({
    start: 2023,
    end: 2023,
    key: 'previous_year'
  });

  const [propType, setPropType] = React.useState({
    filter: 'fv',
    key: 'all'
  });

  const [regType, setRegType] = React.useState({
    filter: 'or',
    key: 'all'
  });

  const [roomsType, setRoomsType] = React.useState({
    filter: 'all',
    key: 'all'
  });

  const resolveFilters = (ignore) => {
    let countFilters = 0;
    let applied = []
    if (propType.key !== 'all' && !ignore?.includes('propType')) {
      countFilters = countFilters + 1;
      applied.push('Property types')
    }
    if (regType.key !== 'all' && !ignore?.includes('regType')) {
      countFilters = countFilters + 1;
      applied.push('Registration types')
    }
    if (roomsType.key !== 'all' && !ignore?.includes('roomsType')) {
      countFilters = countFilters + 1;
      applied.push('Unit types')
    }
    return {
      value: countFilters > 0 ? `+${countFilters}` : 0,
      filters: applied.join(', ')
    }
  }

  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [isLikedLoading, setIsLikedLoading] = useState(true);

  const controllerRef = useRef(null);

  const handleYearChange = (event) => {
    const choice = event.target.value;
    const options = {
      this_year: {
        start: 2024,
        end: 2024,
        key: 'this_year'
      },
      previous_year: {
        start: 2023,
        end: 2023,
        key: 'previous_year'
      },
      last_2_years: {
        start: 2022,
        end: 2023,
        key: 'last_2_years'
      },
      last_3_years: {
        start: 2021,
        end: 2023,
        key: 'last_3_years'
      }
    }
    setYear(options[choice]);
  };

  const handlePropTypeChange = (event) => {
    const choice = event.target.value;
    const options = {
      all: {
        filter: 'fv',
        key: 'all'
      },
      flats: {
        filter: 'f',
        key: 'flats'
      },
      villas: {
        filter: 'v',
        key: 'villas'
      }
    }
    setPropType(options[choice]);
  };

  const handleRegTypeChange = (event) => {
    const choice = event.target.value;
    const options = {
      all: {
        filter: 'or',
        key: 'all'
      },
      offplan: {
        filter: 'o',
        key: 'offplan'
      },
      ready: {
        filter: 'r',
        key: 'ready'
      }
    }
    setRegType(options[choice]);
  };

  const handleRoomsTypeChange = (event) => {
    const choice = event.target.value;
    const options = {
      all: {
        filter: 'all',
        key: 'all'
      },
      s: {
        filter: 's',
        key: 's'
      },
      1: {
        filter: '1',
        key: '1'
      },
      2: {
        filter: '2',
        key: '2'
      },
      3: {
        filter: '3',
        key: '3'
      },
      4: {
        filter: '4',
        key: '4'
      },
      5: {
        filter: '5',
        key: '5'
      },
      p: {
        filter: 'p',
        key: 'p'
      }
    }
    setRoomsType(options[choice]);
    console.log(options[choice])
  };

  const handleChange = (event, newValue) => {
    trackEvent('district-tab-click', { props: { tab: `district-tab-${newValue}` } })
    console.log(newValue)
    setTab(newValue);
  };

  const handleDistrictLike = async (id) => {
    if (!user || !id) {
      toast.error('Please login to add it to favourites.');
      return;
    }
    setIsLikedLoading(true);
    const { data } = await apiInstance.post(`/like`, {
      districtId: id,
      type: 'district',
      liked: !isLiked
    });
    setIsLiked(data.liked);
    setIsLikedLoading(false);
  }

  useEffect(() => {
    console.log('props.region', props.region);
    setSelectedName(props.region?.name);
    setSelectedId(props.region?.id);
    if (props.region?.id) {
      trackPageview({}, { props: { district: `${props.region?.id}`, districtName: `${props.region?.name}` } })
    }

  }, [props.region]);

  useEffect(() => {

    async function fetchIsLiked() {
      if (!user || !selectedId) {
        setIsLikedLoading(false);
        return;
      }
      setIsLikedLoading(true);
      const { data } = await apiInstance.get(`/like`, {
        params: {
          districtId: selectedId,
        }
      });
      setIsLiked(data.liked);
      setIsLikedLoading(false);
    }

    async function abortPrevious() {
      if (!selectedId) return;
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      const controller = new AbortController();
      controllerRef.current = controller;
      // scrollToTop()
      controllerRef.current = null;
    }
    abortPrevious();
    fetchIsLiked();
  }, [selectedId, year, user, dbUser]);

  const scrollToTop = () => {
    if (!scrollRef.current) return;
    scrollRef.current.scroll({
      top: 0,
      behavior: "smooth"
    });
  };

  const selectProject = (id) => {
    props.selectProject(id);
  }

  const setSearchFunction = (id) => {
    props.setSearchFunction(id);
  }

  return (
    <>
      {selectedId ? (
        <Box
          sx={{
            flexGrow: 1,
            width: { lg: 800, md: 600, xs: '100%' },
            position: 'absolute',
            zIndex: 'modal',
            right: 0,
            top: 0,
            bottom: 0,
            margin: { lg: 4, md: 2, xs: 0 },
            marginTop: { lg: 12, md: 10, xs: 0 },
            pt: 0
          }}
        >
          <Box
            sx={{
              p: 3,
              width: { lg: 800, md: 600, xs: '100%' }
            }}
            style={{ position: 'fixed', backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.08)', zIndex: 100 }}
          >
            {/* <Grid2
              md={12}
              justify="space-between" // Add it here :)
              container
              direction="row"
            >
              <Grid2 xs={11}>
                <Typography variant="h1" noWrap={true}>{selectedName}</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography variant="subtitle1">Dubai</Typography>
                  <Typography variant="subtitle1">{selectedName}</Typography>
                </Breadcrumbs>
              </Grid2>

              <Grid2 xs={1} style={{ align: 'right' }}>
                <div style={{ textAlign: 'right' }}>
                  <IconButton aria-label="close" size="meddium" component="label" onClick={() => { setSelectedId(null); setSearchFunction('district', null) }} pb={0}>
                    <HighlightOffIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </Grid2>
            </Grid2> */}

            <Grid2
              md={12}
              justify="space-between" // Add it here :)
              container
              spacing={2}
              direction="row"
            >
              <Grid2 item xs={2} sm={1} style={{ align: 'left' }} >
                <div style={{ textAlign: 'left' }}>
                  <IconButton aria-label="close" size="meddium" component="label" onClick={() => { setSelectedId(null); setSearchFunction('district', null) }} pb={0}>
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </Grid2>
              <Grid2 item xs={8} sm={10}>
                <Typography variant="h1" noWrap={true}>{selectedName}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Breadcrumbs aria-label="breadcrumb" sx={{paddingRight: {xs: 0, sm: '10px'}}}>
                    <Link color="inherit" href="/dubai">
                    <Typography variant="subtitle1">Dubai</Typography>
                    </Link>
                    <Typography variant="subtitle1">{selectedName}</Typography>
                  </Breadcrumbs>
                  <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                  <WhatsappShareButton title={`District ${selectedName} on MAPPI`} url={window.location.href} style={{ display: 'flex' }}>
                    <WhatsappIcon size={18} round />
                  </WhatsappShareButton>
                  <TelegramShareButton title={`District ${selectedName} on MAPPI`} url={window.location.href} style={{ paddingLeft: 10, display: 'flex' }}>
                    <TelegramIcon size={18} round />
                  </TelegramShareButton>
                  <EmailShareButton subject={`District ${selectedName} on MAPPI`} body={`Check this District on MAPPI:\n\n ${selectedName}\n`} url={window.location.href} style={{ paddingLeft: 10, display: 'flex' }}>
                    <EmailIcon size={18} round />
                  </EmailShareButton>
                  </Box>
                </Box>
              </Grid2>
              <Grid2 item xs={2} sm={1} style={{ align: 'right' }} >
                <div style={{ textAlign: 'left' }}>
                  {isLikedLoading ? (
                    <IconButton aria-label="close" size="meddium" component="label" pb={0}>
                      <CircularProgress style={{ width: 25, height: 25, margin: '2px' }} color="inherit" />
                    </IconButton>
                  ) : (<IconButton aria-label="close" size="meddium" component="label" onClick={async () => { await handleDistrictLike(selectedId) }} pb={0}>
                    {isLiked ?
                      <BookmarkIconSolid stroke-width="1.5" style={{ color: '#000', width: 25, margin: '2px' }} /> :
                      <BookmarkIcon stroke-width="1.5" style={{ color: '#000', width: 25, margin: '2px' }} />
                    }
                  </IconButton>)
                  }
                </div>
              </Grid2>
            </Grid2>

          </Box>

          <Box
            sx={{
              p: 3,
              pb: 1,
              mt: 10,
              width: { lg: 800, md: 600, xs: '100%' }
            }}
            style={{ position: 'fixed', backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.08)', zIndex: 100 }}
          >
            <Grid2
              md={12}
              justify="space-between" // Add it here :)
              container
              spacing={2}
              direction="row"
            >
              <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('district.tabs.titleOne')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('district.tabs.subTitleOne')}</Typography>
                  </>
                } {...a11yProps(0)} />
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('district.tabs.titleTwo')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('district.tabs.subTitleTwo')}</Typography>
                  </>
                } {...a11yProps(1)} />
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('district.tabs.titleThree')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('district.tabs.subTitleThree')}</Typography>
                  </>
                } {...a11yProps(1)} />
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('district.tabs.titleFour')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('district.tabs.subTitleFour')}</Typography>
                  </>
                } {...a11yProps(2)} />
              </Tabs>
            </Grid2>
          </Box>

          <Box ref={scrollRef} sx={{ flexGrow: 1, p: 0, pt: 19 }} className="bg-white shadow-darken10 round" style={{ width: '100%', height: '100%', overflow: 'scroll' }}>

            <StickyBox offsetTop={2} offsetBottom={20}
              style={{ background: '#f8f8f8', zIndex: 100000, borderWidth: 0, borderStyle: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderBottomWidth: 'thin', borderTopWidth: 'thin', }}
            >
              <Grid2 xs={12} md={12} p={2} pb={1} pt={1}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Grid2 xs={12} md={12} p={0}>
                    <FilterAltIcon fontSize="small" sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '12px', display: { xs: "none", lg: "inline-flex" } }} />
                    <FormControl sx={{ m: 1, minWidth: { xs: 90, sm: 100 } }} size="small">
                      <InputLabel id="demo-select-small-label">{t('district.filters.year')}</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={year.key}
                        label="Year"
                        style={{ height: 29, fontSize: 13 }}
                        onChange={handleYearChange}
                      >
                        <MenuItem value={'this_year'} key={2023}>This year</MenuItem>
                        <MenuItem value={'previous_year'} key={2022}>Previous year</MenuItem>
                        <MenuItem value={'last_2_years'} key={2021}>Last 2 years</MenuItem>
                        <MenuItem value={'last_3_years'} key={2020}>Last 3 years</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: { xs: 100, sm: 110 } }} size="small">
                      <InputLabel id="demo-select-small-label">{t('district.filters.propTypes')}</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={propType.key}
                        label="Property types"
                        helperText="Please select your currency"
                        style={{ height: 29, fontSize: 13 }}
                        onChange={handlePropTypeChange}
                      >
                        <MenuItem value={'all'} key={2023}>All types</MenuItem>
                        <MenuItem value={'flats'} key={2022}>Flats/Apartments</MenuItem>
                        <MenuItem value={'villas'} key={2021}>Villas</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: { xs: 120, sm: 130 } }} size="small">
                      <InputLabel id="demo-select-small-label">{t('district.filters.regTypes')}</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={regType.key}
                        label="Registration types"
                        style={{ height: 29, fontSize: 13 }}
                        onChange={handleRegTypeChange}
                      >
                        <MenuItem value={'all'} key={2023}>All types</MenuItem>
                        <MenuItem value={'offplan'} key={2022}>Off-plan</MenuItem>
                        <MenuItem value={'ready'} key={2021}>Ready</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: { xs: 120, sm: 130 } }} size="small">
                      <InputLabel id="demo-select-small-label">{t('district.filters.unitTypes')}</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={roomsType.key}
                        label="Unit types"
                        style={{ height: 29, fontSize: 13 }}
                        onChange={handleRoomsTypeChange}
                      >
                        <MenuItem value={'all'} key={'all'}>All types</MenuItem>
                        <MenuItem value={'s'} key={'s'}>Studio</MenuItem>
                        <MenuItem value={'1'} key={'1'}>1 B/R</MenuItem>
                        <MenuItem value={'2'} key={'2'}>2 B/R</MenuItem>
                        <MenuItem value={'3'} key={'3'}>3 B/R</MenuItem>
                        <MenuItem value={'4'} key={'4'}>4 B/R</MenuItem>
                        <MenuItem value={'5'} key={'5'}>5 B/R</MenuItem>
                        <MenuItem value={'p'} key={'p'}>Penthouse</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid2>
                </Box>
              </Grid2>
            </StickyBox>

            <TabPanel value={tab} index={0} sx={{ p: 0 }}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={12} container spacing={1}>
                  <Grid2 xs={12} md={12} container>
                    <Grid2 xs={7} lg={9} pb={0}>

                      <Typography variant="h1">
                        {t('district.basicSection.title')} {resolveYear(year)}
                        <Tooltip title={`${resolveFilters('propType').filters} filter applied.`}>
                          <Badge badgeContent={resolveFilters('propType').value} color="info" style={{ transform: 'translate(11px, -10px)' }}></Badge>
                        </Tooltip>
                      </Typography>
                      <Typography variant="subtitle1" mb={1}>{t('district.basicSection.subTitle')}<CustomizedTooltips title={t('district.basicSection.tooltip')} /></Typography>
                    </Grid2>
                    <Grid2 xs={12} md={12} pt={0}>
                      <Divider flexItem />
                    </Grid2>
                  </Grid2>

                  <BasicSection selectedId={selectedId} year={year} roomsType={roomsType} resolveFilters={resolveFilters} regType={regType} />
                  <SqmPriceSection user={user} year={year} selectedId={selectedId} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
                  <TransactionCalendarSection year={year} selectedId={selectedId} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
                </Grid2>
                <TopSellerSection year={year} selectedId={selectedId} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
              </Grid2>
            </TabPanel>

            <TabPanel value={tab} index={1} sx={{ p: 0 }}>
              <Grid2 container spacing={2}>
                <Grid2 xs={7} md={9}>
                  <Typography variant="h1">
                    {t('district.analyticsSection.distribution.title')} {resolveYear(year)}
                    <Tooltip title={`${resolveFilters().filters} filters applied.`}>
                      <Badge badgeContent={resolveFilters().value} color="info" style={{ transform: 'translate(11px, -10px)' }}></Badge>
                    </Tooltip>
                  </Typography>
                  <Typography variant="subtitle1" mb={1}>{t('district.analyticsSection.distribution.subTitle')}
                    <CustomizedTooltips title={t('district.analyticsSection.distribution.tooltip')} />
                  </Typography>
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <Divider flexItem />
                </Grid2>
              </Grid2>

              <PriceDistributionSection selectedId={selectedId} selectProject={selectProject} year={year} propType={propType} regType={regType} resolveFilters={resolveFilters} roomsType={roomsType} />
              <ResaleSection user={user} dbUser={dbUser} selectedId={selectedId} selectProject={selectProject} />
            </TabPanel>

            <TabPanel value={tab} index={2} sx={{ p: 0 }}>
              <Grid2 container spacing={2}>
                <NewProjectsSection selectedId={selectedId} selectProject={selectProject} />
                <DelayedProjectsSection selectedId={selectedId} selectProject={selectProject} />
                <UpcomingProjectsSection selectedId={selectedId} selectProject={selectProject} />
              </Grid2>

            </TabPanel>

            <TabPanel value={tab} index={3} sx={{ p: 0 }}>
              <Grid2 container spacing={2}>
                <DevelopersDistributionSection selectedId={selectedId} selectProject={selectProject} selectedName={selectedName} />
                <DevelopersRatingsSection selectedId={selectedId} selectProject={selectProject} />
              </Grid2>
            </TabPanel>

          </Box>
        </Box>
      ) : (null)}
    </>
  );
};

export default District;
