import React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import {auth} from "./firebase";
import GoogleButton from './components/common/googleBtn/GoogleButton';
import Menu from './components/Menu';

import { trackEvent } from './components/common/plausible';

import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";

export default function SignIn() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = React.useState(null);

  const { register, handleSubmit, watch, formState: { errors } } = useForm(
    {
      mode: "onSubmit",
      reValidateMode: "onSubmit",
    }
  );
  const redirect = searchParams.get('redirect');

  const onSubmit = data => {
    logInWithEmailAndPassword(data.emailRequired, data.passwordRequired);
  }

  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {
      setErrorMessage(null);
      await signInWithPopup(auth, googleProvider);
      const newUser = auth.currentUser.metadata.creationTime === auth.currentUser.metadata.lastSignInTime;
      console.log("existing", newUser)
      console.log("auth.currentUser.metadata.lastSignInTime", auth.currentUser.metadata.creationTime, auth.currentUser.metadata.lastSignInTime)
      if (!newUser) {
        console.log("redirect", redirect)
        navigate(redirect ? `${redirect}` : '/');
        trackEvent('Login', { method: 'google' })
      } else {
        navigate('/checkout');
        trackEvent('Register', { method: 'google' })
      }
    } catch (err) {
      console.log(err)
      setErrorMessage('Could not sign in with Google');
    }
  };
  const logInWithEmailAndPassword = async (email, password) => {
    try {
      setErrorMessage(null);
      await signInWithEmailAndPassword(auth, email, password);
      const newUser = auth.currentUser.metadata.creationTime === auth.currentUser.metadata.lastSignInTime;
      console.log("existing", newUser)
      console.log("auth.currentUser.metadata.lastSignInTime", auth.currentUser.metadata.creationTime, auth.currentUser.metadata.lastSignInTime)
      if (!newUser) {
        navigate(redirect ? `${redirect}` : '/');
        trackEvent('Login', { method: 'email' })
      } else {
        navigate('/checkout');
        trackEvent('Register', { method: 'email' })
      }
    } catch (err) {
      setErrorMessage('Something is wrong with your email or password...worth checking.');
    }
    
  };
  // const registerWithEmailAndPassword = async (name, email, password) => {
  //   try {
  //     const res = await createUserWithEmailAndPassword(auth, email, password);
  //     const user = res.user;
  //     await addDoc(collection(db, "users"), {
  //       uid: user.uid,
  //       name,
  //       authProvider: "local",
  //       email,
  //     });
  //   } catch (err) {
  //     console.error(err);
  //     alert(err.message);
  //   }
  // };
  // const sendPasswordReset = async (email) => {
  //   try {
  //     await sendPasswordResetEmail(auth, email);
  //     alert("Password reset link sent!");
  //   } catch (err) {
  //     setErrorMessage(err.message);
  //   }
  // };
  // const logout = () => {
  //   signOut(auth);
  //   navigate('/');
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   logInWithEmailAndPassword(data.get("email"), data.get("password"));
  // };

  return (
    <>
      <Container component="main" maxWidth="xs" sx={{mt: 20}}>
        <Box
          sx={{  
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h1">
            Welcome to Mappi
          </Typography>
          <Typography component="subtitle1" variant="subtitle1" pt={1}>
            Continue with your Google or enter your credentials.
          </Typography>
          
          <GoogleButton type={'light'} style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16, marginTop: 24}}
            onClick={signInWithGoogle}
          />
          </Box>

          <Divider>or</Divider>

          {errorMessage ? (
            <Alert severity="error" variant="filled" >{errorMessage}</Alert>
          ) : null}

          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              helperText={errors.emailRequired && (errors.emailRequired.message || 'This field is required')}
              autoComplete="email"
              {...register("emailRequired", { 
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format"
                }
              })}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              helperText={errors.passwordRequired && 'This field is required'}
              type="password"
              id="password"
              autoComplete="current-password"
              {...register("passwordRequired", { required: true })}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/reset-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  Don't have an account? <b>Sign Up</b>
                </Link>
              </Grid>
            </Grid>
        </Box>
      </Container>
    </>
  );
}
