import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import { Bathroom, Bed, Frame } from 'iconoir-react';

import Skeleton from 'react-loading-skeleton'

const DummyItem = (props) => {
  const { navigate, isLoader, item } = props;
  return (
    <Grid2 item xs={12} md={4} p={2}>
      <Card onClick={() => navigate('/broker/verify')}>
        <CardActionArea>
          <CardMedia
            component={() => (
              <Box sx={{ height: 240, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: 2, flexDirection: 'column' }}>
                {isLoader ? (
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <Skeleton width={'100%'} height={'100%'} />
                  </Box>
                ) : (
                  <>
                    <Typography variant="h5" component="h2" fontWeight={400}>
                      This property is accessible for Real Estate Agents exclusively. You can verify your Agent's account in less than 1 minute.
                    </Typography>
                    <Button size="small" color="primary" variant='contained' sx={{ fontSize: '14px', marginTop: 3 }}>
                      Verify your account
                    </Button>
                  </>
                )}
              </Box>
            )}
            height="240"
          />
          <CardContent>
            <Typography gutterBottom variant="h1" component="div">
              {isLoader ? <Skeleton /> : item?.project?.name}
            </Typography>
            <Typography gutterBottom variant="h3" component="div" pb={1} sx={{ fontWeight: 800 }}>
              <Skeleton />
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ minHeight: '60px', maxHeight: '60px' }}>
              <Skeleton count={3} />
            </Typography>
            <Divider sx={{ padding: 1 }} />
            <Grid2 container xs={12} md={12} pt={2} pb={2}>
              <Grid2 container xs={4} md={4} sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>

              </Grid2>
              <Grid2 container xs={2.5} md={2.5} sx={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <Bed strokeWidth={2} color='black' />
                <Typography variant="body2" sx={{ marginTop: '3px' }}><Skeleton width={30} /></Typography>
              </Grid2>
              <Grid2 container xs={2.5} md={2.5} sx={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <Bathroom strokeWidth={2} color='black' />
                <Typography variant="body2" sx={{ marginTop: '3px' }}><Skeleton width={30} /></Typography>
              </Grid2>
              <Grid2 container xs={3} md={3} sx={{ alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
                <Frame strokeWidth={2} color='black' />
                <Typography variant="body2" sx={{ marginTop: '3px' }}><Skeleton width={30} /> </Typography>
              </Grid2>
            </Grid2>
            <Divider />
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Grid2 container xs={12} md={12} pr={1} pl={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h2" sx={{ marginTop: '3px', color: '#fc5c65', fontWeight: 800 }}><Skeleton width={100} variant="rectangular" sx={{ bgcolor: 'grey.900' }} /></Typography>
              <Typography variant="subtitle1" sx={{ marginTop: '3px' }}><Skeleton width={100} variant="rectangular" sx={{ bgcolor: 'grey.900' }} /></Typography>
            </Box>
          </Grid2>
        </CardActions>
        <CardHeader
          avatar={
            <Skeleton width={27} height={27} circle>
            </Skeleton>
          }
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          title={<Skeleton width={40} />}
          subheader={<Skeleton width={40} />}
        />
      </Card>
    </Grid2>
  )
}

export default DummyItem