import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from "react-helmet"
import { useNavigate, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Container from "@mui/material/Container";
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Menu from './components/Menu';
import NewMenu from './components/NewMenu';
import { } from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SubscriptionContext from "./contexts/SubscriptionContext";
import API from './controllers/secureApi.js';
import Divider from '@mui/material/Divider';
import Skeleton from 'react-loading-skeleton'
import { t } from 'i18next';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { trackEvent } from './components/common/plausible';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';


import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import StarIcon from "@mui/icons-material/StarBorder";
import { styled } from "@mui/system";

const PricingList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

export default function Checkout() {

  const navigate = useNavigate();
  const { user } = useContext(SubscriptionContext);
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [priceFileter, setPriceFilter] = useState('month')

  const [prices, setPrices] = useState([]);
  // const [subscriptionData, setSubscriptionData] = useState(null);

  const createSubscription = async (priceId) => {
    const { data } = await API.post('/billing/create-subscription', {
      priceId,
    })

    // setSubscriptionData({ subscriptionId, clientSecret });
    // redirect user!!!! and paas subscriptionId and clientSecret
    navigate('/payment', { state: { clientSecret: data.clientSecret } })
  }

  useEffect(() => {
    const fetchUser = async (user) => {
      const { data } = await API.get('/user/me', {
        user
      })
      setUserData(data)
      console.log("/user/me data:", data)
      setLoading(false);
    }

    if (!user) return;
    if(user?.subscriptionStatus === 'active') {
      navigate('/wallet')
    }
    fetchUser(user)
  }, [user]);

  useEffect(() => {
    const fetchPrices = async () => {
      const { data } = await API.get('/billing/pricing')
      console.log("prices:", data.prices)
      setPrices(data.prices);
    };

    fetchPrices()
    trackEvent('Checkout')
  }, []);

  // useEffect(() => {
  //   const filtered = prices.filter((price) => {
  //     if (price.recurring.interval === priceFileter) {
  //       return price
  //     }
  //   })
  //   setPrices(filtered)

  // }, [priceFileter])

  // if(subscriptionData) {
  //   return <Redirect to={{
  //     pathname: '/payment',
  //     state: subscriptionData
  //   }} />
  // }

  return (
    <>
      <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} md={12} pb={3}>
            <Grid2 xs={12} md={12} pb={1} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Typography variant="h1" sx={{
                fontSize: '2rem',
                pb: 1,
              }}>
                {t('checkout.title')}
              </Typography>
              <Typography variant="subtitle1">{t('checkout.subTitle')}</Typography>
            </Grid2>
            <Divider />
          </Grid2>

          <Grid2 item xs={12} md={12} p={3} pb={5}>
            <Grid2 container spacing={5} alignItems={'center'} justifyContent={'center'}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{t('checkout.monthly')}</Typography>
                <Switch inputProps={{ 'aria-label': 'ant design' }} onChange={(e) => {
                  console.log("e.target.checked", e.target.checked)
                  if (e.target.checked) {
                    setPriceFilter('year')
                  } else {
                    setPriceFilter('month')
                  }
                }} />
                <Typography>{t('checkout.yearly')}</Typography>
              </Stack>
            </Grid2>
          </Grid2>

          <Grid2 item xs={12} md={12} pb={3}>
            <Grid2 container spacing={5} alignItems={'center'} justifyContent={'center'}>
              <Grid2
                item
                key={0}
                xs={12}
                md={4}
              >
                <Card padding={6} sx={{ minWidth: '270px', boxShadow: 'none', border: '1px solid #ebebeb', }}>
                  <CardHeader
                    title={<Typography variant='h1' fontSize={30}>{t('checkout.free')}</Typography>}
                    subheader={<Typography variant="subtitle1" pt={2}>{t('checkout.freeDesc')}</Typography>}
                    titleTypographyProps={{ align: "center" }}
                    action={'tier.title' === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      border: '1px solid #ebebeb',
                      padding: '30px',
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                        mb: 2,
                        mt: 2,
                      }}
                    >
                      <Typography variant="h1" sx={{
                        fontSize: '3rem',
                        fontWeight: 'bold',
                      }}>
                        {t('checkout.freeCaps')}
                      </Typography>
                      <Typography variant="subtitle3" pl={1}>
                      {t('checkout.freeForever')}
                      </Typography>
                    </Box>
                    <PricingList sx={{
                      paddingTop: 3,
                    }}>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        pb: 2,
                        minHeight: '100px',
                      }}>
                        <CheckBadgeIcon stroke-width="1.5" style={{ color: '#000', width: 15, marginRight: 5 }} />
                        <Typography
                          component="li"
                          variant="h5"
                          align="left"
                          color={'#84817a'}
                          key={'free'}
                        >
                          {t('checkout.basicInfo')}
                        </Typography>
                      </Box>
                    </PricingList>
                  </CardContent>
                  <CardActions sx={{
                    padding: '30px',
                  }}>
                    <Button onClick={() => {
                      navigate('/')
                      trackEvent('Checkout-free')
                    }} fullWidth variant={'outlined'}>
                      {t('checkout.freeCTA')}
                    </Button>
                  </CardActions>
                </Card>
              </Grid2>
              {prices.filter((price) => {
                if (price.recurring.interval === priceFileter) {
                  return price
                }
              }).map((price) => (
                <Grid2
                  item
                  key={price.id}
                  xs={12}
                  md={4}
                >
                  <Card padding={6} sx={{ boxShadow: 'none', border: '1px solid #ebebeb', }}>
                    <CardHeader
                      title={<Typography variant='h1' fontSize={30}>{price.product.name.toUpperCase()}</Typography>}
                      subheader={<Typography variant="subtitle1" pt={2}>{price.product.description || null}</Typography>}
                      titleTypographyProps={{ align: "center" }}
                      action={'tier.title' === "Pro" ? <StarIcon /> : null}
                      subheaderTypographyProps={{
                        align: "center",
                      }}
                      sx={{
                        border: '1px solid #ebebeb',
                        padding: '30px',
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                      }}
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "baseline",
                          mb: 2,
                          mt: 2,
                        }}
                      >
                        <Typography variant="h1" sx={{
                          fontSize: '3rem',
                          fontWeight: 'bold',
                        }}>
                          €{price.unit_amount / 100}
                        </Typography>
                        <Typography variant="subtitle3" pl={1}>
                          per {price.recurring.interval}
                        </Typography>
                      </Box>
                      <PricingList sx={{
                        paddingTop: 3,
                      }}>
                        {price.product.features.map((line) => (
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            pb: 2,
                          }}>
                            <CheckBadgeIcon stroke-width="1.5" style={{ color: '#000', width: 15, marginRight: 5 }} />
                            <Typography
                              component="li"
                              variant="h5"
                              align="left"
                              color={'#84817a'}
                              key={line.name}
                            >
                              {line.name}
                            </Typography>
                          </Box>
                        ))}
                      </PricingList>
                    </CardContent>
                    <CardActions sx={{
                      padding: '30px',
                    }}>
                      <Button onClick={() => createSubscription(price.id)} fullWidth variant={'contained'}>
                      {t('checkout.proCTA')}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid2>
              ))}
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </>
  );
}
