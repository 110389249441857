const IconParkDubai = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
<g fill="none" stroke="#000" strokeWidth="3">
    <path strokeLinecap="round" d="M14 4L14 44"></path>
    <path d="M14.5 6C14.5 6 28 13 32 22C36 31 33 44 33 44"></path>
    <path strokeLinecap="round" strokeLinejoin="round" d="M4 44H44"></path>
    <path strokeLinecap="round" d="M10 15H32"></path>
    <path strokeLinecap="round" d="M14 22H22"></path>
    <path strokeLinecap="round" d="M14 29H26"></path>
    <path strokeLinecap="round" d="M14 36H27"></path>
</g>
</svg>
)

export default IconParkDubai