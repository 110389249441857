import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { formatAmount, formatNumber } from '../../../utils';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';


const BasicSection = (props) => {
  const { t } = useTranslation();
  const { selectedId, year, roomsType, resolveFilters, regType } = props;
  // Basic Data
  const [basicData, setBasicData] = useState(null);
  const [loadingB, setBLoading] = useState(true);

  useEffect(() => {
    async function fetchBasicData() {
      if (!selectedId) {
        setBLoading(false);
        return
      }
      setBLoading(true);
      // Basic Data
      // const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?year=${resolveYear(year)}`, controller.signal)
      const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?yearstart=${year.start}&yearend=${year.end}&roomsTypeFilter=${roomsType.filter}&regTypeFilter=${regType.filter}`)
      await setBasicData(bData);
      console.log('bData', bData);
      setBLoading(false);
    }
    fetchBasicData();
  }, [selectedId, year, roomsType, regType]);

  return (
    <>
      <Grid2 xs={6} md={4} pt={2}>
        <Box>
          <Typography variant="subtitle2">{t('district.basicSection.total')}
            <Tooltip title={`${resolveFilters(['propType', 'roomsType']).filters} filters applied.`}>
              <Badge badgeContent={resolveFilters(['propType', 'roomsType']).value} color="info" variant="dot" style={{ transform: 'translate(11px, -10px)' }}></Badge>
            </Tooltip>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatNumber(basicData?.total?._count)}`}</Typography>
        </Box>
        <Grid2 xs={12} md={12} container spacing={2} pt={2}>
          <Grid2 xs={12} md={6} pl={0}>
            <Box>
              <Typography variant="subtitle2">{t('district.basicSection.average')}</Typography>
            </Box>
            <Box>
              <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatAmount(basicData?.total?._avg)}`} <Typography variant="smallCurrency">AED</Typography></Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box>
              <Typography variant="subtitle2">{t('district.basicSection.max')}</Typography>
            </Box>
            <Box>
              <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatAmount(basicData?.total?._max)}`} <Typography variant="smallCurrency">AED</Typography></Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 xs={6} md={4} pt={2}>
        <Box>
          <Typography variant="subtitle2">{t('district.basicSection.apartTotal')}
            <Tooltip title={`${resolveFilters(['propType']).filters} filters applied.`}>
              <Badge badgeContent={resolveFilters(['propType']).value} color="info" variant="dot" style={{ transform: 'translate(11px, -10px)' }}></Badge>
            </Tooltip>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatNumber(basicData?.aparts?._count)}`}</Typography>
        </Box>
        <Grid2 xs={12} md={12} container spacing={2} pt={2}>
          <Grid2 xs={12} md={6} pl={0}>
            <Box>
              <Typography variant="subtitle2">{t('district.basicSection.apartAverage')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatAmount(basicData?.aparts?._avg)}`} <Typography variant="smallCurrency">AED</Typography></Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box>
              <Typography variant="subtitle2">{t('district.basicSection.apartMax')}</Typography>
            </Box>
            <Box>
              <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatAmount(basicData?.aparts?._max)}`} <Typography variant="smallCurrency">AED</Typography></Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 xs={6} md={4} pt={2}>
        <Box>
          <Typography variant="subtitle2">{t('district.basicSection.villaTotal')}
            <Tooltip title={`${resolveFilters(['propType', 'roomsType']).filters} filters applied.`}>
              <Badge badgeContent={resolveFilters(['propType', 'roomsType']).value} color="info" variant="dot" style={{ transform: 'translate(11px, -10px)' }}></Badge>
            </Tooltip>
          </Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatNumber(basicData?.villas?._count)}`}</Typography>
        </Box>
        <Grid2 xs={12} md={12} container spacing={2} pt={2}>
          <Grid2 xs={12} md={6} pl={0}>
            <Box>
              <Typography variant="subtitle2">{t('district.basicSection.villaAverage')}</Typography>
            </Box>
            <Box>
              <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatAmount(basicData?.villas?._avg)}`} <Typography variant="smallCurrency">AED</Typography></Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box>
              <Typography variant="subtitle2">{t('district.basicSection.villaMax')}</Typography>
            </Box>
            <Box>
              <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatAmount(basicData?.villas?._max)}`} <Typography variant="smallCurrency">AED</Typography></Typography>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
}

export default BasicSection;