// npm install @mui/x-data-grid

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid,  GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import Skeleton from 'react-loading-skeleton'
import Grid2 from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import API from '../controllers/api.js';
import { format } from 'date-fns'

const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'transactionDate', headerName: 'Date', valueFormatter: ({ value }) => formatDate(value)},
  { field: 'transactionType', headerName: 'Type' },
  { field: 'transactionSubType', headerName: 'Sub Type' },
  { field: 'transactionSize', headerName: 'Size' },
  { field: 'amount', headerName: 'Amount', valueFormatter: ({ value }) => formatNumber(value) },
  { field: 'propertyType', headerName: 'Property Type' },
  { field: 'propertySubType', headerName: 'Property Sub Type' },
  { field: 'registrationType', headerName: 'Registration Type' },
  { field: 'isFreeHold', headerName: 'Free Hold' },
  { field: 'usage', headerName: 'Usage' },
  { field: 'rooms', headerName: 'Rooms' },
  { field: 'parking', headerName: 'Parking' },
  { field: 'noOfBuyers', headerName: 'Buyers' },
  { field: 'noOfSellers', headerName: 'Sellers' },
];

const formatDate = (date) => {
  return format(new Date(date), 'dd/MM/yyyy')
}

const formatNumber = (number) => {
  return number ? number.toLocaleString('en-US') : ' - ';
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{disableToolbarButton: true}}/>
      
      <Box sx={{ p: 1, display: "flex", float: 'right' }}>
      <GridPagination />
      </Box>
    </GridToolbarContainer>
  );
}

const Transactions = (props) => {

  const { t } = useTranslation();

  const [projectId , setProjectId] = useState(props.projectId);
  const [dataLoading, setDataLoading] = useState(true); // [1
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    setProjectId(props.projectId);
  }, [props.projectId]);
  
  useEffect(() => {
    async function fetchData() {
      setDataLoading(true);
      const { data } = await API.get(`/project/${projectId}/transactions-all`)
      console.log("Transactions data", data);
      setTransactions(data);
      setDataLoading(false);
    }
    fetchData();
  }, [projectId]);

  return (
    <>
      <Grid2 xs={12}>
        <Box>
          <Typography variant="h1">{t('project.transactions.title')}</Typography>
          <Typography variant="subtitle1" mb={1}>{t('project.transactions.subTitle')}</Typography>
          <Divider light />
        </Box>
      </Grid2>
      <Grid2 xs={12} pt={3}>
        {dataLoading ? <Skeleton variant="rectangular" width={'100%'} height={250} /> : (
          <DataGrid
            rows={transactions}
            columns={columns}
            rowHeight={40}
            slots={{ 
              footer: CustomToolbar, 
              noRowsOverlay: () => <div style={{height: 100}}>No transactions found</div> 
            }}
            columnHeaderHeight={40}
            pageSizeOptions={[10, 20, 50]}
            initialState={{
              ...transactions.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
              },
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              '& .MuiDataGrid-virtualScroller': {
                minHeight: 100,
                textAlign: 'center',
              }
            }}
          />
        )}
      </Grid2>
    </>
  );
}

export default Transactions;