import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "../firebase";
import SubscriptionContex from './SubscriptionContext'
import API from '../controllers/secureApi.js';
import isEqual from 'lodash/isEqual';

const auth = getAuth()

// Subscription Provider contains DB user and is not aware of Firebase user!

export const SubscriptionProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  async function fetchData(user) {
    const localstorageUser = JSON.parse(localStorage.getItem('mappiDBUser'));
    setUser(localstorageUser)
    const {data: dbUser} = await API.get('/user/me', {
      user
    })
    if (!dbUser) {
      localStorage.removeItem('mappiDBUser')
      setUser(null)
      return;
    }
    if (isEqual(dbUser, localstorageUser)) {
      console.log("dbUser used from Local storage", localstorageUser)
      return;
    } else {
      setUser(dbUser)
      localStorage.setItem('mappiDBUser', JSON.stringify(dbUser));
      console.log("dbUser changed (dbUser->localstorageUser): ", dbUser, localstorageUser)
    }
    
  }
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        setUser(null)
        return;
      }
      fetchData(user)
    })
  }, []);

  return (
    <SubscriptionContex.Provider value={{ user, refetchDBUser: fetchData }}>{children}</SubscriptionContex.Provider>
  );
};