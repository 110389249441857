// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAh-fuoCd9SiJ39ZqNB5iLTV0EjJ47LPfU",
  authDomain: "auth.mappi.ae",
  projectId: "mappi-a6a20",
  storageBucket: "mappi-a6a20.appspot.com",
  messagingSenderId: "338128599047",
  appId: "1:338128599047:web:7aab19d82acde2aee612a2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {getAuth, onAuthStateChanged, auth};