import React, { useState, useCallback, useEffect } from "react";
import API from '../../controllers/secureApi';
import { useDropzone } from "react-dropzone";
// import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { TrashIcon } from '@heroicons/react/24/outline';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const FilesDropzone = ({
  className,
  onChange,
  hasActions = false,
  multiple = true,
  value,
  ...rest
}) => {
  // const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (value && !files.length) {
      if (multiple) {
        setFiles(value);

        return;
      }

      if (value.id) {
        setFiles([value]);
      }
    }
  }, [value]);

  const handleChange = (files) => {
    if (typeof onChange === "function") {
      let value = multiple ? [] : {};

      if (files.length) {
        console.log('files')
        console.log(files)
        value = {
          id: files[0].id,
          url: files[0].url,
        };

        if (multiple) {
          value = files.map(({ id, url }) => ({ id, url }));
        }
      }
      onChange(value);
    }
  };

  const handleDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);

    try {
      const requests = acceptedFiles.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        const { data } = await API.post("/images/upload", formData);
        console.log(data)
        Object.assign(file, data[0]);
      });

      console.log(Object)

      await Promise.all(requests);

      setFiles((prevValues) => {
        const newFiles = [...prevValues].concat(acceptedFiles);
        handleChange(newFiles);
        console.log(newFiles)
        return newFiles;
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = async (id) => {
    try {
      const selfFiles = files.slice();
      const index = selfFiles.findIndex((file) => file.id === id);
      if (index !== -1) {
        await API.delete(`/images/${id}`);

        selfFiles.splice(index, 1);
        setFiles(selfFiles);
        handleChange(selfFiles);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      files,
      result.source.index,
      result.destination.index
    );
    setFiles(items);
    handleChange(items)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg', '.webp', '.gif'],
    },
    maxFiles: 10,
    disabled: !multiple && files.length === 1,
  });

  return (
    <div {...rest}>
      <div
        style={{
          border: `1px dashed #9d9d9d`,
          padding: '20px',
          outline: "none",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          alignItems: "center",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: 'gray',
            opacity: 0.5,
            cursor: "pointer",
          },
        }}
        // className={clsx({
        //   [classes.dropZone]: true,
        //   [classes.dragActive]: isDragActive,
        // })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img style={{ width: '100px' }}
            alt="Select file"
            // className={classes.image}
            src="../undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          {isLoading && <CircularProgress />}
          <Typography gutterBottom variant="h3">
            Add photos(s)
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              Drop file(s) here or click to <Link underline="always">browse </Link>
              on the device
            </Typography>
          </Box>
        </div>
      </div>
      {files.length > 0 && (
        // <PerfectScrollbar options={{ suppressScrollX: true }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={uuidv4()}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <>
                  <List sx={{ maxHeight: 360, overflow: 'scroll' }}>
                    {files.map(({ name, id, url }, i) => (
                      <Draggable
                        draggableId={id ? id + i : name}
                        index={i}
                        key={id ? id + i : name}
                      >
                        {(provided, snapshot) => (
                          <ListItem divider={i < files.length - 1}
                            key={id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Avatar
                              variant="square"
                              sx={{ width: 86, height: 64, marginRight: 2 }}
                              src={`http://localhost:8080/api/v1/images/resize/100/100/${id}`}
                            ></Avatar>
                            <ListItemText
                              primary={`${id}`}
                              primaryTypographyProps={{ variant: "h5" }}
                            />
                            <Tooltip title="delete">
                              <IconButton edge="end" onClick={() => handleRemove(id)}>
                                <TrashIcon height={20} />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                  {hasActions && (
                    <div sx={{
                      marginTop: 2,
                      display: "flex",
                      justifyContent: "flex-end",
                      "& > * + *": {
                        marginLeft: 3,
                      }
                    }}>
                      <Button onClick={handleRemoveAll} size="small">
                        Remove all
                      </Button>
                      <Button color="secondary" size="small" variant="contained">
                        Upload files
                      </Button>
                    </div>
                  )}
                </>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        // </PerfectScrollbar>
      )}
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string,
};

export default FilesDropzone;
