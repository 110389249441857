import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Legend = (props) => {

  const [show, setShow] = useState(false);

  const setLegend = (d) => {
    localStorage.setItem('mappiLegend', d)
    setShow(d)
  }

  useEffect(() => {
    const show = localStorage.getItem('mappiLegend')
    if (show === 'false') {
      setShow(false)
    } else {
      setShow(true)
    }
  }, []);

  return (
    <Box sx={{
      marginLeft: {xs: 0, sm: '95px'},
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 10px',
      bottom: '32px',
      left: '10px',
      backgroundColor: 'white',
      borderRadius: '4px',
      fontFamily: 'Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
    }}>
      {/* close button */}
      {show ? (
        <IconButton aria-label="close"
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            padding: '0px',
          }}
          size="meddium" component="label" onClick={() => { setLegend(!show) }} pb={0}>
          <HighlightOffIcon fontSize="inherit" />
        </IconButton>
      ) : (
        <Box sx={{
          width: '24px',
          height: '28px',
        }}>
          <IconButton aria-label="close"
            sx={{
              padding: '0px',
            }}
            size="meddium" component="label" onClick={() => { setLegend(!show) }} pb={0}>
            <HelpOutlineIcon fontSize="inherit" />
          </IconButton>
        </Box>
      )}
      {show ? (
        <>
          <Box sx={{ alignSelf: 'flex-start', fontSize: '12px', fontWeight: 'bold', }}>Districts color-codes</Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
          }}>
            <Box sx={{
              fontSize: '12px',
              color: 'black',
              paddingRight: '5px',
            }}>{`<500`}</Box>

            <Box sx={{
              width: '150px',
              height: '15px',
              borderRadius: '2px',
              background: 'linear-gradient(90deg, hsl(51, 93%, 55%), hsl(351, 60%, 56%))',
              marginRight: '5px',
            }}></Box>
            <Box sx={{
              fontSize: '12px',
              color: 'black',
            }}>{`>6000 transactions`}</Box>
          </Box>
          <Box sx={{ alignSelf: 'flex-start', fontSize: '12px', fontWeight: 'bold', }}>Projects</Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
          }}>
            <Box sx={{
              width: '15px',
              height: '15px',
              backgroundColor: '#33d9b2',
              marginRight: '5px',
              borderRadius: '50%',
            }}></Box>
            <Box sx={{
              fontSize: '12px',
              color: 'black',
            }}>Finished</Box>
            <Box sx={{
              width: '15px',
              height: '15px',
              backgroundColor: '#ffda79',
              marginRight: '5px',
              marginLeft: '5px',
              borderRadius: '50%',
            }}></Box>
            <Box sx={{
              fontSize: '12px',
              color: 'black',
            }}>{'Active (>30% ready)'}</Box>
            <Box sx={{
              width: '15px',
              height: '15px',
              backgroundColor: '#ff5252',
              marginRight: '5px',
              marginLeft: '5px',
              borderRadius: '50%',
            }}></Box>
            <Box sx={{
              fontSize: '12px',
              color: 'black',
            }}>{'Active (<30%)'}</Box>
            <Box sx={{
              width: '15px',
              height: '15px',
              backgroundColor: '#000000',
              marginRight: '5px',
              marginLeft: '5px',
              borderRadius: '50%',
            }}></Box>
            <Box sx={{
              fontSize: '12px',
              color: 'black',
            }}>{'Canceled'}</Box>
          </Box>
        </>
      ) : (
        null)
      }

    </Box>
  );
}

export default Legend;