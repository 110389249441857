import React, { useEffect, useState, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AuthContext from "../contexts/AuthContext";
import SubscriptionContext from "../contexts/SubscriptionContext";
import 'react-loading-skeleton/dist/skeleton.css'
import Grid2 from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import API from '../controllers/api.js';
import 'react-lazy-load-image-component/src/effects/blur.css';
import toast from 'react-hot-toast';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';

import {
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

import { BookmarkIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import CircularProgress from '@mui/material/CircularProgress';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import NoData from "./common/nodata.js";
import Transactions from "./Transactions";
import DocCloud from "./project/DocCloud.js";
import Chat from "./project/Chat.js";

import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { InvoicePDF } from './pdf/property';
import BasicSection from "./project/basic/basicSection.js";
import UnitsTypesSection from "./project/basic/unitsTypesSection.js";
import DeveloperSection from "./project/basic/developerSection.js";
import DeveloperPropertiesSection from "./project/basic/developerPropertiesSection.js";
import EscrowSection from "./project/basic/escrowSection.js";
import ManagementSection from "./project/basic/managementSection.js";
import PriceTrendSection from "./project/transactions/priceTrendSection.js";
import ResaleSection from "./project/transactions/resaleSection.js";

import MarketDataSection from "./project/market/marketData.js";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { trackPageview } from "./common/plausible.js";

import apiInstance from '../controllers/secureApi.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      display={value === index ? 'block' : 'none'}
      id={`simple-tabpanel-${index}`}
      key={index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// const formatter = Intl.NumberFormat('en', { notation: 'compact' });

const formatFunctionsData = (functions) => {
  const data = []
  if (!functions) return data;
  functions.forEach((f) => {
    f.rooms.forEach((r) => {
      data.push({
        name: r.name.englishName,
        type: f.name.englishName,
        fullName: f.name.englishName + ' - ' + r.name.englishName,
        label: f.name,
        value: parseInt(r.value),
      })
    })
  })
  console.log(data)
  return data
}

const Legend = (props) => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [tab, setTab] = useState(0);

  const scrollRef = useRef(null);

  const [selectedId, setSelectedId] = useState(props.project?.id);
  const [selectedName, setSelectedName] = useState(props.project?.name);
  const [developerId, setDeveloperId] = useState(null);
  // Basic Data
  // const [basicData, setBasicData] = useState(null);
  const [dldData, setDldData] = useState(null);
  const [dldProjectData, setDldProjectData] = useState(null);
  const [mollakData, setMollakData] = useState(null);
  const [loadingB, setBLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  // const [imageLoaded, setImageLoaded] = useState(false);

  const [district, setDistrict] = useState(null);

  const [viewPDF, setViewPDF] = useState(false);

  const [isLiked, setIsLiked] = useState(false);
  const [isLikedLoading, setIsLikedLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const setSearchFunction = (id) => {
    props.setSearchFunction(id);
  }

  useEffect(() => {
    setSelectedId(props.project?.id);
    setSelectedName(props.project?.name);
    if (props.project?.id) {
      trackPageview({}, { props: { project: `${props.project?.id}`, projectName: `${props.project?.name}` } })
    }
  }, [props.project]);

  const setProject = ({ id, name }) => {
    setSelectedId(id);
    setSelectedName(name);
  }

  const handleProjectLike = async (id) => {
    if (!user || !id) {
      toast.error('Please login to add it to favourites.');
      return
    };
    setIsLikedLoading(true);
    const { data } = await apiInstance.post(`/like`, {
      projectId: id,
      type: 'project',
      liked: !isLiked
    });
    setIsLiked(data.liked);
    setIsLikedLoading(false);
  }

  useEffect(() => {
    async function fetchIsLiked() {
      if (!user || !selectedId) {
        setIsLikedLoading(false);
        return
      };
      setIsLikedLoading(true);
      const { data } = await apiInstance.get(`/like`, {
        params: {
          projectId: selectedId,
        }
      });
      setIsLiked(data.liked);
      setIsLikedLoading(false);
    }

    async function fetchData() {
      const tab = searchParams.get('tab');
      setBLoading(true);
      // setImageLoaded(false);

      scrollToTop()
      // Basic Data
      if (!selectedId) {
        setBLoading(false);
        return
      }

      const { data: bData } = await API.get(`/project/${selectedId}`)

      console.log('output', bData)

      // Basic Data
      await setDldData(bData.dldData);
      await setDldProjectData(bData.dldProjectData);
      await setMollakData(bData.mollakData);
      await setDeveloperId(bData.developerId);
      setGraphData(formatFunctionsData(bData.dldProjectData?.functions))

      await setDistrict({
        name: bData.districtName,
        id: bData.districtId,
      });

      console.log('bData', bData)

      //setTimeout(() => {
      setBLoading(false);
      if (tab) {
        setTab(parseInt(tab));
      }
      //}, 10000);
    }

    setTab(0)
    fetchIsLiked();
    fetchData();
  }, [selectedId, user, dbUser]);

  const scrollToTop = () => {
    if (!scrollRef.current) return;
    scrollRef.current.scroll({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      {selectedId ? (
        <Box
          sx={{
            flexGrow: 1,
            width: { lg: 800, md: 600, xs: '100%' },
            position: 'absolute',
            zIndex: 'modal',
            right: 0,
            top: 0,
            bottom: 0,
            margin: { lg: 4, md: 2, xs: 0 },
            marginTop: { lg: 12, md: 10, xs: 0 },
            marginRight: { lg: 2, md: 1, xs: 0 },
          }}
        >
          <Box
            sx={{
              p: 3,
              width: { lg: 800, md: 600, xs: '100%' }
            }}
            style={{ position: 'fixed', backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.08)', zIndex: 100 }}
          >
            <Grid2
              md={12}
              justify="space-between" // Add it here :)
              container
              spacing={2}
              direction="row"
            >
              <Grid2 item xs={2} sm={1} style={{ align: 'left' }} >
                <div style={{ textAlign: 'left' }}>
                  <IconButton aria-label="close" size="meddium" component="label" onClick={() => { setSelectedId(null); setSearchFunction('project', null) }} pb={0}>
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </Grid2>
              <Grid2 item xs={8} sm={10}>
                <Typography variant="h1">{selectedName}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Breadcrumbs aria-label="breadcrumb" sx={{paddingRight: {xs: 0, sm: '10px'}}}>
                  <Link color="inherit" href="/dubai">
                    <Typography variant="subtitle1">Dubai</Typography>
                    </Link>
                    <Link color="inherit" href={`/district/${district?.id}`}>
                    <Typography variant="subtitle1">{district?.name}</Typography>
                    </Link>
                    <Typography variant="subtitle1">{selectedName}</Typography>
                  </Breadcrumbs>
                  <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                  <WhatsappShareButton title={`Project ${selectedName} on MAPPI`} url={window.location.href} style={{ display: 'flex' }}>
                    <WhatsappIcon size={18} round />
                  </WhatsappShareButton>
                  <TelegramShareButton title={`Project ${selectedName} on MAPPI`} url={window.location.href} style={{ paddingLeft: 10, display: 'flex' }}>
                    <TelegramIcon size={18} round />
                  </TelegramShareButton>
                  <EmailShareButton subject={`Project ${selectedName} on MAPPI`} body={`Check this Project on MAPPI:\n\n ${selectedName}\n`} url={window.location.href} style={{ paddingLeft: 10, display: 'flex' }}>
                    <EmailIcon size={18} round />
                  </EmailShareButton>
                  </Box>
                </Box>
              </Grid2>
              <Grid2 item xs={2} sm={1} style={{ align: 'right' }} >
                <div style={{ textAlign: 'left' }}>
                  {isLikedLoading ? (
                    <IconButton aria-label="close" size="meddium" component="label" pb={0}>
                      <CircularProgress style={{ width: 25, height: 25, margin: '2px' }} color="inherit" />
                    </IconButton>
                  ) : (<IconButton aria-label="close" size="meddium" component="label" onClick={async () => { await handleProjectLike(selectedId) }} pb={0}>
                    {isLiked ?
                      <BookmarkIconSolid stroke-width="1.5" style={{ color: '#000', width: 25, margin: '2px' }} /> :
                      <BookmarkIcon stroke-width="1.5" style={{ color: '#000', width: 25, margin: '2px' }} />
                    }
                  </IconButton>)
                  }
                </div>
              </Grid2>
              {/* <Button color="primary" sx={{ m: 1, position: 'absolute', right:10 }} variant="contained" onClick={() => setViewPDF(true)}>
                Preview
              </Button> */}
            </Grid2>
          </Box>

          <Box
            sx={{
              p: 3,
              pb: 1,
              mt: 10,
              width: { lg: 800, md: 600, xs: '100%' }
            }}
            style={{ position: 'fixed', backgroundColor: 'white', borderBottom: '1px solid rgba(0, 0, 0, 0.08)', zIndex: 100 }}
          >
            <Grid2
              md={12}
              justify="space-between" // Add it here :)
              container
              spacing={2}
              direction="row"
            >
              <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('project.tabs.titleOne')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('project.tabs.subTitleOne')}</Typography>
                  </>
                } {...a11yProps(0)} />
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('project.tabs.titleFour')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('project.tabs.subTitleFour')}</Typography>
                  </>
                } {...a11yProps(1)} />
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('project.tabs.titleThree')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('project.tabs.subTitleThree')}</Typography>
                  </>
                } {...a11yProps(2)} />
                <Tab sx={{ alignItems: 'flex-start', paddingBottom: '0px', paddingLeft: '5px', textTransform: 'none' }} label={
                  <>
                    <Typography variant="h4">{t('project.tabs.titleFive')}</Typography>
                    <Typography variant="subtitle2" mb={1}>{t('project.tabs.subTitleFive')}</Typography>
                  </>
                } {...a11yProps(3)} />

              </Tabs>
            </Grid2>
          </Box>

          <Box ref={scrollRef} sx={{ flexGrow: 1, p: 0, pt: 20 }} className="bg-white shadow-darken10 round" style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
            <TabPanel value={tab} index={0} sx={{ p: 0 }}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={12} container spacing={1}>
                  {!dldData ? loadingB ? (null) : <NoData width={300} marginTop={0} /> : (
                    <div style={{ width: '100%' }}>
                      <BasicSection
                        loadingB={loadingB}
                        dldData={dldData}
                        dldProjectData={dldProjectData}
                        selectedId={selectedId}
                      />

                      <UnitsTypesSection
                        loadingB={loadingB}
                        graphData={graphData}
                        selectedId={selectedId}
                      />

                      <DeveloperSection
                        loadingB={loadingB}
                        dldData={dldData}
                        selectedId={selectedId}
                      />

                      <DeveloperPropertiesSection
                        developerId={developerId}
                        selectedId={selectedId}
                        user={user}
                        setProject={setProject}
                      />

                      <EscrowSection
                        loadingB={loadingB}
                        dldProjectData={dldProjectData}
                        selectedId={selectedId}
                      />

                      <ManagementSection
                        loadingB={loadingB}
                        mollakData={mollakData}
                        selectedId={selectedId}
                      />

                    </div>
                  )}
                  {/* <div>
                    <div>
                      <pre>{JSON.stringify(mollakData, null, 2)}</pre>
                    </div>
                  </div> */}
                </Grid2>
              </Grid2>
            </TabPanel>
            <TabPanel value={tab} index={1} key={tab}>
              <Grid2 container spacing={2}>
                <Grid2 xs={12} md={12} container spacing={1}>
                  <>
                    <Grid2 container spacing={2}>

                      <PriceTrendSection
                        user={user}
                        dbUser={dbUser}
                        selectedId={selectedId}
                      />

                      <ResaleSection
                        dbUser={dbUser}
                        user={user}
                        selectedId={selectedId}
                      />

                      <Transactions projectId={selectedId} />

                    </Grid2>
                  </>
                </Grid2>
              </Grid2>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <DocCloud projectId={selectedId} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <Chat projectId={selectedId} />
            </TabPanel>
          </Box>
          <Dialog fullScreen open={viewPDF} sx={{ position: 'absolute', zIndex: 100000, width: '100vw' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  p: 2,
                }}
              >
                <Button
                  onClick={() => setViewPDF(false)}
                  variant="contained"
                >
                  Back
                </Button>
                <PDFDownloadLink
                  document={<InvoicePDF />}
                  fileName="invoice"
                  style={{ textDecoration: 'none' }}
                >
                  <Button color="primary" sx={{ m: 1 }} variant="contained">
                    Download
                  </Button>
                </PDFDownloadLink>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <PDFViewer height="100%" style={{ border: 'none' }} width="100%">
                  <InvoicePDF />
                </PDFViewer>
              </Box>
            </Box>
          </Dialog>
        </Box>
      ) : (null)}
    </>
  );
};

export default Legend;
