import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "../firebase";
import AuthContext from './AuthContext'

const auth = getAuth()

// Auth Provider contains Firebase user and is not aware of DB user!

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    const localstorageUser = localStorage.getItem('mappiUser');
    setUser(localstorageUser)
    onAuthStateChanged(auth,(user) => {
      if (!user) {
        localStorage.removeItem('mappiUser')
        setUser(null)
        return;
      }
      if (user && localstorageUser) {
        console.log("userInstore used from Local storage", localstorageUser)
        return;
      } else {
        setUser(true)
        localStorage.setItem('mappiUser', true);
        console.log("userInstore changed (userInstore->localstorageUser): ", user, localstorageUser)
      }
    })
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};