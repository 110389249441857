import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionContext from "../../contexts/SubscriptionContext";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {PORTAL_URL} from '../../controllers/stripe'


const NoSpecials = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user: dbUser } = useContext(SubscriptionContext);
  const height = props.height || 300;
  const marginTop = props.marginTop || 0;
  const preview = props.preview || 'pro-district-pricetrend';
  
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: height,
      mt: marginTop,
      background: `linear-gradient(rgba(255,255,255,.85), rgba(255,255,255,.85)), url(/preview/empty.png)`,
      backgroundSize: 'cover',
    }}
    >
      <Typography background={'white'} variant="h1">No Special Deals</Typography>
      <Typography variant="subText" background={'white'} textAlign={'center'} mb={1} pt={2}>You have not listed anything just for now. Maybe it is time!;)</Typography>
      <Stack spacing={2} direction="row" pt={2}>
        <Button variant="contained" onClick={() => navigate('/specials/add')}>Add Special Offer</Button>
      </Stack>
    </Box>
  );
}

export default NoSpecials;