
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import Divider from '@mui/material/Divider';
import CustomizedTooltips from '../../common/tooltip';
import { ResponsiveRadar } from '@nivo/radar'
import NoData from '../../common/nodata';
import Register from '../../common/register';
import GoPro from '../../common/goPro';
import { BasicTooltip } from '@nivo/tooltip';


const ResaleSection = (props) => {
  const { t } = useTranslation();
  const { selectedId, user, dbUser } = props;
  // Basic Data
  const [resaleData, setResaleData] = useState(null);
  const [leftovers, setLeftovers] = useState([]);
  const [loadingRES, setRESLoading] = useState(true);

  useEffect(() => {
    async function fetchResaleData() {
      if (!selectedId) {
        setRESLoading(false);
        return
      }
      setRESLoading(true);
      const { data: resData } = await API.get(`/projects/${selectedId}/resalepercent`)
      // filter records where the realPercent is lower then 0
      setResaleData(resData)
      if (resData && resData.length) {
        const leftovers = resData.filter((r) => r.realPercent < 0)
        setLeftovers(leftovers)
        console.log('leftovers', leftovers)
      }
      setRESLoading(false);
    }
    fetchResaleData();
  }, [selectedId, user, dbUser]);

  return (
    <>
      <Grid2 xs={12} md={12}>
        <Typography variant="h1">{t('project.resaleSection.title')}</Typography>
        <Typography variant="subtitle1" mb={1}>{t('project.resaleSection.subTitle')}
          <CustomizedTooltips title={t('project.resaleSection.tooltip')} />
        </Typography>
        <Divider light />
      </Grid2>
      {!user ? <Register height={'400px'} forpro={true} preview={'pro-project-resale'} /> : dbUser?.tier !== 'pro' ? <GoPro height={'300px'} preview={'pro-project-resale'} /> : loadingRES ? <Grid2 xs={12} md={12}><Skeleton style={{ height: 400, width: '100%', marginTop: 20 }} /></Grid2> : resaleData?.length ? (
        <>
          <Grid2 xs={12} md={8}>
            <Box style={{ height: 400 }} pb={2}>
              <ResponsiveRadar
                data={resaleData}
                keys={['percent']}
                indexBy="name"
                valueFormat=">-.0f"
                margin={{ top: 30, right: 10, bottom: 30, left: 10 }}
                borderColor={{ from: 'color' }}
                gridLabelOffset={16}
                dotSize={10}
                dotColor={{ theme: 'background' }}
                dotBorderWidth={2}
                colors={{ scheme: 'nivo' }}
                blendMode="normal"
                motionConfig="wobbly"
                sliceTooltip={p => {
                  return <BasicTooltip
                    id={p.index}
                    value={p.data[0].value + '%'}
                    color={p.data[0].color}
                    enableChip
                  />;
                }}
              />
            </Box>
          </Grid2>
          <Grid2 xs={12} md={4}>
            <Typography variant="h2" fontWeight={'bold'} mb={1}>{t('project.resaleSection.leftovers.title')}</Typography>
            {leftovers.length ? (
              <>
                {leftovers.map((item, index) => (
                  <>
                    <Box mt={1}>
                      <Typography variant="subtitle2" fontWeight={'600'}>{item.name}</Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h4">{-item.realPercent} %  <Typography variant="subtitle2">out of {item.total} properties</Typography></Typography>
                    </Box>
                    <Divider light />
                  </>))
                }
              </>
            ) : (
              <Typography variant="h4" mb={1}>This property is sold out</Typography>
            )}
          </Grid2>
        </>
      ) : <NoData width={300} marginTop={-30} />}
    </>
  );
}

export default ResaleSection;