import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { resolveYear, formatNumber } from '../../../utils.js';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton';
import Divider from '@mui/material/Divider';
import CustomizedTooltips from "../../common/tooltip.js";
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import API from '../../../controllers/api';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';

import NoData from "../../common/nodata.js";


const TopSellerSection = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { year, selectedId, propType, regType, resolveFilters, roomsType } = props;
  // Top Projects
  const [topProjects, setTopProjects] = useState([]);
  const [loadingT, setTLoading] = useState(true);

  useEffect(() => {
    async function fetchTopProjectData() {
      setTLoading(true);
      // Top Projects
      const { data: tData } = await API.get(`/dubai/topprojects?yearstart=${year.start}&yearend=${year.end}&propFilter=${propType.filter}&regTypeFilter=${regType.filter}&roomsTypeFilter=${roomsType.filter}`)
      console.log('tData', tData);
      await setTopProjects(tData);
      setTLoading(false);
    }
    fetchTopProjectData();
  }, [selectedId, year, propType, regType, roomsType])

  return (
    <Grid2 xs={12}>
      <Box>
        <Typography variant="h1">
          {t('dubai.topSellerSection.title')} {resolveYear(year)}
          <Tooltip title={`${resolveFilters().filters} filters applied.`}>
                          <Badge badgeContent={resolveFilters().value} color="info" style={{  transform: 'translate(11px, -10px)'}}></Badge>
                        </Tooltip>
        </Typography>
        <Typography variant="subtitle1" mb={1}>{t('dubai.topSellerSection.subTitle')}<CustomizedTooltips title={t('dubai.topSellerSection.tooltip')} /></Typography>
        <Divider light />
        <Box style={{ height: 720 }}>
          {loadingT ? <Skeleton style={{ height: 400, marginTop: 20 }} /> : topProjects.length ? (
            <ResponsiveRadialBar
              data={topProjects}
              valueFormat={value => `${formatNumber(value)} AED`}
              padding={0.4}
              colors={['#20bf6b', '#fa8231', '#fc5c65', '#ff5252']}
              cornerRadius={2}
              height={700}
              margin={{ top: 40, right: isSM ? 40 : 120, bottom: 40, left: 40 }}
              radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
              circularAxisOuter={{
                tickSize: 5,
                tickPadding: 12,
                tickRotation: 10,
              }}
              legends={isSM ? [] : [
                {
                  anchor: 'right',
                  direction: 'column',
                  colors: ['#34ace0', '#227093', '#ff793f'],
                  justify: false,
                  translateX: 80,
                  translateY: 0,
                  itemsSpacing: 6,
                  itemDirection: 'left-to-right',
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#000',
                  symbolSize: 18,
                  symbolShape: 'square',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000'
                      }
                    }
                  ]
                }
              ]}
            />) : (
            <NoData width={300} marginTop={40} />
          )}
        </Box>
      </Box>
    </Grid2>
  );
}

export default TopSellerSection;