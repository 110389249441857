import React from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Menu from './components/Menu';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import {auth} from "./firebase";

import {
  sendPasswordResetEmail,
} from "firebase/auth";

export default function SignIn() {

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [suceessMessage, setSuccessMessage] = React.useState(null);

  const { register, handleSubmit, watch, formState: { errors } } = useForm(
    {
      mode: "onSubmit",
      reValidateMode: "onSubmit",
    }
  );
  
  const onSubmit = data => {
    sendPasswordReset(data.emailRequired);
  }

  const sendPasswordReset = async (email) => {
    try {
      setErrorMessage(null);
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage("Password reset instructions are sent to your email!");
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (err) {
      setErrorMessage('Something went wrong. Please check your email and try again.');
    }
  };

  return (
    <>
      <Menu />
      <Container component="main" maxWidth="xs" sx={{mt: 20}}>
        <Box
          sx={{  
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h1">
            Reset Password
          </Typography>
          <Typography component="subtitle1" variant="subtitle1" pt={1}>
            Please enter your email address to reset your password.
          </Typography>

          {suceessMessage ? (
            <Alert severity="success" variant="filled" >{suceessMessage}</Alert>
          ) : null}

          {errorMessage ? (
            <Alert severity="error" variant="filled" >{errorMessage}</Alert>
          ) : null}

          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              helperText={errors.emailRequired && (errors.emailRequired.message || 'This field is required')}
              autoComplete="email"
              {...register("emailRequired", { 
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format"
                }
              })}
              autoFocus
            />
            <Button
              type="submit"
              size="large"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset password
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/login" variant="body2">
                  Back to login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>  
      </Container>
    </>
  );
}
