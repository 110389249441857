import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { formatNumber, resolveYear, isTickShow, calculatePercentChange } from '../../../utils.js';
import Grid2 from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Skeleton from 'react-loading-skeleton'
import { ResponsiveLine } from '@nivo/line'
import API from '../../../controllers/api';

import NoData from "../../common/nodata.js";
import Register from "../../common/register.js";
import CustomizedTooltips from "../../common/tooltip.js";
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';

import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';

const SqmPriceSection = (props) => {
  const { t } = useTranslation();
  const { user, year, propType, regType, resolveFilters, roomsType } = props;

  const TEMPLATE_GRAPH = [
    {
      id: t('dubai.sqmPriceSection.graph.axe1'),
      color: "hsl(211.43deg 40.38% 20.39%)",
      data: []
    }, {
      id: t('dubai.sqmPriceSection.graph.axe2'),
      color: "hsl(211.43deg 40.38% 20.39%)",
      data: []
    }
  ]

  // avarage sqm price
  const [avgSqmPrice, setAvgSqmPrice] = useState(TEMPLATE_GRAPH);
  const [volumeGraph, setVolumeGraph] = useState(TEMPLATE_GRAPH);
  const [loadingASP, setASPLoading] = useState(true);
  const [loadingSST, setSSTLoading] = useState(true);
  const [currentSqmPrice, setCurrentSqmPrice] = useState(null);
  const [startSqmPrice, setStartSqmPrice] = useState(null);

  useEffect(() => {
    async function fetchAvgPriceData() {
      setASPLoading(true);
      // avarage sqm price
      const { data: avgPrice } = await API.get(`/dubai/avgprice?yearstart=${year.start}&yearend=${year.end}&propFilter=${propType.filter}&regTypeFilter=${regType.filter}&roomsTypeFilter=${roomsType.filter}`)
      console.log('avgSqmPrice', avgPrice);
      if (!avgPrice) {
        await setAvgSqmPrice(TEMPLATE_GRAPH);
        setASPLoading(false);
      } else {
        const avgGraph = avgPrice.map((t) => {
          return {
            x: `${t.month >= 10 ? t.month : `0${t.month}`}.${t.year}`,
            y: t.avgsqmprice
          }
        })
        const movingAvgGraph = avgPrice.map((t) => {
          return {
            x: `${t.month >= 10 ? t.month : `0${t.month}`}.${t.year}`,
            y: t.movingaverage
          }
        })
        const volumeGraph = avgPrice.map((t) => {
          return {
            x: `${t.month >= 10 ? t.month : `0${t.month}`}.${t.year}`,
            y: t.countmonth
          }
        })
        console.log('avgSqmPrice - Graph', avgGraph);
        console.log('movingAvgGraph - Graph', movingAvgGraph);
        await setAvgSqmPrice([
          {
            id: t('dubai.sqmPriceSection.graph.axe1'),
            color: "hsl(296, 70%, 50%)",
            data: avgGraph
          }, {
            id: t('dubai.sqmPriceSection.graph.axe2'),
            color: "hsl(211.43deg 40.38% 20.39%)",
            data: movingAvgGraph
          }
        ]);
        await setVolumeGraph([
          {
            id: t('dubai.sqmPriceSection.graph.axe3'),
            color: "hsl(211.43deg 40.38% 20.39%)",
            data: volumeGraph
          }
        ]);
        setStartSqmPrice(avgPrice[0]?.avgsqmprice || 0);
        setCurrentSqmPrice(avgPrice.length > 2 ? avgPrice[avgPrice.length - 1]?.avgsqmprice : 0);
        setSSTLoading(false);
        console.log('volumeGraph', volumeGraph);
        setASPLoading(false);
      }
    }
    fetchAvgPriceData();
  }, [year, propType, regType, roomsType]);

  return (
    <Grid2 xs={12} md={12} pt={3}>
      <Box>
        <Typography variant="h1">
          {t('dubai.sqmPriceSection.title')} {resolveYear(year)}
          <Tooltip title={`${resolveFilters().filters} filters applied.`}>
            <Badge badgeContent={resolveFilters().value} color="info" style={{ transform: 'translate(11px, -10px)' }}></Badge>
          </Tooltip>
        </Typography>
        <Typography variant="subtitle1" mb={1}>{t('dubai.sqmPriceSection.subTitle')}<CustomizedTooltips title={t('dubai.sqmPriceSection.tooltip')} /></Typography>
        <Divider light />

        <Grid2 xs={12} md={12} pt={2}>
          {!user ? (null) : loadingSST ? <Skeleton style={{ height: '70px', width: 200 }} /> : currentSqmPrice > 0 && startSqmPrice > 0 ? (
            <>
              <Box>
                <Typography variant="subtitle2" fontWeight={'600'}>
                  {t('dubai.sqmPriceSection.priceChange.title')}
                  <CustomizedTooltips title={t('dubai.sqmPriceSection.priceChange.tooltip')} />
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="h2">
                  {calculatePercentChange(currentSqmPrice, startSqmPrice) > 0 ? (
                    <NorthEastIcon color={'success'} fontSize="10" />
                  ) : (
                    <SouthEastIcon color={'error'} fontSize="10" />
                  )}
                  {calculatePercentChange(currentSqmPrice, startSqmPrice)} %
                  <Typography variant="subtitle2">
                    {t('project.incomeLossSection.from')} {formatNumber(Number(startSqmPrice))} to {formatNumber(Number(currentSqmPrice))} AED/Ft²
                  </Typography>
                </Typography>
              </Box>
            </>
          ) : (null)}
        </Grid2>

        {!user ? <Register height={'400px'} preview={'pro-district-pricetrend'} /> : (
          <>
            <Box style={{ height: 250 }}>
              {loadingASP ? <Skeleton style={{ height: 230, marginTop: 20 }} /> : avgSqmPrice[0]?.data?.length > 3 ? (
                <ResponsiveLine
                  data={avgSqmPrice}
                  margin={{ top: 10, right: 20, bottom: 30, left: 40 }}
                  xScale={{ type: 'point' }}
                  colors={['#fc5c65', '#d1d8e0']}
                  yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    // stacked: true,
                    reverse: false
                  }}
                  stacked={false}
                  yFormat={value => `${formatNumber(value)} AED/Ft²`}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36,
                    legendPosition: 'middle',
                    format: function (value) {
                      if (year.start !== year.end) {
                        return isTickShow(value) ? value : '';
                      } else {
                        return value;
                      }
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -40,
                    legendPosition: 'middle'
                  }}
                  pointSize={10}
                  pointColor="#ffffff"
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabelYOffset={-12}
                  enableArea={false}
                  // enableSlices="x"
                  crosshairType="bottom-left"
                  useMesh={true}
                  legends={[]}
                  enableSlices="x"
                  curve="cardinal"
                  motionConfig="default"
                />
              ) : (
                <NoData width={450} marginTop={-50} />
              )}
            </Box>
            <Box style={{ height: 150 }}>
              {loadingASP ? <Skeleton style={{ height: 130, marginTop: 20 }} /> : volumeGraph[0]?.data?.length > 3 ? (
                <ResponsiveLine
                  data={volumeGraph}
                  margin={{ top: 30, right: 20, bottom: 30, left: 40 }}
                  xScale={{ type: 'point' }}
                  colors={['#84817a', '#d1d8e0']}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: 'auto',
                    stacked: true,
                    reverse: false
                  }}
                  yFormat=" >-,.0f"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36,
                    legendPosition: 'middle',
                    format: function (value) {
                      if (year.start !== year.end) {
                        return isTickShow(value) ? value : '';
                      } else {
                        return value;
                      }
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -40,
                    legendPosition: 'middle'
                  }}
                  pointColor="#ffffff"
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor' }}
                  pointLabelYOffset={-12}
                  enableArea={true}
                  // enableSlices="x"
                  crosshairType="bottom-left"
                  useMesh={true}
                  legends={[]}
                  enableSlices="x"
                  lineWidth={2}
                  pointSize={7}
                  curve="step"
                  motionConfig="default"
                />
              ) : (
                (null)
              )}
            </Box>

          </>)}
      </Box>
    </Grid2>
  )
}

export default SqmPriceSection;