import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import CustomizedTooltips from '../../common/tooltip';
import Divider from '@mui/material/Divider';
import { ResponsiveBar } from '@nivo/bar'
import NoData from '../../common/nodata';


const UnitsTypesSection = (props) => {
  const { t } = useTranslation();
  const { loadingB, graphData, selectedId } = props;
  // Basic Data
  // const [basicData, setBasicData] = useState(null);
  // const [loadingB, setBLoading] = useState(true);

  // useEffect(() => {
  //   async function fetchBasicData() {
  //     if (!selectedId) {
  //       setBLoading(false);
  //       return
  //     }
  //     setBLoading(true);
  //     // Basic Data
  //     // const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?year=${resolveYear(year)}`, controller.signal)
  //     const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?yearstart=${year.start}&yearend=${year.end}`)
  //     await setBasicData(bData);
  //     console.log('bData', bData);
  //     setBLoading(false);
  //   }
  //   fetchBasicData();
  // }, [selectedId, year]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={12} pt={3}>
          <Typography variant="h1">{t('project.typesSection.title')}</Typography>
          <Typography variant="subtitle1" mb={1}>{t('project.typesSection.subTitle')}
            <CustomizedTooltips title={t('project.typesSection.tooltip')} />
          </Typography>
          <Divider light />
        </Grid2>
      </Grid2>

      <Grid2 xs={12} md={12}>
        <Box style={{ height: 400 }}>
          {loadingB ? <Skeleton variant="rectangular" width={'100%'} height={400} /> : graphData.length > 0 ? (
            <ResponsiveBar
              data={graphData}
              keys={[
                'value'
              ]}
              indexBy="fullName"
              margin={{ top: 10, right: 0, bottom: 50, left: 120 }}
              padding={0.3}
              layout="horizontal"
              valueScale={{ type: 'symlog' }}
              indexScale={{ type: 'band', round: true }}
              // colors={{ scheme: 'nivo' }}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1.6
                  ]
                ]
              }}
              axisTop={null}
              axisRight={null}
              // enableGridX={true}
              // labelSkipWidth={12}
              // labelSkipHeight={12}
              labelTextColor="#ffffff"
              colorBy="indexValue"
              legends={[]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              colors={['#20bf6b', '#fa8231', '#fc5c65', '#ff5252', '#0fb9b1', '#2d98da', '#3867d6', '#8854d0', '#4b6584']}
              barAriaLabel={function (e) { return e.type + ": " + e.formattedValue + ": " + e.indexValue }}
            />
          ) : (
            <NoData width={400} marginTop={0} />
          )}
        </Box>
      </Grid2>
    </>
  );
}

export default UnitsTypesSection;