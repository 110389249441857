import React, { useRef, useEffect, useState, useContext } from 'react';

import { Toaster } from 'react-hot-toast';

import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import mapboxgl from '!mapbox-gl';
import Menu from './components/Menu';
import NewMenu from './components/NewMenu';
import District from './components/District.js';
import Project from './components/Project';
import './theme/Map.css';
import getGeoData from './controllers/geodata';
import AuthContext from "./contexts/AuthContext";
import API from './controllers/api.js';

import axios from 'axios';
import Legend from './components/Legend';
import Box from '@mui/material/Box';
import { set } from 'lodash';
import { use } from 'i18next';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZXVnYW5veCIsImEiOiJjbGcza3Q2bDYwZ2FjM2VuOW5zNGl1MzBhIn0.UdtHbQyNWdcaZJmObkPuPA';

let hoveredStateId = null;
let activeStateId = null;
let projectId = null;

const Map = () => {

  const { user } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { districtIdParam, projectIdParam } = useParams();
  const navigate = useNavigate();

  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  // const [districtId, setDistrictId] = useState(null);
  const [project, setProject] = useState({ id: null, name: null });
  const [region, setRegion] = useState({ id: null, name: null });
  // const [hoveredStateId, setHoveredStateId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const district = districtIdParam;
      const project = projectIdParam;
      if (district) {
        const { data: districtData } = await API.get(`/districts/${district}`)
        console.log('districtData.district.metaData.bbox', districtData.district.metaData.properties.bbox)
        changeRegion({ id: district, name: districtData.district.name });
        try {
          map.setFilter('communities-data-press', ['==', 'id', district]);
          map.setFilter('communities-line-press', ['==', 'id', district]);
          map.setFilter('projects-pin', ['==', 'district', district]);
          // fit box
          map.fitBounds(districtData.district.metaData?.properties?.bbox, {
            bearing: -49.60,
            pitch: 39.00,
            animate: true,
            duration: 3000,
            padding: { top: 1, bottom: 1, left: 1, right: 800 }
          });
        } catch (error) {
          console.log("Map error, mobile?", error)
        }
      }

      if (project) {
        const { data: projectData } = await API.get(`/project/${project}`)
        setProject({ id: project, name: projectData.name });
        try {
          console.log("MAPPPPPPPP2")
          if (district) {
            map.setFilter('projects-pin', ['==', 'district', district]);
          }
          map.setFilter('projects-pin-active', ['==', 'id', project]);
        } catch (error) {
          console.log("MAPPPPPPPP2 ERROR")
          console.log("error", error)
        }
      }
    }
    if (!mapLoaded) return;
    fetchData();
  }, [mapLoaded]);


  const setSearchFunction = (key, value) => {
    if (!value) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    setSearchParams(searchParams);
  }

  const changeRegion = async (newRegion) => {
    // if (newRegion?.id === region.id) return;
    await setRegion(newRegion);
    if(!projectIdParam){
      await changeProject({ id: null, name: null });
    }
  }

  const changeProject = async (newProject) => {
    // if (newProject?.id === project.id) return;
    await setProject(newProject);
  }

  useEffect(() => {
    if(region.id && project.id) {
      navigate(`/district/${region.id}/project/${project.id}`);
    } else if(region.id) {
      navigate(`/district/${region.id}`);
    }
  }, [region, project]);

  const handleSearch = async (item) => {
    if (item?.type === 'district') {
      await changeRegion({ id: item.districtId, name: item.districtName });
      //await changeProject({ id: null, name: null });
      try {
        map.setFilter('projects-pin-active', ['==', 'id', '']);
        map.setFilter('communities-data-press', ['==', 'id', item.districtId]);
        map.setFilter('communities-line-press', ['==', 'id', item.districtId]);
        map.setFilter('projects-pin', ['==', 'district', item.districtId]);
      } catch (error) {
        console.log("Map error, mobile?", error)
      }
      // map.setFilter('projects-name', ['==', 'district', item.districtId]);
    } else if (item?.type === 'project') {
      await changeRegion({ id: item.districtId, name: item.districtName });
      await changeProject({ id: item.projectId, name: item.projectName });
      try {
        map.setFilter('communities-data-press', ['==', 'id', item.districtId]);
        map.setFilter('communities-line-press', ['==', 'id', item.districtId]);
        map.setFilter('projects-pin', ['==', 'district', item.districtId]);
        // map.setFilter('projects-name', ['==', 'district', item.districtId]);
        map.setFilter('projects-pin-active', ['==', 'id', item.projectId]);
      } catch (error) {
        console.log("Map error, mobile?", error)
      }
    }
    try {
      map.fitBounds(item.bbox, {
        bearing: -49.60,
        pitch: 39.00,
        animate: true,
        duration: 3000,
        padding: { top: 1, bottom: 1, left: 1, right: 800 }
      });
    } catch (error) {
      console.log("Map error, mobile?", error)
    }
  }

  // Initialize map when component mounts
  useEffect(() => {
    console.log("user IN", user)
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/euganox/clg5cji0j009k01qfa3mubrmp',
      center: [55.252738, 25.033080],
      bearing: -49.60,
      pitch: 39.00,
      zoom: 11.50,
      customAttribution: '<a href="https://euganox.com">Euganox</a> | <a href="https://www.linkedin.com/in/popovae/">Andrei Popov</a>'
    });
    setMap(map);

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15
    });

    // This implements `StyleImageInterface`
    // to draw a pulsing dot icon on the map.
    const size = 150;
    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // Call once before every frame where the icon will be used.
      render: function () {
        const duration = 1500;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        // Draw the outer circle.
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = `rgb(252, 92, 101, ${1 - t / 2})`;
        context.fill();

        // Draw the inner circle.
        // context.beginPath();
        // context.arc(
        //   this.width / 2,
        //   this.height / 2,
        //   radius,
        //   0,
        //   Math.PI * 2
        // );
        // context.fillStyle = 'rgba(255, 100, 100, 0)';
        // context.strokeStyle = 'white';
        // context.lineWidth = 2 + 4 * (1 - t);
        // context.fill();
        // context.stroke();

        // Update this image's data with data from the canvas.
        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        map.triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      }
    };

    // map.on('mousemove', 'communities-data', e => {
    //   new mapboxgl.Popup()
    //     .setLngLat(e.lngLat)
    //     .setHTML(
    //       `<h3>${e.features[0].properties.CNAME_E}</h3><br><h3>${e.features[0].properties.CNAME_A}</h3>`
    //     )
    //     .addTo(map);
    // });

    map.on('load', () => {
      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
      // map.addSource('countries', {
      //   type: 'geojson',
      //   data
      // });

      // map.addLayer({
      //   id: 'choropleth-fill',
      //   type: 'fill',
      //   source: 'states',
      //   layout: {},
      //   paint: {
      //     'fill-color': '#627BC1',
      //     'fill-opacity': [
      //       'case',
      //       ['boolean', ['feature-state', 'hover'], false],
      //       1,
      //       0.5
      //     ]
      //   }
      // });

      map.addSource('communities', {
        type: 'geojson',
        // Use any Mapbox-hosted tileset using its tileset id.
        // Learn more about where to find a tileset id:
        // https://docs.mapbox.com/help/glossary/tileset-id/
        data: getGeoData('districts'),
        generateId: true
      });

      map.addSource('projects', {
        type: 'geojson',
        // Use any Mapbox-hosted tileset using its tileset id.
        // Learn more about where to find a tileset id:
        // https://docs.mapbox.com/help/glossary/tileset-id/
        data: getGeoData('projects'),
        generateId: true
      });

      map.addLayer(
        {
          "id": "choropleth-labels",
          "type": "symbol",
          "source": "communities",
          "minzoom": 1,
          "filter": ["has", "SHAPE_AREA"],
          "layout": {
            "text-field": ["get", "CNAME_E"],
            "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
            "text-size": 12,
            "text-padding": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1,
              10,
              7,
              20,
              9,
              100
            ]
          },
          "paint": {
            "text-color": [
              "interpolate",
              ["exponential", 1],
              ["get", "SHAPE_AREA"],
              75481.4632114,
              "hsl(295, 1%, 47%)",
              182544826,
              "hsl(266, 2%, 42%)",
              365014170.612,
              "hsl(235, 2%, 26%)"
            ],
            "text-halo-color": "rgba(0, 0, 0, 0)",
            "text-halo-width": 1,
            "text-halo-blur": 0.5
          }
        }
      );

      map.addLayer(
        {
          id: 'communities-line',
          type: 'line',
          source: 'communities',
          "paint": {
            "line-color": "#a6a6a6",
            "line-opacity": [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1, // orange
              0.2 // transparent,
            ],
            "line-width": 2
          }
        },
      );

      map.addLayer({
        'id': 'communities-data',
        'type': 'fill',
        'source': 'communities',
        'paint': {
          "fill-color": [
            "interpolate",
            ["exponential", 1],
            ["get", "transactions2023"],
            0,
            "rgba(238, 249, 26, 0)",
            500,
            "hsl(51, 93%, 55%)",
            2000,
            "hsl(40, 96%, 58%)",
            3000,
            "hsl(29, 96%, 59%)",
            4000,
            "hsl(20, 85%, 60%)",
            5000,
            "hsl(7, 71%, 61%)",
            6000,
            "hsl(351, 60%, 56%)"
          ],
          "fill-opacity": [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.4, // orange
            0.2 // transparent,
          ],
        }
      }
      );

      map.addLayer({
        id: 'communities-data-press',
        type: 'fill',
        filter: ["==", "id", ""],
        source: 'communities',
        paint: {
          "fill-color": "#2d98da",
          "fill-opacity": 0.2,
        }
      }
      );

      map.addLayer({
        id: "projects-pin-active",
        type: "symbol",
        source: "projects",
        filter: ["==", "id", ""],
        layout: {
          'icon-image': 'pulsing-dot',
          // "icon-offset": [0, -3],
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            0.5,
            22,
            1
          ],
        },
        "paint": {}
      });

      map.addLayer({
        id: "projects-pin",
        type: "circle",
        source: "projects",
        filter: ["==", "district", ""],
        paint: {
          "circle-color": [
            "case",
            [
              ">=",
              ["get", "completion"],
              99
            ],
            "#33d9b2",
            [
              ">=",
              ["get", "completion"],
              30
            ],
            "#ffda79",
            [
              ">=",
              ["get", "completion"],
              0
            ],
            "#ff5252",
            ["has", "completion"],
            "#ff5252",
            "#ff5252"
          ],
          "circle-opacity": 1,
          "circle-stroke-color": "#ffffff",
          "circle-stroke-width": 2.3,
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            0.1,
            18,
            6
          ]
        }
      });

      map.addLayer(
        {
          id: 'communities-line-press',
          type: 'line',
          source: 'communities',
          filter: ["==", "id", ""],
          "paint": {
            "line-color": "#000",
            "line-dasharray": [1, 2],
            "line-opacity": 0.3,
            "line-width": 2
          }
        },
      );

      map.on('click', (e) => {
        // recognize clicked feature type
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['projects-pin', 'communities-data'],
        });

        // console.log("WARN! click", features[0].layer.id)

        // only proceed if user clicked on a certain feature
        if (!features.length) return;

        const layerId = features[0].layer.id;
        const feature = features[0];

        // handle clicks depending on type
        switch (layerId) {
          case 'projects-pin':
            console.log(feature[0])
            console.log("WARN! click project")
            map.getCanvas().style.cursor = 'pointer';

              projectId = feature.properties;
              changeProject({ id: projectId.id, name: projectId.name })
              map.setFilter('projects-pin-active', ['==', 'id', projectId.id]);
            // code for markers
            break;
          case 'communities-data':
            // code for clusters
            const bbox = JSON.parse(feature.properties.bbox)
            map.getCanvas().style.cursor = 'pointer';
            map.setFilter('communities-data-press', ['==', 'id', feature.properties.id]);
            map.setFilter('communities-line-press', ['==', 'id', feature.properties.id]);

            map.setFilter('projects-pin-active', ['==', 'id', '']);

            map.setFilter('projects-pin', ['==', 'district', feature.properties.id]);
            // map.setFilter('projects-name', ['==', 'district', e.features[0].properties.id]);

            if (activeStateId !==feature.properties.id) {
              try {
                map.fitBounds(bbox, {
                  bearing: -49.60,
                  pitch: 39.00,
                  animate: true,
                  duration: 3000,
                  padding: { top: 10, bottom: 10, left: 10, right: 800 }
                });
              } catch (error) {
                console.log("Map error, mobile?", error)
              }
            }
            changeRegion({ id: feature.properties.id, name: feature.properties.CNAME_E })
            console.log("WARN! click region")
            changeProject({ id: null, name: null })
            activeStateId = feature.properties.id;
            break;
          // you can add more cases as needed
          default:
            // optional: handle unexpected cases
            break;
        }
      });

      // map.on('click', 'communities-data', (e) => {
      //   const bbox = JSON.parse(e.features[0].properties.bbox)
      //   map.getCanvas().style.cursor = 'pointer';
      //   map.setFilter('communities-data-press', ['==', 'id', e.features[0].properties.id]);
      //   map.setFilter('communities-line-press', ['==', 'id', e.features[0].properties.id]);

      //   map.setFilter('projects-pin-active', ['==', 'id', '']);

      //   map.setFilter('projects-pin', ['==', 'district', e.features[0].properties.id]);
      //   // map.setFilter('projects-name', ['==', 'district', e.features[0].properties.id]);

      //   if (activeStateId !== e.features[0].properties.id) {
      //     try {
      //       map.fitBounds(bbox, {
      //         bearing: -49.60,
      //         pitch: 39.00,
      //         animate: true,
      //         duration: 3000,
      //         padding: { top: 10, bottom: 10, left: 10, right: 800 }
      //       });
      //     } catch (error) {
      //       console.log("Map error, mobile?", error)
      //     }
      //   }
      //   changeRegion({ id: e.features[0].properties.id, name: e.features[0].properties.CNAME_E })
      //   console.log("WARN! click region")
      //   changeProject({ id: null, name: null })
      //   activeStateId = e.features[0].properties.id;
      // });

      // map.on('click', 'projects-pin', (e) => {
      //   console.log(e.features[0])
      //   console.log("WARN! click project")
      //   map.getCanvas().style.cursor = 'pointer';
      //   if (e.features.length > 0) {
      //     projectId = e.features[0].properties;
      //     changeProject({ id: projectId.id, name: projectId.name })
      //     map.setFilter('projects-pin-active', ['==', 'id', projectId.id]);
      //   }
      // });

      map.on('mousemove', 'communities-data', (e) => {
        map.getCanvas().style.cursor = 'pointer';
        if (e.features.length > 0) {
          if (hoveredStateId) {
            // set the hover attribute to false with feature state
            map.setFeatureState({
              source: 'communities',
              id: hoveredStateId
            }, {
              hover: false
            });
          }

          hoveredStateId = e.features[0].id;
          // set the hover attribute to true with feature state
          map.setFeatureState({
            source: 'communities',
            id: hoveredStateId
          }, {
            hover: true
          });
        }
      });

      // map.setLayoutProperty('country-label', 'text-field', [
      //   'format',
      //   ['get', 'name_en'],
      //   { 'font-scale': 1.2 },
      //   '\n',
      //   {},
      //   ['get', 'name'],
      //   {
      //     'font-scale': 0.8,
      //     'text-font': [
      //       'literal',
      //       ['DIN Offc Pro Italic', 'Arial Unicode MS Regular']
      //     ]
      //   }
      // ]);

      map.on('mousemove', 'communities-data', (e) => {
        // Change the cursor style as a UI indicator.
        // map.getCanvas().style.cursor = 'pointer';

        // Copy coordinates array.
        const coordinates = e.lngLat.wrap();
        const description = e.features[0].properties.CNAME_E;
        const descriptionA = e.features[0].properties.CNAME_A;

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(`
          <h1 style="font-weight: bold; font-family: 'Noto Sans', sans-serif; font-size: 14px">${description}</h1>
          <p style="font-family: 'Noto Sans', sans-serif; font-size: 12px">${descriptionA}</p>
        `).addTo(map);
      });

      map.on('mousemove', 'projects-pin', (e) => {
        // Change the cursor style as a UI indicator.
        // map.getCanvas().style.cursor = 'pointer';

        // Copy coordinates array.
        const coordinates = e.lngLat.wrap();
        const description = e.features[0].properties.name;

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(`
          <subtitle1 style="font-family: 'Noto Sans', sans-serif; color: 'gray'">Project</subtitle1>
          <h1 style="font-weight: bold; font-family: 'Noto Sans', sans-serif; font-size: 14px">${description}</h1>
        `).addTo(map);
      });

      map.on('mouseleave', 'communities-data', () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      });

      // map.on('render', () => {
      //   console.log('render')
      // });

      setMapLoaded(true);

      // map.setPaintProperty('countries', 'fill-color', {
      //   property: active.property,
      //   stops: active.stops
      // });
    });
    // Clean up on unmount
    return () => map.remove();
  }, []);

  return (
    <div>
      <NewMenu />
      <Menu handleSearch={handleSearch} />

      <Box ref={mapContainerRef} className='map-container' sx={{ marginLeft: { xs: '0px', sm: '95px' } }} />
      <Legend />
      <District region={region} selectProject={changeProject} setSearchFunction={setSearchFunction} />
      {project !== '' ? (
        <Project project={project} setSearchFunction={setSearchFunction} />
      ) : null}
      <Toaster containerStyle={{
        zIndex: 100000,
        top: 100,
      }} />
    </div>
  );
};

export default Map;
