import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { isTickShowPriceDistro, formatNumber } from '../../../utils';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import { ResponsiveBar } from '@nivo/bar'

import NoData from "../../common/nodata.js";

import DistributionProperties from "./DistributionProperties.js";
import API from '../../../controllers/api';


const PriceDistributionSection = (props) => {
  const { t } = useTranslation();
  const { selectProject, selectedId, year, propType, regType, roomsType } = props;

  // Price distribution
  const [priceDistribution, setPriceDistribution] = useState([]);
  const [loadingPD, setPDLoading] = useState(true);
  const [distributionProperties, setDistributionProperties] = useState([]);

  const distribution = 100;

  useEffect(() => {

    async function fetchPriceDistributionData() {
      setPDLoading(true);
      await setDistributionProperties([]);
      // Price Distribution
      const { data: pdData } = await API.get(`/dubai/pricedistribution?yearstart=${year.start}&yearend=${year.end}&distribution=${distribution}&propFilter=${propType.filter}&regTypeFilter=${regType.filter}&roomsTypeFilter=${roomsType.filter}`)
      await setPriceDistribution(pdData);
      console.log('dubai price distro', pdData);
      setPDLoading(false);
    }
    fetchPriceDistributionData();
  }, [selectedId, year, propType, regType, roomsType])

  return (
    <Grid2 xs={12} md={12}>
      <Box style={{ height: 400 }}>
        {loadingPD ? <Skeleton variant="rectangular" width={'100%'} height={400} /> : priceDistribution.length > 0 ? (
          <ResponsiveBar
            data={priceDistribution}
            keys={[
              'volume'
            ]}
            indexBy="upperrange"
            margin={{ top: 40, right: 10, bottom: 40, left: 40 }}
            padding={0}
            layout="vertical"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            // colors={{ scheme: 'nivo' }}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            onClick={(node, event) => {
              setDistributionProperties(node.data?.projects);
            }}
            axisTop={null}
            axisRight={null}
            enableLabel={false}
            valueFormat={value =>
              `${formatNumber(value)} transactions`
            }
            // tooltipLabel={function (e) {
            //   return `${e.formattedValue} transactions`;
            // }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: 32,
              truncateTickAt: 0,
              format: function (value) {
                return isTickShowPriceDistro(value) ? value : '';
              },
            }}
            // enableGridX={true}
            // labelSkipWidth={12}
            // labelSkipHeight={12}
            labelTextColor="#ffffff"
            colorBy="indexValue"
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            // colors={['#20bf6b', '#fa8231', '#fc5c65', '#ff5252', '#0fb9b1', '#2d98da', '#3867d6', '#8854d0', '#4b6584']}
            label={function (e) {
              return e.indexValue;
            }}
            tooltipLabel={function (e) {
              const value = parseInt(e.indexValue);
              const lowerRange = value - distribution;
              // return JSON.stringify(e.indexValue);
              return `Range ${lowerRange} - ${value} AED/Ft²`
              // return e.type + ": " + e.formattedValue + ": " + e.indexValue 
            }}
          />
        ) : (
          <NoData width={400} marginTop={0} />
        )}
      </Box>
      <DistributionProperties properties={distributionProperties} selectProject={selectProject} />
    </Grid2>
  );
}

export default PriceDistributionSection;