import React from "react";
import Box from '@mui/material/Box';
import Lottie from "lottie-react";
import noData from "../../lottie/error-no-data.json";


const NoData = (props) => {
  const width = props.width || 300;
  const marginTop = props.marginTop || -50;
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    }}
    >
      <Lottie animationData={noData} loop={true} style={{width: width, marginTop: marginTop}} />
    </Box>
  );
}

export default NoData;