import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { getRatingInfoMapping, formatDate, trimString } from '../../../utils.js';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import CustomizedTooltips from "../../common/tooltip.js";
import { DataGrid } from '@mui/x-data-grid';
// import Link from '@mui/material/Link';
import CustomNoRowsOverlay from '../../common/tableNoData.js';


import API from '../../../controllers/api.js';


const DevelopersRatingsSection = (props) => {
  const { t } = useTranslation();
  const { selectedId } = props;
  // Copletion Projects
  const [completionProjects, setCompletionProjects] = useState([]);
  const [loadingCP, setCPLoading] = useState(true);

  useEffect(() => {

    async function fetchComplitionData() {
      if (!selectedId) {
        setCPLoading(false);
        return
      }
      setCPLoading(true);
      // Completion Projects
      const { data: cpData } = await API.get(`/districts/${selectedId}/developersrating`)
      await setCompletionProjects(cpData);
      console.log('District Developer rating', cpData);
      setCPLoading(false);
    }
    fetchComplitionData();
  }, [selectedId])

  return (
    <Grid2 xs={12}>
      <Box>
        <Typography variant="h1">{t('district.developersRatingsSection.title')}</Typography>
        <Typography variant="subtitle1" mb={1}>{t('district.developersRatingsSection.subTitle')}<CustomizedTooltips title={t('district.developersRatingsSection.tooltip')} /></Typography>
        {loadingCP ? (
          <Skeleton style={{ height: 400, marginTop: 20 }} />
        ) : (
          <>
            <DataGrid
              minHeight={400}
              rows={completionProjects}
              columns={[
                {
                  field: 'nameFull', flex: 1, minWidth: 200, headerName: t('district.developersRatingsSection.table.name'),
                  valueGetter: (params) => params.row,
                  renderCell: ({ value }) => {
                    return (
                      // <Link
                      //   component="button"
                      //   onClick={() => {
                      //     console.log('value', value)
                      //     selectProject({ id: value.id, name: value.name });
                      //   }}
                      // >
                      value?.name
                      // </Link>
                    )
                  }
                },
                { field: 'rating', minWidth: 50, headerName: t('district.developersRatingsSection.table.rating'), renderCell: ({ value }) => (<b>{value}</b>) },
                {
                  field: 'active', minWidth: 100,
                  valueGetter: (params) => params.row?.ratingInfo,
                  headerName: t('district.developersRatingsSection.table.active'), 
                  valueFormatter: ({ value }) => getRatingInfoMapping(value, 'Active')
                },
                {
                  field: 'cancelled', minWidth: 100,
                  valueGetter: (params) => params.row?.ratingInfo,
                  headerName: t('district.developersRatingsSection.table.cancelled'), 
                  valueFormatter: ({ value }) => getRatingInfoMapping(value, 'Cancelled')
                },
                {
                  field: 'finished', minWidth: 100,
                  valueGetter: (params) => params.row?.ratingInfo,
                  headerName: t('district.developersRatingsSection.table.finished'), 
                  valueFormatter: ({ value }) => getRatingInfoMapping(value, 'Finished')
                },
                // { field: 'developer', flex: 1, minWidth: 200, headerName: t('district.upcomingProjectsSection.table.developer'), valueFormatter: ({ value }) => trimString(value) },
              ]}
              rowHeight={40}
              slots={{ noRowsOverlay: () => <CustomNoRowsOverlay context={t('district.developersRatingsSection.table.nodata')} /> }}
              columnHeaderHeight={40}
              pageSizeOptions={[10, 20, 50]}
              initialState={{
                ...completionProjects.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              sx={{
                '--DataGrid-overlayHeight': '300px',
                boxShadow: 0,
                border: 0,
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                },
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: 300,
                  textAlign: 'center',
                }
              }}
            />
          </>
        )}
      </Box>
    </Grid2>
  );
}

export default DevelopersRatingsSection;