import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { formatDate, trimString } from '../../../utils.js';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import Divider from "@mui/material/Divider";
import CustomizedTooltips from "../../common/tooltip.js";
import { DataGrid } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Chip from "@mui/material/Chip";
import CustomNoRowsOverlay from '../../common/tableNoData.js';
import { useNavigate } from "react-router-dom";

import API from '../../../controllers/api.js';


const DelayedProjectsSection = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Copletion Projects
  const [completionProjects, setCompletionProjects] = useState([]);
  const [loadingCP, setCPLoading] = useState(true);

  useEffect(() => {

    async function fetchComplitionData() {
      setCPLoading(true);
      // Completion Projects
      const { data: cpData } = await API.get(`/dubai/projects/delayed`)
      await setCompletionProjects(cpData);
      console.log('cpData', cpData);
      setCPLoading(false);
    }
    fetchComplitionData();
  }, [])

  const renderCompletion = (value) => {
    return (
      <Chip label={`${value} %`} color={value >= 99 ? 'success' : value >= 50 ? 'warning' : 'error'} size="small" variant="outlined" style={{ fontWeight: 600 }} />
    )
  }

  return (
    <Grid2 xs={12}>
      <Box>
        <Typography variant="h1">{t('dubai.delayedProjectsSection.title')}</Typography>
        <Typography variant="subtitle1" mb={1}>{t('dubai.delayedProjectsSection.subTitle')}<CustomizedTooltips title={t('dubai.delayedProjectsSection.tooltip')} /></Typography>
        <Divider light />
        {loadingCP ? (
          <Skeleton style={{ height: 400, marginTop: 20 }} />
        ) : (
          <>
            <DataGrid
              minHeight={400}
              rows={completionProjects}
              columns={[
                {
                  field: 'nameColumn', flex: 1, minWidth: 200, headerName: t('dubai.delayedProjectsSection.table.name'), renderCell: ({ value }) => {
                    return (
                      <Link
                        href={`/?district=${value.districtId}&project=${value.id}`}
                      >
                        {value.name}
                      </Link>
                    )
                  }
                },
                { field: 'completion', minWidth: 50, headerName: t('dubai.delayedProjectsSection.table.completion'), renderCell: ({ value }) => renderCompletion(value) },
                { field: 'completionDate', minWidth: 100, headerName: t('dubai.delayedProjectsSection.table.expectedAt'), valueFormatter: ({ value }) => formatDate(value) },
                { field: 'developer', flex: 1, minWidth: 200, headerName: t('dubai.delayedProjectsSection.table.developer'), valueFormatter: ({ value }) => trimString(value) },
              ]}
              rowHeight={40}
              slots={{ noRowsOverlay: () => <CustomNoRowsOverlay context={t('dubai.delayedProjectsSection.table.nodata')} /> }}
              columnHeaderHeight={40}
              pageSizeOptions={[10, 20, 50]}
              initialState={{
                ...completionProjects.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              sx={{
                '--DataGrid-overlayHeight': '300px',
                boxShadow: 0,
                border: 0,
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                },
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                },
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: 300,
                  textAlign: 'center',
                }
              }}
            />
          </>
        )}
      </Box>
    </Grid2>
  );
}

export default DelayedProjectsSection;