import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CardMedia from '@mui/material/CardMedia';

const ProgressiveCardMedia = ({ src, placeholder, alt = "", height }) => {
  const [loading, setLoading] = useState(true);
  const [currentSrc, setCurrentSrc] = useState(placeholder);

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      setCurrentSrc(src);
      setLoading(false);
      // setTimeout(() => {
      //   setCurrentSrc(src);
      //   setLoading(false);
      // }, 10000);

    };
  }, [src]);

  return (
    <CardMedia
      component="img"
      style={{
        opacity: loading ? 0.5 : 1,
        transition: "opacity .15s linear"
      }}
      height={height}
      image={currentSrc}
      alt={alt}
    />
  );
};

ProgressiveCardMedia.propTypes = {
  src: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  height: PropTypes.string,
  alt: PropTypes.string
};

export default ProgressiveCardMedia;