import { format } from 'date-fns'
import { Chip } from '@mui/material';

const formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1, });

const formatNumber = (number, fraction) => {
  let fractionDigits = fraction || fraction===0 ? fraction : 2;
  return number ? number.toLocaleString('en-US', {maximumFractionDigits: fractionDigits }) : ' - ';
}

const formImagePreview = (imageId, w, h) => {
  const baseURL = process.env.NODE_ENV === 'development' ? `http://localhost:8080/api/v1` : `https://dubai-api.euganox.com/api/v1`
  return `${baseURL}/images/resize/${w}/${h}/${imageId}`
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const formatDate = (date) => {
  if(date === '0001-01-01T00:00:00') return ' - ';
  return date ? format(new Date(date), 'dd/MM/yyyy') : ' - '
}

const formatDateTime = (date) => {
  if(date === '0001-01-01T00:00:00') return ' - ';
  return date ? format(new Date(date), 'dd/MM/yyyy HH:mm') : ' - '
}

const formatAmount = (amount) => {
  return amount ? formatter.format(amount) : ' - ';
}

const oneAsEmpty = (amount) => {
  return amount > 1 ? amount : '';
}

const stripName = (name) => {
  return name ? name.split(' ')[0] : ' - ';
}

const trimString = (string) => {
  const length = 30;
  const trimmedString = string.length > length ?
    string.substring(0, length - 3) + "..." :
    string;
  return trimmedString;
}

const trimStringLenght = (string, length) => {
  const trimmedString = string.length > length ?
    string.substring(0, length - 3) + "..." :
    string;
  return trimmedString;
}

const formatCurrency = (currency) => {
  return currency ? currency.toUpperCase() : ' - ';
}

const formatPercentage = (percentage) => {
  return percentage ? `${percentage}%` : ' - ';
}

const isTickShow = (value) => {
  //get month from the date 01.2022
  const month = parseInt(value.split('.')[0]);
  //check the month is quarter start month or not
  if (month === 1 || month === 4 || month === 7 || month === 10) {
    return true;
  }
}

const resolveYear = (year) => {
  if (year.start !== year.end) {
    return `${year.start} - ${year.end}`
  } else {
    return `${year.start}`
  }
}

const resolveCalHeight = (year) => {
  if (year.start !== year.end) {
    return (200 * (year.end - year.start + 1))
  } else {
    return 200
  }
}

const isTickShowPriceDistro = (value) => {
  //check if value is devidaible by 100
  return value % 500 === 0;
}

const calculatePercentChange = (currentPrice, oldPrice) => {
  return Math.round(((currentPrice - oldPrice) / oldPrice) * 100, 1)
}

const renderCompletion = (value) => {
  return (
    <Chip label={`${value} %`} color={value >= 99 ? 'success' : value >= 50 ? 'warning' : 'error'} size="small" variant="outlined" style={{ fontWeight: 600 }} />
  )
}

const getRatingInfoMapping = (ratingInfo, neededStatus) => {
  if(!ratingInfo) return ' - ';
  const filteredArray = ratingInfo.filter((item) => item.status === neededStatus);  
  return filteredArray.length > 0 ? filteredArray[0].count : ' - ';
}


export {
  formatNumber,
  formatDate,
  formatAmount,
  trimString,
  formatCurrency,
  formatPercentage,
  isTickShow,
  resolveYear,
  resolveCalHeight,
  isTickShowPriceDistro,
  calculatePercentChange,
  renderCompletion,
  oneAsEmpty,
  getRatingInfoMapping,
  trimStringLenght,
  stripName,
  capitalizeFirstLetter,
  formImagePreview,
  formatDateTime
}