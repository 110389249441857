
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../controllers/api';
import Divider from '@mui/material/Divider';
import CustomizedTooltips from '../common/tooltip';
import { ResponsiveLine } from '@nivo/line'
import { calculatePercentChange, formatNumber, formatDate, resolveYear } from '../../utils';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NoData from '../common/nodata';
import Register from '../common/register';
import GoPro from '../common/goPro';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const mapBeds = (beds) => {
  return beds > 0 && beds < 10 ? beds : 'all'
}

const stringifyBeds = (beds) => {
  return beds > 0 && beds < 10 ? `for ${beds} B/R` : ``
}

const PriceTrend = (props) => {
  const { t } = useTranslation();
  const { selectedId, user, dbUser, hideFilter, beds } = props;

  const TEMPLATE_GRAPH = [
    {
      id: t('project.sqmTrendSection.graph.axe1'),
      color: "hsl(211.43deg 40.38% 20.39%)",
      data: []
    }, {
      id: t('project.sqmTrendSection.graph.axe2'),
      color: "hsl(211.43deg 40.38% 20.39%)",
      data: []
    }
  ]

  const [loadingSQMP, setSQMPLoading] = useState(true);
  // Start sale data
  const [startSaleData, setStartSaleData] = useState(null);
  const [currentSqmPrice, setCurrentSqmPrice] = useState(null);
  const [loadingSST, setSSTLoading] = useState(true);

  const [transactionsData, setTransactionsData] = useState(TEMPLATE_GRAPH);
  const [transactionsVolumeData, setTransactionsVolumeData] = useState(TEMPLATE_GRAPH);

  const [startSqmPrice, setStartSqmPrice] = useState(null);

  const [year, setYear] = useState({
    start: 2023,
    end: 2024,
    key: 'previous_year'
  });

  const [roomsType, setRoomsType] = useState({
    filter: 'all',
    key: 'all'
  });

  const handleYearChange = (event) => {
    const choice = event.target.value;
    const options = {
      this_year: {
        start: 2024,
        end: 2024,
        key: 'this_year'
      },
      previous_year: {
        start: 2023,
        end: 2023,
        key: 'previous_year'
      },
      last_2_years: {
        start: 2023,
        end: 2024,
        key: 'last_2_years'
      },
      last_3_years: {
        start: 2021,
        end: 2023,
        key: 'last_3_years'
      },
      last_4_years: {
        start: 2020,
        end: 2023,
        key: 'last_4_years'
      },
      last_5_years: {
        start: 2019,
        end: 2023,
        key: 'last_5_years'
      }
    }
    setYear(options[choice]);
  };

  const handleRoomsTypeChange = (event) => {
    const choice = event.target.value;
    const options = {
      all: {
        filter: 'all',
        key: 'all'
      },
      s: {
        filter: 's',
        key: 's'
      },
      1: {
        filter: '1',
        key: '1'
      },
      2: {
        filter: '2',
        key: '2'
      },
      3: {
        filter: '3',
        key: '3'
      },
      4: {
        filter: '4',
        key: '4'
      },
      5: {
        filter: '5',
        key: '5'
      },
      p: {
        filter: 'p',
        key: 'p'
      }
    }
    setRoomsType(options[choice]);
    console.log(options[choice])
  };

  useEffect(() => {
    async function fetchSQMData() {
      if (!selectedId) {
        return
      }
      setSQMPLoading(true);
      setSSTLoading(true);
      setCurrentSqmPrice(null)
      // Transactions Data
      const { data: tranData } = await API.get(`/project/${selectedId}/transactions-by-month?yearstart=${year.start}&yearend=${year.end}&roomsTypeFilter=${mapBeds(beds)}`)
      if (tranData.length === 0) {
        setTransactionsData(TEMPLATE_GRAPH);
        setTransactionsVolumeData(TEMPLATE_GRAPH);
        setSQMPLoading(false);
      } else {
        const transactions = tranData.map((t) => {
          return {
            x: `${t.year}-${t.month >= 10 ? t.month : `0${t.month}`}-01`,
            y: t.avgsqmprice > 0 ? t.avgsqmprice : null
          }
        })
        const movingAvgGraph = tranData.map((t) => {
          return {
            x: `${t.year}-${t.month >= 10 ? t.month : `0${t.month}`}-01`,
            y: t.movingaverage > 0 ? t.movingaverage : null
          }
        })
        const transactionsReadyGraph = tranData.map((t) => {
          return {
            x: `${t.year}-${t.month >= 10 ? t.month : `0${t.month}`}-01`,
            y: t.avgsqmpriceready > 0 ? t.avgsqmpriceready : null
          }
        })
        const volumeGraph = tranData.map((t) => {
          return {
            x: `${t.year}-${t.month >= 10 ? t.month : `0${t.month}`}-01`,
            y: t.countmonth > 0 ? t.countmonth : null
          }
        })
        setTransactionsData([
          {
            id: t('project.sqmTrendSection.graph.axe3'),
            color: "hsl(37, 70%, 50%)",
            data: transactionsReadyGraph
          },
          {
            id: t('project.sqmTrendSection.graph.axe1'),
            color: "hsl(296, 70%, 50%)",
            data: transactions
          }, {
            id: t('project.sqmTrendSection.graph.axe2'),
            color: "hsl(329, 70%, 50%)",
            data: movingAvgGraph
          }
        ])
        setTransactionsVolumeData([
          {
            id: t('project.sqmTrendSection.graph.volume'),
            color: "hsl(211.43deg 40.38% 20.39%)",
            data: volumeGraph
          }
        ])
        setCurrentSqmPrice(transactions[transactions.length - 1].y)

        setStartSqmPrice(tranData[0]?.avgsqmprice > 0 ? tranData[0]?.avgsqmprice : tranData[0]?.avgsqmpriceready || 0);
        setCurrentSqmPrice(tranData.length > 2 ? tranData[tranData.length - 1]?.avgsqmprice > 0 ? tranData[tranData.length - 1]?.avgsqmprice : tranData[tranData.length - 1]?.avgsqmpriceready : 0);

        setSQMPLoading(false);
      }
    }

    async function fetchSaleStartData() {
      if (!selectedId) {
        setSSTLoading(false);
        return
      }
      setStartSaleData(null)
      setSSTLoading(true);
      const { data: ssData } = await API.get(`/projects/${selectedId}/startprice`)
      // filter records where the realPercent is lower then 0
      setStartSaleData(ssData)
      setSSTLoading(false);
    }
    fetchSQMData();
    fetchSaleStartData();
  }, [selectedId, user, dbUser, year, roomsType]);

  return (
    <>
      <Grid2 xs={12} md={12}>
        <Typography variant="h1">{t('project.sqmTrendSection.title')} {resolveYear(year)} {stringifyBeds(beds)}</Typography>
        <Typography variant="subtitle1" mb={1}>{t('project.sqmTrendSection.subTitle')}
          <CustomizedTooltips title={t('project.sqmTrendSection.tooltip')} />
        </Typography>
        <Divider light />
        {!user || hideFilter ? (null) : (
          <Grid2 xs={12} md={12} p={2} pb={1} pt={1} mt={0} sx={{ background: '#f8f8f8', borderStyle: 'solid', borderColor: 'rgba(0, 0, 0, 0.12)', borderBottomWidth: 'thin' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid2 xs={12} md={12} p={0}>
                <FilterAltIcon fontSize="small" sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '12px', display: { xs: "none", lg: "inline-flex" } }} />
                <FormControl sx={{ m: 1, minWidth: { xs: 90, sm: 100 } }} size="small">
                  <InputLabel id="demo-select-small-label">{t('district.filters.year')}</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={year.key}
                    label="Year"
                    style={{ height: 29, fontSize: 13 }}
                    onChange={handleYearChange}
                  >
                    <MenuItem value={'this_year'} key={2023}>This year</MenuItem>
                    <MenuItem value={'previous_year'} key={2022}>Previous year</MenuItem>
                    <MenuItem value={'last_2_years'} key={2021}>Last 2 years</MenuItem>
                    <MenuItem value={'last_3_years'} key={2020}>Last 3 years</MenuItem>
                    <MenuItem value={'last_4_years'} key={2020}>Last 4 years</MenuItem>
                    <MenuItem value={'last_5_years'} key={2020}>Last 5 years</MenuItem>
                  </Select>
                </FormControl>


                <FormControl sx={{ m: 1, minWidth: { xs: 120, sm: 130 } }} size="small">
                  <InputLabel id="demo-select-small-label">{t('district.filters.unitTypes')}</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={roomsType.key}
                    label="Unit types"
                    style={{ height: 29, fontSize: 13 }}
                    onChange={handleRoomsTypeChange}
                  >
                    <MenuItem value={'all'} key={'all'}>All types</MenuItem>
                    <MenuItem value={'s'} key={'s'}>Studio</MenuItem>
                    <MenuItem value={'1'} key={'1'}>1 B/R</MenuItem>
                    <MenuItem value={'2'} key={'2'}>2 B/R</MenuItem>
                    <MenuItem value={'3'} key={'3'}>3 B/R</MenuItem>
                    <MenuItem value={'4'} key={'4'}>4 B/R</MenuItem>
                    <MenuItem value={'5'} key={'5'}>5 B/R</MenuItem>
                    <MenuItem value={'p'} key={'p'}>Penthouse</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Box>
          </Grid2>
        )}
      </Grid2>

      {!user ? (null) : (
        <Grid2 container xs={12} md={12} p={1}>
          {loadingSQMP ? <Grid2 xs={12} sm={6}><Skeleton style={{ height: '70px', width: 200 }} /></Grid2> : currentSqmPrice > 0 && startSqmPrice > 0 ? (
            <Grid2 xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle2" fontWeight={'600'}>
                  {t('district.sqmPriceSection.priceChange.title')}
                  <CustomizedTooltips title={t('district.sqmPriceSection.priceChange.tooltip')} />
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="h2">
                  {calculatePercentChange(currentSqmPrice, startSqmPrice) > 0 ? (
                    <NorthEastIcon color={'success'} fontSize="10" />
                  ) : (
                    <SouthEastIcon color={'error'} fontSize="10" />
                  )}
                  {calculatePercentChange(currentSqmPrice, startSqmPrice)} %
                  <Typography variant="subtitle2">
                    {t('project.incomeLossSection.from')} {formatNumber(Number(startSqmPrice))} to {formatNumber(Number(currentSqmPrice))} AED/Ft²
                  </Typography>
                </Typography>
              </Box>
            </Grid2>
          ) : (null)}

          {loadingSST ? <Grid2 xs={12} sm={6}><Skeleton style={{ height: '70px', width: 200 }} /></Grid2> : currentSqmPrice && startSaleData ? (
            <Grid2 xs={12} sm={6}>
              <Box>
                <Typography variant="subtitle2" fontWeight={'600'}>
                  {t('project.incomeLossSection.title')}
                  <CustomizedTooltips title={t('project.incomeLossSection.tooltip')} />
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="h2">
                  {calculatePercentChange(currentSqmPrice, startSaleData?.avgsqmprice) > 0 ? (
                    <NorthEastIcon color={'success'} fontSize="10" />
                  ) : (
                    <SouthEastIcon color={'error'} fontSize="10" />
                  )}
                  {calculatePercentChange(currentSqmPrice, startSaleData?.avgsqmprice)} %
                  <Typography variant="subtitle2">
                    {t('project.incomeLossSection.from')} {formatNumber(Number(startSaleData?.avgsqmprice))} {t('project.incomeLossSection.mid')} {formatDate(startSaleData?.startdate)}
                  </Typography>
                </Typography>
              </Box>
            </Grid2>
          ) : (null)}
        </Grid2>
      )}
      <Grid2 xs={12} md={12}>
        {!user ? <Register height={'550px'} forpro={false} preview={'pro-project-trend'} /> : loadingSQMP ? <Skeleton style={{ height: 270, marginTop: 20 }} /> : transactionsData[0]?.data?.length ? (
          <Box style={{ height: 300 }}>
            <ResponsiveLine
              data={transactionsData}
              colors={['#27ae60', '#fc5c65', '#d1d8e0']}
              margin={{ top: 10, right: 20, bottom: 50, left: 40 }}
              xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'month' }}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                // stacked: true,
                reverse: false
              }}
              yFormat={value => `${formatNumber(value)} AED/Ft²`}
              axisTop={null}
              axisRight={null}
              // axisBottom={{
              //   format: '%b %d',
              //   legend: 'time scale',
              //   legendOffset: -12,
              //   tickValues: 'every 2 days'
              // }}
              xFormat="time:%Y-%m-%d"
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle',
                format: '%m.%Y',
                // format: function (value) {
                //   if (year.start !== year.end) {
                //     return isTickShow(value) ? value : '';
                //   } else {
                //     return value;
                //   }
                // },
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -40,
                legendPosition: 'middle'
              }}
              // sliceTooltip={p => {
              //   return <BasicTooltip
              //     id={p.index}
              //     value={JSON.stringify(p.slice.points)}
              //     // color={p.data[0].color}
              //     enableChip
              //   />;
              // }}
              // markers={[
              //   {
              //     axis: 'y',
              //     legend: 'Project start price',
              //     legendPosition: 'bottom-left',
              //     lineStyle: {
              //       stroke: '#b0413e',
              //       strokeWidth: 1
              //     },
              //     value: 800
              //   }
              // ]}
              pointSize={10}
              pointColor="#ffffff"
              pointBorderWidth={2}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabelYOffset={-12}
              enableArea={false}
              // enableSlices="x"
              crosshairType="bottom-left"
              useMesh={true}
              legends={[]}
              enableSlices="x"
              curve="catmullRom"
              motionConfig="default"
            />
          </Box>
        ) : <NoData width={450} marginTop={-30} />}
        {!user ? (null) : (
          <Box style={{ height: 150 }}>
            {loadingSQMP ? <Skeleton style={{ height: 130, marginTop: 20 }} /> : transactionsData[0]?.data?.length ? (
              <ResponsiveLine
                data={transactionsVolumeData}
                margin={{ top: 10, right: 20, bottom: 30, left: 40 }}
                xFormat="time:%Y-%m-%d"
                xScale={{ type: 'time', format: '%Y-%m-%d', precision: 'month' }}
                colors={['#84817a', '#d1d8e0']}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: true,
                  reverse: false
                }}
                yFormat=" >-,.0f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: 36,
                  legendPosition: 'middle',
                  format: '%m.%Y',
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -40,
                  legendPosition: 'middle'
                }}
                pointColor="#ffffff"
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                enableArea={true}
                // enableSlices="x"
                crosshairType="bottom-left"
                useMesh={true}
                legends={[]}
                enableSlices="x"
                lineWidth={2}
                pointSize={7}
                curve="step"
                motionConfig="default"
              />
            ) : (null)}
          </Box>
        )}
      </Grid2>
    </>
  );
}

export default PriceTrend;