import React, { useEffect, useState, useRef, useContext } from "react";
import AppBar from '@mui/material/AppBar';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import SubscriptionContext from "../contexts/SubscriptionContext";
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import API from '../controllers/api.js';
import Logo from '../theme/logo.svg';

import {
  signOut,
} from "firebase/auth";

import { auth } from "../firebase";
import Skeleton from "react-loading-skeleton";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'black',
  fontSize: '16px',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `5px`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  marginTop: 2,
  marginRight: 10,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha(theme.palette.common.black, 0.05),
  // '&:hover': {
  //   backgroundColor: alpha(theme.palette.common.black, 0.15),
  // },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: '100%',
  },
}));

const OurMenu = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  // const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);
  const { t } = useTranslation();

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [search, setSearch] = useState(null);
  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (search === null) return
      const { data } = await API.get(`/search?q=${search}`)
      console.log(data)
      setSearchResult(data.hits)
    }
    fetchData()
  }, [search]);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSearch = async (e) => {
    const search = e
    setOpenFilter(true)
    setSearch(search)
  }

  const handleChange = () => {
    handleCloseUserMenu()
    setOpen((prev) => !prev);
    setOpenFilter((prev) => !prev);
    // setTimeout(() => {
    //   inputRef.current && inputRef.current.focus();
    // }, 300);
  };

  const propogateSearch = (item) => {
    let navigateto = '/'
    if (item?.type === 'district') {
      // setSearch(item?.districtName)
      navigateto = `/district/${item?.id}`
    } else if (item?.type === 'project') {
      // setSearch(item?.projectName)
      navigateto = `/district/${item?.districtId}/project/${item?.projectId}`
    }
    if (location.pathname === '/' || location.pathname.includes('/district', '/project')) {
      props.handleSearch(item)
      handleChange()
    } else {
      navigate(navigateto)
    }
  }

  return (
    <>
      <AppBar position="fixed" sx={{
        zIndex: { xs: 1000, sm: 1000 },
        backgroundColor: 'rgba(255, 255, 255, 1)',
        // backdropFilter: 'blur(6px)',
        pr: { xs: 0, sm: 2 },
        pl: 0,
        borderBottomColor: 'rgba(34, 34, 34, .08)',
        borderStyle: 'solid',
        borderBottomWidth: '1px',
        minHeight: '57px',
      }}>
        <Container maxWidth={false} sx={{ minHeight: '56px'}}>
          <Toolbar disableGutters>
            <>
              {/* SEARCH */}

              <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingLeft: {xs: '70px', sm:'80px'},
                paddingTop: {xs: '5px', sm:'0px'},

                // marginLeft: 2,
                // marginRight: 2,
              }}>
                <MagnifyingGlassIcon style={{ color: '#000', width: 25, margin: '5px', hidden: location.pathname === '/' ? true : false }} />
                <Box sx={{ width: { xs: '100%', md: 350 }, marginLeft: 0, hidden: location.pathname === '/' ? true : false, }}>
                  <Search>
                    <StyledInputBase
                      inputRef={inputRef}
                      placeholder={t('general.search')}
                      inputProps={{ 'aria-label': 'search' }}
                      value={search}
                      onChange={(e) => handleSearch(e.target.value)}
                      onFocus={() => setOpenFilter(true)}
                    />
                  </Search>
                </Box>
              </Box>

              {searchResult && (
                <Box
                  sx={{
                    p: 1,
                    left: { xs: 0, md: 85 },
                    width: { xs: '100%', md: 345 },
                    marginTop: {xs: '57px', sm: '78px'},
                  }}
                  style={{
                    top: 0,
                    position: 'absolute',
                    maxHeight: 330,
                    overflow: 'scroll',
                    marginRight: 0,
                    // position: 'fixed',
                    backgroundColor: 'white',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                    boxShadow: "10px 10px 30px -17px rgba(122,122,122,1)",
                    zIndex: 10000,
                    display: openFilter ? 'flex' : 'none'
                  }}
                >
                  <List dense={false} style={{ width: '100%', margin: 5, marginTop: 0 }}>
                    {searchResult.length > 0 && searchResult.map((item, index) => (
                      <>
                        <ListItemButton key={index} onClick={() => propogateSearch(item)}>
                          <ListItemText
                            sx={{ color: 'black' }}
                            primary={item.type === 'district' ? item.districtName : item.projectName}
                            secondary={item.type === 'district' ? `District` : 'Project'}
                          />
                        </ListItemButton>
                        <Divider component="li" />
                      </>
                    ))}
                  </List>
                </Box>
              )}
            </>
            {/* ) : null} */}

            {dbUser === undefined ? (<Skeleton width={40} height={40}/>) : dbUser ? (
              <Box sx={{ flexGrow: 0 }}>
                <IconButton sx={{ p: 0, display: { xs: 'none', sm: 'block' } }}>
                  <Badge overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} color="primary" badgeContent={'Pro'} invisible={dbUser?.tier !== 'pro'}>
                    <Avatar alt={dbUser?.name || 'M'} src={dbUser?.avatar} variant="rounded" />
                  </Badge>
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`)}
                  sx={{ my: 1, display: { xs: 'none', sm: 'block' } }}
                >
                  {t('general.topNav.singIn')}
                </Button>
              </Box>
            )}

          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default OurMenu;