import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";
import API from '../controllers/api.js'
import secureAPI from '../controllers/secureApi.js'
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import FormHelperText from '@mui/material/FormHelperText';

import FilesDropzone from './common/FilesDropzone.js';

import { useForm } from 'react-hook-form';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { NumericFormat } from "react-number-format";

import Autocomplete from '@mui/material/Autocomplete';
import Register from './common/register.js';
import PleaseVerify from './common/pleaseVerify.js';

const AddAd = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [inputValue, setInputValue] = useState('');

  const [projectId, setProjectId] = useState(null);
  const [projectIdError, setProjectIdError] = useState(false);

  const [generateLoading, setGenerateLoading] = useState(false);
  const [generateError, setGenerateError] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch, reset } = useForm({
    defaultValues: {
      description: ''
    },
  });
  const onSubmit = async data => {
    if (!projectId) {
      setProjectIdError(true)
      return
    }
    const finalData = {
      ...data,
      projectId,
    }
    console.log('finalData')
    console.log(finalData)
    try {
      const data = await secureAPI.post('/offer', finalData)
      if (data.status === 200) {
        reset()
        navigate('/specials')
      }
    }
    catch (e) {
      console.log(e)
    }
  }
  // console.log(errors);

  const generateDesc = async () => {
    setGenerateLoading(true)
    const dataToSend = getValues()
    if (!dataToSend.projectName || !dataToSend.bedrooms || !dataToSend.bathrooms || !dataToSend.floor || !dataToSend.size || !dataToSend.type || !dataToSend.price) {
      setGenerateError(true)
      setGenerateLoading(false)
      return
    }
    const { data: response } = await API.post('/lab/ai/dubai-content', dataToSend)
    await setValue('description', response.result)
    setGenerateError(false)
    setGenerateLoading(false)
  }

  useEffect(() => {
    if (dbUser) {
      console.log('dbUser')
      console.log(dbUser)
      setValue('email', dbUser.email)
    }
  }, [dbUser]);


  useEffect(() => {
    const fetchProjects = async () => {
      const { data } = await API.get(`/search/projects?q=${inputValue}`)
      const hits = data.hits || []
      setOptions(hits);
    }
    fetchProjects()
  }, [inputValue, searchValue]);

  console.log(errors)

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 item xs={12} md={12} pb={3}>
              <Grid2 xs={12} md={12} pb={1}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  {t('addspecial.header')}
                </Typography>
                <Typography variant="subtitle1">{t('addspecial.subheader')}</Typography>
              </Grid2>
              <Divider />
            </Grid2>

            <Grid2 xs={12} md={12} p={0}>
              {dbUser === undefined ? (null) : !user ? <Register height={'300px'} preview={'empty'} /> : !dbUser ? (null) : dbUser?.type !== 'BROKER' ? (
                <PleaseVerify height={'300px'} />
              ) : (
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1 },
                    '& button': { m: 1 },
                  }}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked margin="normal" {...register("priceOffer", {})} />} label={t('addspecial.allow-counter-offers')} />
                    <Typography variant="subtitle1">{t('addspecial.couneroffer-desc')}</Typography>
                    <FormControlLabel control={<Checkbox color={'error'} margin="normal" {...register("showProOnly", {})} />} label={t('addspecial.showonly-verified')} />
                    <Typography variant="subtitle1">{t('addspecial.only-verified-desc')}</Typography>

                  </FormGroup>

                  <Grid2 xs={12} md={12} pb={1} pt={2}>
                    <Typography variant="h2" sx={{
                      pb: 1,
                      fontWeight: 800,
                      color: '#000'
                    }}>
                      {t('addspecial.about-the-property')}
                    </Typography>
                  </Grid2>
                  <Divider />

                  <FormGroup>
                    <Autocomplete
                      id="asynchronous-demo"
                      sx={{
                        width: 300, '& MuiAutocomplete-popupIndicator': {
                          margin: '0px !important'
                        }
                      }}
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.projectId === value.projectId}
                      getOptionLabel={(option) => option.projectName}
                      options={options}
                      filterOptions={(x) => x}
                      onChange={(event, newValue) => {
                        console.log('newValue')
                        console.log(newValue)
                        setOptions(newValue ? [newValue, ...options] : options);
                        setSearchValue(newValue);
                        setProjectId(newValue?.projectId || null)
                        if (!newValue?.projectId) {
                          setProjectIdError(true)
                        } else {
                          setProjectIdError(false)
                        }
                      }}
                      noOptionsText="Start typing to search for a project"
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="normal"
                          label={t('addspecial.project-name')}
                          sx={{
                            '& button': {
                              marginTop: '0px !important'
                            }
                          }}
                          {...register("projectName", { required: { value: true, message: t('addspecial.error.project-is-required') } })}
                          error={Boolean(errors.projectName)} helperText={errors.projectName ? errors.projectName.message : ''}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}

                      renderOption={(props, option) => {

                        return (
                          <div key={option.projectId}>
                            <ListItemButton {...props}>
                              <ListItemText
                                sx={{ color: 'black' }}
                                primary={option.projectName}
                                secondary={option.districtName}
                              />
                            </ListItemButton>
                            <Divider component="li" />
                          </div>
                        );
                      }}
                    />
                    {/* <TextField sx={{display: 'none'}} {...register("projectId", { required: true })}  margin="normal" fullWidth /> */}
                    {/* <TextField label="Project" variant="outlined" placeholder="Project"  {...register("project", { required: true })} margin="normal" /> */}
                    <Typography variant="subtitle1">{t('addspecial.please-select-the-project')}</Typography>

                    <Grid2 container pb={0}>
                      <Grid2 container xs={12} md={12}>
                        <Grid2 item xs={3} md={3}>
                          <FormControl margin="normal" fullWidth>
                            <InputLabel id="type-select-label">{t('addspecial.type')}</InputLabel>
                            <Select
                              defaultValue={t('specials.apartment')}
                              labelId="type-label"
                              id="type"
                              label={t('addspecial.type')}
                              {...register("type", { required: { value: true, message: t('addspecial.is-required') } })}
                              error={Boolean(errors.type)} helperText={errors.type ? errors.type.message : ''}
                            >
                              <MenuItem value={t('specials.apartment')}>{t('specials.apart-flat')}</MenuItem>
                              <MenuItem value={t('specials.villa')}>{t('specials.villa')}</MenuItem>
                              <MenuItem value={t('specials.other')}>{t('specials.other')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid2>
                      </Grid2>
                      <Grid2 item xs={3} md={3} pr={2}>
                        <TextField label={t('addspecial.size')} variant="outlined" placeholder={t('addspecial.size')} {...register("size", { required: { value: true, message: t('addspecial.is-required') } })}
                          error={Boolean(errors.size)} helperText={errors.size ? errors.size.message : ''} InputProps={{
                            endAdornment: <InputAdornment position="end">sqft</InputAdornment>,
                          }} margin="normal" fullWidth />
                      </Grid2>
                      <Grid2 item xs={3} md={3} pr={2}>
                        <TextField label={t('addspecial.bedrooms')} variant="outlined" placeholder={t('addspecial.bedrooms')} {...register("bedrooms", { required: { value: true, message: t('addspecial.is-required') } })}
                          error={Boolean(errors.bedrooms)} helperText={errors.bedrooms ? errors.bedrooms.message : ''} margin="normal" fullWidth />
                      </Grid2>
                      <Grid2 item xs={3} md={3} pr={2}>
                        <TextField label={t('addspecial.bathrooms')} variant="outlined" placeholder={t('addspecial.bathrooms')} {...register("bathrooms", { required: { value: true, message: t('addspecial.is-required') } })}
                          error={Boolean(errors.bathrooms)} helperText={errors.bathrooms ? errors.bathrooms.message : ''} margin="normal" fullWidth />
                      </Grid2>

                      <Grid2 item xs={3} md={3}>
                        <FormControl margin="normal" fullWidth>
                          <InputLabel id="demo-simple-select-label">{t('addspecial.floor')}</InputLabel>
                          <Select
                            defaultValue={""}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t('addspecial.floor')}
                            {...register("floor", { required: { value: true, message: t('addspecial.is-required') } })}
                            error={Boolean(errors.floor)}
                            inputProps={{
                              ...props,
                              helperText: errors.floor ? errors.floor.message : '',
                            }}
                          >
                            <MenuItem value={t('addspecial.high')}>{t('addspecial.high')}</MenuItem>
                            <MenuItem value={t('addspecial.middle')}>{t('addspecial.middle')}</MenuItem>
                            <MenuItem value={t('addspecial.low')}>{t('addspecial.low')}</MenuItem>
                          </Select>
                          <FormHelperText error>{errors.floor ? errors.floor.message : ''}</FormHelperText>
                        </FormControl>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        {t('addspecial.price-and-commision')}
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 container pb={0}>
                      <Grid2 container xs={12} md={12} p={0}>
                        <Grid2 xs={12} md={3} p={0}>
                        <NumericFormat
                          label={t('addspecial.price')}
                          customInput={TextField}
                          thousandSeparator={true}
                          onValueChange={(v) => {
                            setValue('price',v.value)
                          }}
                          {...register("price", { required: { value: true, message: t('addspecial.is-required') }, min: { value: 80000, message: t('addspecial.the-price-should-be') } })}
                          variant="outlined" 
                          placeholder={t('addspecial.price')} 
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{t('general.aed')}</InputAdornment>,
                          }}
                          error={Boolean(errors.price)} helperText={errors.price ? errors.price.message : ''}
                          name="price"
                          margin="normal"
                          fullWidth
                        />
                          {/* <TextField label="Price" variant="outlined" placeholder="Price" InputProps={{
                            endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                          }} margin="normal" fullWidth {...register("price", { required: { value: true, message: 'This field is required' }, min: { value: 100000, message: 'The price should be at least 100.000' } })}
                            error={Boolean(errors.price)} helperText={errors.price ? errors.price.message : ''} /> */}
                        </Grid2>
                        <Grid2 xs={12} md={9} p={2} alignContent={'center'} display={'flex'}>
                          <FormControlLabel control={<Checkbox color={'error'} margin="normal" {...register("priceBrokersOnly", {})} />} label={t('addspecial.show-price-only-to-verified-agents')} />
                        </Grid2>
                        <Grid2 xs={12} md={12} p={0}>
                          <Alert severity="info">{t('addspecial.the-price-should-be-the-full')}</Alert>
                        </Grid2>
                      </Grid2>

                      <Grid2 container xs={12} md={12} p={0} mb={-1}>
                        <Grid2 xs={3} md={3} p={0} pt={2}>
                          <FormControlLabel control={<Checkbox defaultChecked margin="normal" {...register("shareCommision", {})} />} label={t('addspecial.ready-to-share-the-commission')} />
                        </Grid2>
                        <Typography variant="subtitle1">{t('addspecial.in-case-the-offer-will-come')}</Typography>
                      </Grid2>

                      <Grid2 container xs={12} md={12} p={0}>
                        <Grid2 xs={3} md={3} p={0}>
                          <TextField label={t('addspecial.shared-commision')} variant="outlined" placeholder={t('addspecial.shared-commision')} {...register("commision", {})} InputProps={{
                            endAdornment: <InputAdornment position="end">{t('addspecial.percent')}</InputAdornment>,
                          }} margin="normal" fullWidth disabled={!watch('shareCommision')} />
                        </Grid2>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        {t('addspecial.description-and-photos')}
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 container xs={12} md={12} p={0}>
                      <Grid2 xs={6} md={6} p={0}>
                        <FormGroup>
                          <TextField label={t('addspecial.offer-title')} variant="outlined" placeholder={t('addspecial.title')} {...register("title", { required: { value: true, message: t('addspecial.is-required') } })}
                            error={Boolean(errors.title)} helperText={errors.title ? errors.title.message : ''} margin="normal" />
                          <TextField label={t('addspecial.description')} variant="outlined" {...register("description", { required: { value: true, message: t('addspecial.is-required') } })}
                            error={Boolean(errors.description)} helperText={errors.description ? errors.description.message : ''} margin="normal" multiline
                            rows={7} InputLabelProps={{ shrink: !!watch('description') }} />
                          <Box sx={{ float: 'left' }}>
                            <Button disabled={generateLoading} variant="outlined" color="primary" size="small" onClick={() => generateDesc()}>{generateLoading ? `${t('addspecial.wait-a-sec')}` : t('addspecial.generate-description')}</Button>
                            <FormHelperText error>{generateError ? t('addspecial.please-enter-all-datails') : ''}</FormHelperText>
                          </Box>
                        </FormGroup>
                      </Grid2>
                      <Grid2 xs={6} md={6} pb={0} p={0} pl={2} pt={2} >
                        <FilesDropzone
                          value={getValues().images}
                          {...register("images", { required: { value: true, message: t('addspecial.at-least-one-image-is-required') } })}
                          onChange={(value) => setValue('images', value)}
                          multiple
                        />
                        <FormHelperText error>{errors.images ? errors.images.message : ''}</FormHelperText>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        {t('addspecial.contacts')}
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 container xs={12} md={12} p={0}>
                      <Grid2 xs={6} md={6} p={0}>
                        <TextField InputLabelProps={{ shrink: !!watch('email') }} fullWidth error={Boolean(errors.email)} helperText={errors.email ? errors.email.message : ''} label={t('addspecial.email')} variant="outlined" placeholder={t('addspecial.address')} {...register("email", { required: { value: true, message: t('addspecial.is-required') } })} margin="normal" />
                        <Typography variant="subtitle1">
                          {t('addspecial.please-note-this-email-will-not-be-shown')} <b>notifications@mappi.ae</b>{t('addspecial.please-whitelist-this-email')}
                        </Typography>
                      </Grid2>
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        {t('addspecial.exclusivity')}
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 xs={12} md={12} pb={3} pt={2}>
                      <FormControlLabel control={<Checkbox defaultChecked margin="normal" {...register("alreadyListed", {})} />} label={t('addspecial.this-property-is-listed-on-other-portals')} />
                    </Grid2>

                    <Grid2 xs={12} md={12} pb={1} pt={2}>
                      <Typography variant="h2" sx={{
                        pb: 1,
                        fontWeight: 800,
                        color: '#000'
                      }}>
                        {t('addspecial.you-must-know')}
                      </Typography>
                    </Grid2>
                    <Divider />

                    <Grid2 xs={12} md={12} pb={5} pt={2}>
                    <Typography variant="body" pb={1}  component="div">
                        {t('addspecial.mustknow.1')}
                      </Typography>
                      <Typography variant="body" pb={1}  component="div">
                        {t('addspecial.mustknow.2')}
                      </Typography>
                      <Typography variant="body" pb={3}  component="div">
                        {t('addspecial.mustknow.3')}
                      </Typography>
                      <Typography variant="subtitle1" pb={3} component="div">
                        {t('addspecial.tnc')}
                      </Typography>
                      <Button size="large" type="submit" variant="contained" color="primary" fullWidth pb={20}>{t('addspecial.post-the-special-offer')}</Button>
                    </Grid2>
                  </FormGroup>
                </Box>
              )}

            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default AddAd;