// npm install @mui/x-data-grid

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import Skeleton from 'react-loading-skeleton'
import Grid2 from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import API from '../../../controllers/api.js';
import { format } from 'date-fns'
import Link from '@mui/material/Link';
import Chip from "@mui/material/Chip";

const formatDate = (date) => {
  if (!date) {
    return '-';
  }
  return format(new Date(date), 'dd/MM/yyyy')
}

const renderCompletion = (value) => {
  if (!value) {
    return '';
  }
  return (
    <Chip label={`${value} %`} color={value >= 99 ? 'success' : value >= 50 ? 'warning' : 'error'} size="small" variant="outlined" style={{ fontWeight: 600 }} />
  )
}

const trimString = (string) => {
  if (!string) {
    return '-';
  }
  const length = 30;
  const trimmedString = string.length > length ?
    string.substring(0, length - 3) + "..." :
    string;
  return trimmedString;
}

const DistributionProperties = (props) => {

  const { t } = useTranslation();

  const [properties, setProperties] = useState(props.properties);
  const [dataLoading, setDataLoading] = useState(true); // [1
  const [outProperties, setOutProperties] = useState([]);

  const selectProject = props.selectProject;

  useEffect(() => {
    setProperties(props.properties);
  }, [props.properties]);

  useEffect(() => {
    async function fetchData() {
      if(properties || properties.length > 0) {
        setDataLoading(true);
        const { data } = await API.post(`/districts/projects/distribution-filter`, {
          projects: properties
        });
        console.log("Distribution properties", properties);
        setOutProperties(data);
        setDataLoading(false);
      } else {
        setOutProperties([]);
        setDataLoading(false);
      }
    }
    fetchData();
  }, [properties]);

  return (
    <>
      <Grid2 xs={12}>
        {dataLoading ? <Skeleton variant="rectangular" width={'100%'} height={190} /> : (
          <DataGrid
            rows={outProperties}
            columns={[
              {
                field: 'nameColumn', flex: 1, minWidth: 200, headerName: t('dubai.upcomingProjectsSection.table.name'), renderCell: ({ value }) => {
                  return (
                    <Link
                      component="button"
                      onClick={() => {
                        console.log('value', value)
                        selectProject({ id: value.id, name: value.name });
                      }}
                    >
                      {value.name}
                    </Link>
                  )
                }
              },
              { field: 'completion', minWidth: 50, headerName: t('dubai.upcomingProjectsSection.table.completion'), renderCell: ({ value }) => renderCompletion(value) },
              { field: 'completionDate', minWidth: 100, headerName: t('dubai.upcomingProjectsSection.table.expectedAt'), valueFormatter: ({ value }) => formatDate(value) },
              { field: 'developer', flex: 1, minWidth: 200, headerName: t('dubai.upcomingProjectsSection.table.developer'), valueFormatter: ({ value }) => trimString(value) },
            ]}
            rowHeight={40}
            slots={{ noRowsOverlay: () => <Typography variant="subtitle1" pt={5} mb={1}>{t('dubai.analyticsSection.distribution.tableNoData')}</Typography> }}
            columnHeaderHeight={40}
            pageSizeOptions={[10, 20, 50]}
            initialState={{
              ...outProperties.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            sx={{
              boxShadow: 0,
              border: 0,
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
              },
              '& .MuiDataGrid-cell:hover': {
                color: 'primary.main',
              },
              '& .MuiDataGrid-virtualScroller': {
                minHeight: 100,
                textAlign: 'center',
              }
            }}
          />
        )}
      </Grid2>
    </>
  );
}

export default DistributionProperties;