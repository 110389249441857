import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FileIcon, defaultStyles } from 'react-file-icon';
import Skeleton from 'react-loading-skeleton'
import Grid2 from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import API from '../../controllers/api.js';
import AuthContext from '../../contexts/AuthContext.js';
import SubscriptionContext from '../../contexts/SubscriptionContext.js';
import GoPro from '../common/goPro.js';
import Register from '../common/register.js';
import NoData from '../common/nodata.js';
import CustomizedTooltips from '../common/tooltip.js';

import download from 'downloadjs';

const DocCloud = (props) => {
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(true)
  const [docs, setDocs] = useState([])


  useEffect(() => {
    const fetchData = async () => {
      const response = await API.get(`/projects/${props.projectId}/documents`)
      setDocs(response.data)
      setDataLoading(false)
    }
    setDataLoading(true)
    fetchData()
  }, [props.projectId]);

  // const onDownload = (href) => {
  //   const link = document.createElement("a");
  //   link.download = `download.txt`;
  //   link.href = href;
  //   link.click();
  // };

  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12} md={12} container spacing={1} p={0}>
      <Grid2 xs={12} md={12}>
                      <Typography variant="h1">{t('project.documentCloud.title')}</Typography>
                      <Typography variant="subtitle1" mb={1}>{t('project.documentCloud.subTitle')}<CustomizedTooltips title={t('project.documentCloud.tooltip')} /></Typography>
                      <Divider light />
                    </Grid2>

        {!user ? <Register height={'300px'} forpro={false} preview={'pro-project-docs'} />
            : dataLoading ? <Skeleton variant="rectangular" width={'100%'} height={250} />
              : docs.length === 0 ? <NoData width={300} marginTop={-50} />
                : (
                  <>
                    <Grid2 xs={12} md={12} pt={3}>
                      {docs.map((doc) => (
                        <Box key={doc.id} sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          mb: 2,
                          width: '100%',
                        }}>
                          <div style={{ width: 48, p: 1 }}>
                            <FileIcon extension={doc.format} {...defaultStyles[doc.format]} />
                          </div>
                          <Box key={doc.id} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'center',
                            ml: 2,
                          }}>
                            <Typography variant="h4" mb={1} sx={{ mb: 0 }}>{doc.name}</Typography>
                            <Typography variant="subtitle1" mb={1} sx={{ mb: 0 }}>{doc.format}</Typography>
                          </Box>
                          <Button onClick={() => download(doc.url)} variant="contained" color="primary" style={{ marginLeft: 'auto' }}>
                            Download
                          </Button>
                        </Box>
                      ))}
                    </Grid2>
                  </>
                )}
      </Grid2>
    </Grid2>
  );

}

export default DocCloud;