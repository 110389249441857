import axios from 'axios';
import { getAuth } from "firebase/auth";

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? `http://localhost:8080/api/v1` : `https://dubai-api.euganox.com/api/v1`

const apiInstance = axios.create();

apiInstance.interceptors.request.use(
  async config => {
    let token = null;
    if (getAuth().currentUser) {
      token = await getAuth().currentUser.getIdToken();
    }
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    // console.log(token);
    // console.log(`SkillsPassAuthorization = 'Bearer ${token}'`);
    return config;
  },
  error => {
    // if 401 error, logout user
    return Promise.reject(error);
  },
);

apiInstance.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
    // if (condition) {
    //   // Handle error
    // } else {
    //   // This is important, so that unhandled cases make axios throw errors
    //   return Promise.reject(error);
    // }
  }
);

export default apiInstance;