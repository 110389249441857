import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';


const NoMarketData = (props) => {
  const { t } = useTranslation();
  const height = props.height || 300;
  const marginTop = props.marginTop || 0;
  
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: {
        xs: '100%',
        md: '50%',
      },
      margin: 'auto',
      height: height,
      mt: marginTop,
      background: `linear-gradient(rgba(255,255,255,.85), rgba(255,255,255,.85)), url(/preview/empty.png)`,
      backgroundSize: 'cover',
    }}
    >
      <Typography background={'white'} variant="h1">{t('project.marketData.noDataTitle')}</Typography>
      <Typography variant="subText" background={'white'} textAlign={'center'} mb={1} pt={2}>{t('project.marketData.explain')}</Typography>
      <Link to={t('project.marketData.ctaLink')} target="_blank">
        <Typography style={{textDecoration: 'underline'}}variant="subText" background={'white'} textAlign={'center'} mb={1}>{t('project.marketData.explainCta')}</Typography>
      </Link>
    </Box>
  );
}

export default NoMarketData;