import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { oneAsEmpty } from '../../../utils';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import { ResponsiveTreeMap } from '@nivo/treemap'
import CustomizedTooltips from "../../common/tooltip.js";
import NoData from "../../common/nodata.js";


const DevelopersDistributionSection = (props) => {
  const { t } = useTranslation();
  const { selectedId, selectedName, year } = props;
  // Basic Data
  const [graphData, setGraphData] = useState({
    name: "District",
    children: []
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (!selectedId) {
        setLoading(false);
        return
      }
      setLoading(true);
      // Basic Data
      const { data: bData } = await API.get(`/districts/${selectedId}/develpersdistribution`)
      console.log('Вум вшыекшигешщт', bData);
      await setGraphData(bData);
      // await setGraphData({
      //   "name": "dd",
      //   "children": [
      //     {
      //       "name": "DAMAC",
      //       "children": [
      //         {
      //           "name": "LLC1",
      //           "children": [
      //             {
      //               "name": "Volume",
      //               "loc": 1327760
      //             },
      //           ]
      //         },
      //         {
      //           "name": "LLC2",
      //           "children": [
      //             {
      //               "name": "Volume",
      //               "loc": 1327760
      //             },
      //           ]
      //         }
      //       ]
      //     },
      //     {
      //       "name": "TEST",
      //       "loc": 110557
      //     },
      //     {
      //       "name": "colors",
      //       "children": [
      //         {
      //           "name": "rgb",
      //           "loc": 110557
      //         },
      //         {
      //           "name": "hsl",
      //           "loc": 40415
      //         }
      //       ]
      //     },
      //     {
      //       "name": "generators",
      //       "children": [
      //         {
      //           "name": "address",
      //           "loc": 143432
      //         },
      //         {
      //           "name": "city",
      //           "loc": 98565
      //         },
      //         {
      //           "name": "animal",
      //           "loc": 86308
      //         },
      //         {
      //           "name": "movie",
      //           "loc": 108771
      //         },
      //         {
      //           "name": "user",
      //           "loc": 23835
      //         }
      //       ]
      //     },
      //     {
      //       "name": "set",
      //       "children": [
      //         {
      //           "name": "clone",
      //           "loc": 30925
      //         },
      //         {
      //           "name": "intersect",
      //           "loc": 176885
      //         },
      //         {
      //           "name": "merge",
      //           "loc": 45909
      //         },
      //         {
      //           "name": "reverse",
      //           "loc": 180635
      //         },
      //         {
      //           "name": "toArray",
      //           "loc": 10437
      //         },
      //         {
      //           "name": "toObject",
      //           "loc": 32977
      //         },
      //         {
      //           "name": "fromCSV",
      //           "loc": 20188
      //         },
      //         {
      //           "name": "slice",
      //           "loc": 71750
      //         },
      //       ]
      //     },
      //   ]
      // });

      setLoading(false);
    }
    fetchData();
  }, [selectedId, year]);

  return (
    <Grid2 xs={12}>
      <Box>
        <Typography variant="h1">{t('district.developerDistribution.title')}</Typography>
        <Typography variant="subtitle1" mb={1}>{t('district.developerDistribution.subTitle')}<CustomizedTooltips title={t('district.developerDistribution.tooltip')} /></Typography>
        {loading ? (
          <Skeleton style={{ height: 550, marginTop: 20 }} />
        ) : graphData.children.length ? (
          <Box style={{ height: 550 }}>
            <ResponsiveTreeMap
              data={graphData}
              identity="name"
              value="loc"
              valueFormat={function (e) {
                return e
              }}
              colors={{ scheme: 'nivo' }}
              orientLabel={false}
              margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
              labelSkipSize={12}
              labelTextColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    1.2
                  ]
                ]
              }}
              // enableParentLabel={false}
              // tooltip={({node}) => (
              //     <strong>
              //         {node.pathComponents[node.pathComponents.length - 2]} {JSON.stringify(node.pathComponents, null, 2)}
              //     </strong>
              // )}
              parentLabel={function (e) {
                const isgroup = e.data.groupId;
                let name = e.data.name;
                if (isgroup) {
                  if (name.length > 20) {
                    name = name.substring(0, 20) + '...';
                  }
                } else {
                  if (name.length > 10) {
                    name = name.substring(0, 10) + '...';
                  }
                }
                if (e.pathComponents.length === 1) {
                  return selectedName ?? 'District'
                }
                return name
              }}

              // parentLabelPosition="left"
              defs={[
                {
                  "id": "dots",
                  "type": "patternDots",
                  "size": 2,
                  "padding": 2,
                  "stagger": true,
                  "background": "rgba(148,148,148,0.2)",
                  "color": "#999797"
                }
              ]}
              fill={[{
                match: node => node.data.isAlone === true,
                id: 'dots'
              }]}
              parentLabelTextColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    3
                  ]
                ]
              }}
              borderColor={'#aaa69d'}
            />
          </Box>) : (
            <NoData width={300} marginTop={10} />
          )}
      </Box>
    </Grid2>
  );
}

export default DevelopersDistributionSection;