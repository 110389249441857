import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";
import API from '../controllers/api.js';

import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";

const Feedback = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  registerCoreBlocks();

  const saveFeedback = async (data) => {
    try {
      const response = await API.post(`/feedback`, data)
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(() => {
  }, []);

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: '100px' }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 container xs={12} md={12} p={0}>
            <div style={{ width: "100%", height: "90vh" }}>
              <Form
                formId="1"
                formObj={{
                  theme: {
                    font: "'Noto Sans', sans-serif",
                    buttonsBgColor: '#000',
                    buttonsFontColor: '#fff',
                  },
                  messages: {
                    'label.hintText.key': 'Click',
                    'block.defaultThankYouScreen.label': 'Thank you for submitting your feedback!',
                },
                  blocks: [
                    {
                      name: "welcome-screen",
                      id: "v1-1",
                      attributes: {
                        label: "We need your feedback 🙏",
                        description: "We promise, it won't take more than 2 minutes",
                        attachment: {
                          type: "image",
                          url:
                            "/feedback-header.png"
                        }
                      }
                    },
                    {
                      name: "short-text",
                      id: "v1-2",
                      attributes: {
                        classnames: "first-block",
                        required: true,
                        label: "Let's start with your name",
                        defaultValue: dbUser?.name,
                      }
                    },
                    {
                      name: "multiple-choice",
                      id: "v1-3",
                      attributes: {
                        required: true,
                        multiple: false,
                        verticalAlign: false,
                        label: "Who are you?",
                        choices: [
                          {
                            label: "Real Estate Agent",
                            value: "agent"
                          },
                          {
                            label: "Portfolio Investor",
                            value: "portfolio-investor"
                          },
                          {
                            label: "Private Investor",
                            value: "private-investor"
                          },
                          {
                            label: "Other",
                            value: "other"
                          }
                        ]
                      }
                    },
                    {
                      name: "long-text",
                      id: "v1-4",
                      attributes: {
                        description: "Nothing is also fine, however something you should have liked about MAPPI",
                        label: "What did you like about MAPPI? ",
                      }
                    },
                    {
                      name: "long-text",
                      id: "v1-5",
                      attributes: {
                        description: "Please, tell us what you didn't like the most about MAPPI (we promise we won't take it personal)",    
                        label: "What frastrated you the most?",
                      }
                    },
                    {
                      name: "long-text",
                      id: "v1-6",
                      attributes: {
                        description: "Have some feature that you would love to see, or something that you think we should improve? We are all ears!",    
                        label: "What you missed in MAPPI?",
                      }
                    },
                    {
                      name: "multiple-choice",
                      id: "v1-7",
                      attributes: {
                        required: true,
                        multiple: true,
                        verticalAlign: true,
                        label: "I would go Pro if MAPPI...",
                        description: "There is definatly something that we can do to make you go Pro",
                        choices: [
                          {
                            label: "Was less expensive",
                            value: "expensive"
                          },
                          {
                            label: "Had more features",
                            value: "features"
                          },
                          {
                            label: "I will never pay for this",
                            value: "never"
                          }
                        ]
                      }
                    },
                    {
                      name: "multiple-choice",
                      id: "v1-8",
                      attributes: {
                        required: true,
                        multiple: true,
                        verticalAlign: false,
                        label: "Can we get in touch with you?",
                        description: "Just in case we have more questions, we would love to get in touch with you",
                        choices: [
                          {
                            label: "Yes, via email",
                            value: "yes-email"
                          },
                          {
                            label: "Sure, just call",
                            value: "yes-call"
                          },
                          {
                            label: "Sorry, no!",
                            value: "no"
                          }
                        ]
                      }
                    },
                    {
                      name: "email",
                      id: "v1-9",
                      attributes: {
                        required: true,
                        label: "What is your email?",
                        defaultValue: dbUser?.email,
                      }
                    }
                  ],
                }}
                onSubmit={(data, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
                  saveFeedback(data)
                  setIsSubmitting(false);
                  completeForm();
                }}
              />
              </div>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default Feedback;