import React, { useEffect, useState, useContext } from "react";
import Link from '@mui/material/Link';
import AuthContext from '../../../contexts/AuthContext.js';
import SubscriptionContext from '../../../contexts/SubscriptionContext.js';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { formatAmount, formatNumber } from '../../../utils.js';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import Register from '../../common/register.js';
import CustomizedTooltips from '../../common/tooltip';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import BathroomIcon from '@mui/icons-material/Bathroom';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Chip from '@mui/material/Chip';

import NoData from "../../common/nodata.js";
import apiInstance from '../../../controllers/secureApi.js';
import { Divider } from "@mui/material";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';

import Lottie from "lottie-react";
import loadingData from "../../../lottie/loading-data.json";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
    borderRight: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgb(255 255 255)'
      : 'rgb(255 255 255)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderLeft: 0,
  borderRight: 0,
}));


const MarketDataSection = (props) => {
  const { t } = useTranslation();
  const { selectedId } = props;
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  // Price distribution
  const [loading, setLoading] = useState(null);

  const [hasHistory, setHasHistory] = useState(false);
  const [exists, setExists] = useState(false);
  const [marketProperties, setMarketProperties] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [summary, setSummary] = useState(null);

  const [fetching, setFetching] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function fetchData() {
    if (!user) {
      return
    }
    if (!selectedId) {
      setFetching(false);
      return
    }
    setFetching(true);
    setExists(true);
    setHasHistory(true);
    await setMarketProperties([]);
    // Price Distribution
    const { data } = await apiInstance.get(`/job/getmarket/${selectedId}`)
    console.log(data.groups)
    await setMarketProperties(data.groups);
    await setSummary({
      total: data.totalOffers,
      min: data.minPrice,
      max: data.maxPrice,
    });
    setFetching(false);
  }

  useEffect(() => {

    async function fetchStatus() {
      if (!user) {
        return
      }
      if (!selectedId) {
        setLoading(false);
        return
      }
      setLoading(true);
      // Price Distribution
      const { data } = await apiInstance.get(`/job/getmarket/${selectedId}/status`)
      console.log(data)
      if (data.status === 'completed') {
        setHasHistory(true);
        await fetchData();
      }
      if (data.projectExists === true) {
        setExists(true);
      }
      setLoading(false);
    }
    fetchStatus();
  }, [selectedId])

  return (
    <Grid2 xs={12} md={12}>
      <Typography variant="h1">{t('project.marketData.title')}  <Chip color="error" size="small" label={`Beta`} /></Typography>
      <Typography variant="subtitle1" mb={1}>{t('project.marketData.subTitle')}
        <CustomizedTooltips title={t('project.marketData.tooltip')} />
      </Typography>
      <Divider light />
      <Box mt={2}>
        {!user ?
          <Register height={'300px'} forpro={false} preview={'pro-project-market-data'} />
          : loading ? (
            <>
              <Skeleton variant="rectangular" width={'100%'} height={120} paddingTop={2} />
            </>
          ) : fetching ? (
            <Box mt={10}>
              <Lottie animationData={loadingData} loop={true} style={{ height: '100px' }} />
              <Typography variant="h4" sx={{ fontWeight: 'bold', width: '100%', textAlign: 'center' }} pt={2}>
                {t('project.marketData.fetching')}
              </Typography>
            </Box>
          ) : !exists ? (
            <Alert severity="error">
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{t('project.marketData.sorry')}</Typography>
              <Box mb={2}>{t('project.marketData.cannotfetch')}</Box>
            </Alert>
          ) : !hasHistory ? (
            <Alert severity="info">
              <Typography variant="h4" sx={{ fontWeight: 'bold' }} mb={1}>{t('project.marketData.infoBox.title')}</Typography>
              <Box mb={2}>{t('project.marketData.infoBox.context')}</Box>
              <Button onClick={
                () => {
                  fetchData()
                }
              } variant="contained">{t('project.marketData.infoBox.cta')}</Button>
            </Alert>
          ) : marketProperties.length > 0 ? (
            <>
              <Grid2 xs={12} md={12} container spacing={2} pb={2}>
                <Grid2 xs={12} md={4}>
                  <Box>
                    <Typography variant="subtitle2">{t('project.marketData.summary.total')}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2">{formatNumber(summary.total)}</Typography>
                  </Box>
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <Box>
                    <Typography variant="subtitle2">{t('project.marketData.summary.min')}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2">{formatNumber(summary.min)} AED</Typography>
                  </Box>
                </Grid2>
                <Grid2 xs={12} md={4}>
                  <Box>
                    <Typography variant="subtitle2">{t('project.marketData.summary.max')}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h2">{formatNumber(summary.max)} AED</Typography>
                  </Box>
                </Grid2>
              </Grid2>
              {marketProperties.map((item, index) => (
                <Accordion expanded={expanded === item.groupId} onChange={handleChange(item.groupId)}>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Grid2 container sx={{ width: '100%' }}>
                      <Grid2 item xs={6} md={6}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                          {item.type}(s)
                        </Typography>
                        <Typography variant="subtitle1" mt={1} style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          fontWeight: 600,
                          fontSize: '0.9rem',
                        }}>
                          <BedroomParentIcon fontSize="small" sx={{ marginRight: 0.5, color: '#000' }} /> {item.bedrooms} <BathroomIcon fontSize="small" sx={{ marginLeft: 2, marginRight: 0.5 }} /> {item.bathrooms} <SquareFootIcon fontSize="small" sx={{ marginLeft: 2, marginRight: 0.5, color: '#000' }} /> {item.size} Ft²
                        </Typography>
                      </Grid2>
                      <Grid2 item xs={6} md={6} sx={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        display: 'flex',
                      }}>
                        <Typography >
                          {item.properties.length > 1 ? (
                            <>
                              <Chip color="info" size="small" label={`${item.offers} offers`} />
                              {' '}from <b>{item.minPrice > 0 ? formatAmount(item.minPrice) : 'Ask for price'}</b> to <b>{`${formatAmount(item.maxPrice)} AED`}</b>
                            </>
                          ) : (
                            <>
                              <Chip color="info" size="small" label={`${item.offers} offer`} />
                              {' '}at <b>{item.minPrice > 0 ? `${formatAmount(item.minPrice)} AED` : 'Ask for price'}</b>
                            </>
                          )}
                        </Typography>
                      </Grid2>
                    </Grid2>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.properties.map((property, index) => (
                      <Link href={property.url} target="_blank">
                        <Grid2 container sx={{
                          width: '100%',
                          padding: 2,
                          '&:hover': {
                            background: '#f8f8f8',
                          }
                        }}>
                          <Grid2 item xs={8} md={8}>
                            <Typography variant="h4">
                              {property.title}
                            </Typography>
                            <Typography variant="subtitle1" mt={1} style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              fontWeight: 400,
                              fontSize: '0.9rem',
                            }}>
                              Listed on Property Finder
                            </Typography>
                          </Grid2>
                          <Grid2 item xs={4} md={4} sx={{
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            display: 'flex'
                          }}>
                            <Typography ><b>{property.price > 0 ? `${formatNumber(property.price)} AED` : 'Ask for price'} </b></Typography>
                          </Grid2>
                        </Grid2>
                        {index < item.properties.length - 1 && <Divider />}
                      </Link>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          ) : (
            <NoData width={400} marginTop={0} />
          )}
      </Box>
    </Grid2>
  );
}

export default MarketDataSection;