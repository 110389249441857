import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import CustomizedTooltips from '../../common/tooltip';
import Divider from '@mui/material/Divider';
import { ResponsiveBar } from '@nivo/bar'
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';


const DeveloperSection = (props) => { 
  const { t } = useTranslation();
  const { loadingB, dldData, selectedId } = props;
  // Basic Data
  // const [basicData, setBasicData] = useState(null);
  // const [loadingB, setBLoading] = useState(true);

  // useEffect(() => {
  //   async function fetchBasicData() {
  //     if (!selectedId) {
  //       setBLoading(false);
  //       return
  //     }
  //     setBLoading(true);
  //     // Basic Data
  //     // const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?year=${resolveYear(year)}`, controller.signal)
  //     const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?yearstart=${year.start}&yearend=${year.end}`)
  //     await setBasicData(bData);
  //     console.log('bData', bData);
  //     setBLoading(false);
  //   }
  //   fetchBasicData();
  // }, [selectedId, year]);

  return (
    <>
    <Grid2 container spacing={2}>
                        <Grid2 xs={12} md={12} pt={3}>
                          <Typography variant="h1">{t('project.developerSection.title')}</Typography>
                          <Typography variant="subtitle1" mb={1}>{t('project.developerSection.subTitle')}
                            <CustomizedTooltips title={t('project.developerSection.tooltip')} />
                          </Typography>
                          <Divider light />
                        </Grid2>
                      </Grid2>

                      <Grid2 xs={12} md={12} container spacing={2}>
                        <Grid2 xs={12} md={12} pt={2}>
                          <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={2}
                            pb={2}
                          >
                            <Avatar
                              alt={dldData?.developer?.englishName || 'NA'}
                              variant="square"
                              src={dldData?.developer?.logo}
                              sx={{ width: 80, height: 80 }}
                            />
                            <Box>
                              <Typography variant="h2">{loadingB ? <Skeleton /> : `${dldData?.developer?.englishName || '-'}`}</Typography>
                              <Typography variant="h2">{loadingB ? <Skeleton /> : `${dldData?.developer?.arabicName || '-'}`}</Typography>
                              <Rating name="read-only" value={5} readOnly size="small" sx={{ pt: 1 }} />
                            </Box>
                          </Stack>
                          <Typography variant="body1"><b>{t('project.developerSection.id')}: </b>{loadingB ? <Skeleton /> : dldData?.developer?.number || '-'}</Typography>
                          <Typography variant="body1"><b>{t('project.developerSection.website')}: </b>{loadingB ? <Skeleton /> : dldData?.developer?.contact?.url || '-'}</Typography>
                          <Typography variant="body1"><b>{t('project.developerSection.email')}: </b>{loadingB ? <Skeleton /> : dldData?.developer?.contact?.email || '-'}</Typography>
                          <Typography variant="body1"><b>{t('project.developerSection.phone')}: </b>{loadingB ? <Skeleton /> : dldData?.developer?.contact?.phone || '-'}</Typography>
                          <Typography variant="body1"><b>{t('project.developerSection.mobile')}: </b>{loadingB ? <Skeleton /> : dldData?.developer?.contact?.mobile || '-'}</Typography>
                        </Grid2>
                        {dldData?.developer?.number !== dldData?.mainDeveloper?.number ? (
                          <Grid2 xs={12} md={12} pt={2}>
                            <Stack
                              direction="row"
                              divider={<Divider orientation="vertical" flexItem />}
                              spacing={2}
                              pb={2}
                            >
                              <Avatar
                                alt={dldData?.mainDeveloper?.englishName || 'NA'}
                                variant="square"
                                src={dldData?.mainDeveloper?.logo}
                                sx={{ width: 80, height: 80 }}
                              />
                              <Box>
                                <Typography variant="h3">{loadingB ? <Skeleton /> : `${dldData?.mainDeveloper?.englishName || '-'}`}</Typography>
                                <Typography variant="h3">{loadingB ? <Skeleton /> : `${dldData?.mainDeveloper?.arabicName || '-'}`}</Typography>
                                <Rating name="read-only" value={5} readOnly size="small" sx={{ pt: 1 }} />
                              </Box>
                            </Stack>
                            <Typography variant="body1"><b>{t('project.developerSection.id')}: </b>{loadingB ? <Skeleton /> : dldData?.mainDeveloper?.number || '-'}</Typography>
                            <Typography variant="body1"><b>{t('project.developerSection.website')}: </b>{loadingB ? <Skeleton /> : dldData?.mainDeveloper?.contact?.url || '-'}</Typography>
                            <Typography variant="body1"><b>{t('project.developerSection.email')}: </b>{loadingB ? <Skeleton /> : dldData?.mainDeveloper?.contact?.email || '-'}</Typography>
                            <Typography variant="body1"><b>{t('project.developerSection.phone')}: </b>{loadingB ? <Skeleton /> : dldData?.mainDeveloper?.contact?.phone || '-'}</Typography>
                            <Typography variant="body1"><b>{t('project.developerSection.mobile')}: </b>{loadingB ? <Skeleton /> : dldData?.mainDeveloper?.contact?.mobile || '-'}</Typography>
                          </Grid2>
                        ) : (null)}
                      </Grid2>
                      </>
  );
}

export default DeveloperSection;