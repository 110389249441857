
import { useForm, Controller } from 'react-hook-form';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from "../../contexts/AuthContext.js";
import SubscriptionContext from "../../contexts/SubscriptionContext.js";
import { Modal, Box, Grid, Typography, Divider, Button, TextField, FormGroup } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Register from '../common/register.js';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import {NumericFormat} from "react-number-format";
import InputAdornment from '@mui/material/InputAdornment';
import { formatNumber } from '../../utils';
import { Alert } from '@mui/material';
import API from '../../controllers/secureApi';
import Lottie from "lottie-react";
import success from "../../lottie/success-msg.json";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};



const MakeOffer = ({ open, handleClose, priceOffer, item, priceHidden }) => {
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const { register, handleSubmit, formState: { errors }, setValue, control, watch, reset } = useForm({
    defaultValues: {
      price: item?.price,
      comment: priceOffer ? 'Hello, I would like to offer the price mentioned below. Looking forward to you reply!' : 'Hello, I am interested in your offer. Can you please contact me back?',
    },
    price: 0
  });

  const [sending, setSending] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (dbUser) {
      setValue('email', dbUser.email)
      setValue('phone', dbUser.phone)
    }
  }, [dbUser]);

  useEffect(() => {
  }, [user])

  const recalcDiscount = (newValue) => {
    if (newValue > 0) {
      const percentDiscount = (( newValue / item.price * 100 ) - 100).toFixed(2);
      setDiscount(percentDiscount)
    }
  }


  const handleSend = async (data) => {
    setSending(true);
    console.log(data);
    await API.post(`/offers/${item.id}/message`, data);
    reset()
    setSending(false);
    setDone(true);
  }

  const { t } = useTranslation();


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2} pt={2}>
          {done ? (
            <Grid2 container xs={12} md={12} p={0}>
            <Grid2 container xs={12} md={12} p={0} sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
          <Lottie animationData={success} loop={true} style={{ width: '50%', marginTop: '30px' }} />
          <Typography background={'white'} variant="h1">Message sent</Typography>
              <Typography variant="subText" background={'white'} textAlign={'center'} mb={1} pt={2} pb={5} sx={{ fontSize: '1rem', maxWidth: '50%' }}>We informed the seller about your message. The Agent will be back to you soon, however, be aware there is no gurantie as he is the one to decide. Good luck closing the deal!</Typography>
              <Button variant="contained" onClick={() => handleClose()}>OK!</Button>
              </Grid2>
          </Grid2>
          ) : !user ? <Register height={'300px'} preview={'empty'} /> : !dbUser ? (null) : (
            <>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { m: 1 },
                  '& button': { m: 1 },
                }}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(handleSend)}
              >
                <Grid item xs={12}>
                  {/* form with fileds: price, email, phone, comment */}

                  {priceOffer && (
                    // <TextField label="Price" variant="outlined" placeholder="Price offer" {...register("price", { required: { value: true, message: 'This field is required' } })}
                    //   error={Boolean(errors.price)} helperText={errors.price ? errors.price.message : ''} margin="normal" fullWidth />
                    <Box sx={{ display: 'flex', direction:'row', alignItems: 'center'}}>
                    <Controller
                    render={({ field: { onChange, value }}) => (
                      <NumericFormat
                      label="Price offer"
                        customInput={TextField}
                        thousandSeparator={true}
                        onValueChange={(v) => {
                          onChange(Number(v.value));
                          recalcDiscount(v.value)
                        }}
                        variant="outlined"
                        defaultValue={priceHidden ? 0 : item?.price}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                        }}
                        value={value}
                        margin="normal"
                      />
                    )}
                    label="Price"
                    name="price"
                    control={control}
                  />
                  {!priceHidden && (
                  <Typography variant="h4" ml={2}>{discount}% from original {formatNumber(item?.price)} AED</Typography>
                  )}
                  </Box>
                  )}

                  <TextField label="Email" variant="outlined" placeholder="Email" {...register("email", { required: { value: true, message: 'This field is required' } })}
                    error={Boolean(errors.email)} helperText={errors.email ? errors.email.message : ''} margin="normal" fullWidth />

                  {/* <TextField label="Phone" type="tel" variant="outlined" placeholder="Phone" {...register("phone", { required: { value: true, message: 'This field is required' } })}
                    error={Boolean(errors.phone)} helperText={errors.phone ? errors.phone.message : ''} margin="normal" fullWidth /> */}

                  <Controller
                    name="phone"
                    control={control}
                    rules={{ validate: matchIsValidTel, required: { value: true, message: 'This field is required' } }}
                    render={({ field, fieldState }) => (
                      <MuiTelInput
                        {...field}
                        label="Phone"
                        // onlyCountries={["AE"]}
                        helperText={fieldState.invalid ? "Invalid phone number" : ""}
                        error={fieldState.invalid}
                        defaultCountry={"AE"}
                        focusOnSelectCountry={true}
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />

                  <TextField label="Comment" variant="outlined" placeholder="Comment" {...register("comment", { required: { value: true, message: 'This field is required' } })}
                    multiline rows={4} error={Boolean(errors.comment)} helperText={errors.comment ? errors.comment.message : ''} margin="normal" fullWidth />



                </Grid>
                <Alert severity="error">Please be aware that on MAPPI, the seller holds the decision-making power and determines whether to initiate contact with you. However, we trust that real estate professionals seize every opportunity, so anticipate the agent reaching out to you promptly 😉</Alert>
                <Grid item xs={12} md={6} xl={4}>
                
                  <Button
                    fullWidth
                    disabled={sending}
                    size="large"
                    color="primary"
                    type="submit"
                    variant="contained"
                    endIcon={<SendIcon />}
                  >
                     {priceOffer ? 'Send offer' : 'Send contact request'}
                  </Button>
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="subtitle1" mb={1}>Submitting inaccurate or inappropriate information may result in your account being deactivated. It's imperative to ensure all submitted details are correct and suitable to maintain account privileges.</Typography>
                </Grid>
              </Box>

            </>
          )}
        </Grid>
      </Box>
    </Modal>
  );
}

export default MakeOffer;