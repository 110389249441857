import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../../contexts/AuthContext.js";
import SubscriptionContext from "../../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Linkify from "linkify-react";

import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Badge from '@mui/material/Badge';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { NumericFormat } from "react-number-format";

import { BinMinusIn } from 'iconoir-react';
import { ChatBubbleLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import QuiteHere from '../common/quiteHere.js';

import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, green } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Pagination from '@mui/material/Pagination';

import { trimStringLenght, formatDate, formatNumber } from '../../utils';

import API from '../../controllers/secureApi';
import PleaseVerify from '../common/pleaseVerify.js';
import NoSpecials from '../common/noSpecials.js';
import GoPro from '../common/goPro.js';
import Register from '../common/register.js';


const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  const { hostname } = new URL(href);
  console.log(attributes);
  if (hostname) {
    return <b><Link href={href} target={'_blank'} {...props}>{hostname}</Link></b>;
  }
  return <b><Link href={href} target={'_blank'} {...props}>{'Link'}</Link></b>;
};


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'rgb(240, 68, 56)',
    color: 'rgb(240, 68, 56)',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Message = ({ message, dispatchReadMsg }) => {

  const [isRead, setIsRead] = useState(false);

  const markAsRead = async () => {
    await API.put(`/messages/${message.id}/read`);
    setIsRead(true);
    dispatchReadMsg();
  }

  useEffect(() => {
    setIsRead(message?.read);
    console.log('message rerender')
  }, [message?.read, message])

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        mb: 2,
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <Box spacing={1} direction={'row'} display={'flex'} alignItems={'flex-end'}>
        <Box spacing={1} direction={'row'} display={'flex'} sx={{ alignItems: 'center' }}>
          {!isRead &&
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              variant="dot"
              sx={{ mr: 2 }}
            >
            </StyledBadge>
          }
          <Box
            sx={{
              p: 2,
              backgroundColor: "#f7f7f7",
              border: 'none',
              borderRadius: '10px',
              alignItems: 'flex-end'
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              <Linkify options={{ render: renderLink }} style={{ whiteSpace: 'pre-line' }}>
                {message?.comment}
              </Linkify>
            </Typography>
            {message?.price && (
              <>
                <Typography variant="h5" pt={2}>Price offer</Typography>
                <Typography variant="body2">{formatNumber(message?.price)} AED</Typography>
              </>
            )}
            <Typography variant="h5" pt={2}>Contacts</Typography>
            <Typography variant="body2">{message?.user?.name} {message?.user?.officialBroker?.companyName && `(${message?.user?.officialBroker?.companyName})`}</Typography>
            <Typography variant="body2">Mobile: <Link to={`tel:${message?.phone}`} style={{ textDecoration: 'underline' }}>{message?.phone}</Link></Typography>
            <Typography variant="body2">Email: <Link to={`mailto:${message?.email}`} style={{ textDecoration: 'underline' }}>{message?.email}</Link></Typography>
          </Box>
        </Box>
        <Box component={'div'} sx={{ alignItems: 'flex-end' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', pt: 1, pl: 2, height: '50px' }}>
            <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ "& .MuiBadge-badge": { fontSize: 8, height: 15 } }} color="primary" badgeContent={'Pro'} invisible={message?.user?.tier !== 'pro'}>
              <Avatar alt={message?.user?.name || 'M'} src={message?.user?.avatar || ''} sx={{ width: 40, height: 40 }} />
            </Badge>
          </Box>
        </Box>

      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1, justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
        {!isRead && <Button color="primary" size={'small'} sx={{ fontWeight: 800, textDecoration: 'underline' }} onClick={() => markAsRead(message?.id)}>Mark as read</Button>}
        <Typography sx={{ paddingLeft: 1.5 }} variant="subtitle1" textAlign={'right'} fontStyle={'italic'}>
          {/* Posted {formatDistance(parseISO(message.createdAt), new Date(), { addSuffix: true })} */}
          {message?.createdAt && `Sent: ${formatDate(message.createdAt)}`}
        </Typography>
      </Box>
    </Box>
  );
};

function Row(props) {
  const { property: item } = props;
  const [searchParams] = useSearchParams();

  const [open, setOpen] = useState(false);

  const [price, setPrice] = useState(null);
  const [commision, setCommision] = useState(null);
  const [published, setPublished] = useState(true);
  const [needSave, setNeedSave] = useState(false);

  const [loadingMessages, setLoadingMessages] = useState(true);
  const [messages, setMessages] = useState([]);

  const [countMessages, setCountMessages] = useState(0);

  useEffect(() => {
    setPrice(parseInt(item?.price) || 0);
    setCommision(parseInt(item?.commision) || 0);
    setCountMessages(item?._count?.offersOffers || 0)
    setPublished(item?.status === 'PUBLISHED');
    console.log('rerender tem')
  }, [item, item.status])

  useEffect (() => {
    if(searchParams.get('offer') === item?.id.toString()) {
      setOpen(true);
    }
  }, [searchParams])

  const dispatchReadMsg = async () => {
    await setCountMessages(countMessages - 1)
  }

  const checkNeedSave = async (newPrice, newCommision) => {
    if (newPrice == item?.price && newCommision == item?.commision) {
      await setNeedSave(false);
    } else {
      await setNeedSave(true);
    }
    console.log('needsave', newPrice, item?.price, newCommision, item?.commision)
  }

  const changePrice = async (e) => {
    const newPrice = parseInt(e.value) || 0;

    await setPrice(newPrice);
    await checkNeedSave(newPrice, commision)
  }

  const changeCommision = async (e) => {
    const newCommision = parseInt(e.target.value) || 0;
    await setCommision(newCommision);
    await checkNeedSave(price, newCommision)
  }

  const changePublished = async (e) => {
    const status = e.target.checked ? 'PUBLISHED' : 'ARCHIVED';
    const {data} = await API.patch(`/myoffers/${item.id}`, {
      status,
    });
    item.status = data.status;
    setPublished(e.target.checked);
    console.log(data.status)
  }

  const savePriceCommision = async () => {
    const {data} = await API.patch(`/myoffers/${item.id}`, {
      price,
      commision,
    });
    await setPrice(parseInt(data.price))
    await setCommision(parseInt(data.commision))
    item.commision = parseInt(data.commision);
    item.price = parseInt(data.price);
    await setNeedSave(false);
  }

  const readAllMessages = async () => {
    await API.put(`/myoffers/${item.id}/readall`);
    await setCountMessages(0);
    await setMessages(messages.map((item) => {
      item.read = true;
      return item
    }));
    console.log(messages)
  }

  useEffect(() => {
    const fetchMessages = async () => {
      setLoadingMessages(true);
      if (open) {
        const { data } = await API.get(`/myoffers/${item.id}/messages`);
        console.log(data);
        setMessages(data);
      }
      setLoadingMessages(false);
    }
    fetchMessages();
  }, [open])

  return (
    <>
      <TableRow
        key={item.id.toString()}
        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
      >
        <TableCell width={50} sx={{ textAlign: 'center' }}>

          {countMessages > 0 && (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              variant="dot"
            >
              <ChatBubbleLeftIcon height={20} />
            </StyledBadge>
          )}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>


        </TableCell>
        <TableCell scope="row" >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Avatar sx={{ width: 120, height: 90 }} variant="rounded" src={item?.images[0]?.url || 'https://mappi.ams3.cdn.digitaloceanspaces.com/offers/026407ff-299a-4ea8-973a-dc59f7d066fd.webp'}>
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 2 }}>
              <Typography variant='h3' fontWeight={'800'} color={'black'} pb={0.5}>{item?.project?.name}</Typography>
              <Typography variant='h5' fontWeight={'400'}>{item?.type} ● {formatNumber(item?.bedrooms)} bed(s) ● {formatNumber(item?.size)} sqft</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Typography variant='h4' fontWeight={'800'} pb={1}>{`${formatNumber(item?.price)} AED`}</Typography>
          <Typography variant='subtitle1' fontWeight={'400'}>{`${formatNumber(item.commision)} % shared commision`}</Typography>
        </TableCell>
        <TableCell align="right" width={100}>
          <Chip label={item?.status} color={published ? 'success' : 'error'} sx={{ fontWeight: 800, textTransform: 'uppercase' }} />
          <Typography variant="subtitle1">Until: 12.02.2025</Typography>
        </TableCell>
      </TableRow>
      <TableRow sx={{ }} key={`details-${item.id}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h3' fontWeight={'800'} color={'black'} pt={1}>
                Edit listing
              </Typography>
              <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />
              <Grid2 container spacing={2}>
                <Grid2 item xs={12} md={5}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <TextField label="Current price" variant="outlined" value={item?.price} disabled InputProps={{
                      endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                    }} margin="normal" />
                    <ArrowRightIcon height={20} style={{ margin: 5 }} />
                    {/* <TextField label="New price" variant="outlined" value={price} InputProps={{
                      endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                    }} margin="normal" onChange={(e) => changePrice(e)} /> */}

                    <NumericFormat
                      label="New price"
                      customInput={TextField}
                      thousandSeparator={true}
                      onValueChange={(v) => {
                        changePrice(v)
                      }}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">AED</InputAdornment>,
                      }}
                      value={price}
                      name="price"
                      margin="normal"
                    />
                  </Box>
                </Grid2>
                <Grid2 item xs={12} md={5}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    {item.shareCommision && (
                      <>
                        <TextField label="Current shared commision" variant="outlined" value={item?.commision} disabled InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }} margin="normal" />
                        <ArrowRightIcon height={20} style={{ margin: 5 }} />
                        <TextField label="New shared commision" variant="outlined" value={commision} InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }} margin="normal" onChange={(e) => changeCommision(e)} />
                      </>
                    )}
                  </Box>
                </Grid2>
                <Grid2 item xs={12} md={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', height: '100%' }}>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked={published} onChange={(e) => changePublished(e)}/>} label="Published" />
                    </FormGroup>
                  </Box>
                </Grid2>
                {needSave && (
                  <Grid2 item xs={12} md={2}>
                    <Button variant="contained" sx={{ mt: 0, width: '100%' }} onClick={() => savePriceCommision()}>Save changes</Button>
                  </Grid2>
                )}
                <Grid2 container xs={12} md={12}>
                  <Grid2 item xs={12} md={8}>
                    <Typography variant='h3' fontWeight={'800'} color={'black'}>
                      Offers and Messages
                    </Typography>
                  </Grid2>
                  <Grid2 item xs={12} md={4} textAlign={'right'}>
                    {countMessages > 0 && <Button color="primary" size={'small'} sx={{ fontWeight: 800, textDecoration: 'underline' }} onClick={() => readAllMessages()}>Mark all as read</Button>}
                  </Grid2>
                  <Grid2 item xs={12} md={12}>
                    <Divider width={'100%'} />
                  </Grid2>
                  <Box sx={{ flexGrow: 1, overflow: "auto", pb: 10, pt: 0, p: 2, minHeight: 230 }}>
                    {loadingMessages ? (
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 5 }}>
                        <Skeleton variant="circular" width={400} height={230} />
                      </Box>
                    ) : !messages.length ? (
                      <QuiteHere message={'No offers here yet'} />
                    ) : (messages.map((item) => (
                      <>
                        <Message message={item} dispatchReadMsg={dispatchReadMsg} key={`msg-${item.id}`}/>
                      </>
                    )))}
                  </Box>
                </Grid2>
              </Grid2>
              <Divider />

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );

}

const MySpecials = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [mySpecials, setMySpecials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchOffers = async () => {
      if (dbUser) {
        setLoading(true);
        const { data } = await API.get('/myoffers');
        console.log(data);
        setMySpecials(data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
    fetchOffers();
    console.log(dbUser)
  }, [dbUser]);

  return (
    <Grid2 item xs={12} md={12} p={2} pb={10}>
      {!dbUser ? (
        <Register  />
      ) : dbUser?.type !== 'BROKER' ? (
        <PleaseVerify />
      ) : loading ? (
        <Box sx={{ width: '100%' }}>
          <Skeleton height={55} count={1} width={'100%'} />
          <Skeleton height={125} count={3} width={'100%'} />
        </Box>
      ) : !mySpecials.length ? (
        <NoSpecials />
      ) : (
        <>

          <Typography variant='h2' fontWeight={'800'} color={'black'} pb={2}>My Special deals</Typography>
          <Divider />


          <TableContainer>
          <Grid2 container xs={12} md={12}>
            <Table sx={{ minWidth: 650, overflow: 'hidden' }} aria-label="simple table">
              <TableBody>
                {mySpecials.map((item) => (
                  <Row property={item} />
                ))}
              </TableBody>
            </Table>
            </Grid2>
          </TableContainer>
          

          {mySpecials.length > 100 && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
              <Pagination count={10} page={page} variant="outlined" shape="rounded"
              // onChange={handleChange} 
              />
            </Box>
          )}

        </>
      )}
    </Grid2>
  );
}

export default MySpecials;