import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import Autocomplete from '@mui/material/Autocomplete';
import { useForm } from 'react-hook-form';
import CustomizedTooltips from './common/tooltip.js';

import Menu from './Menu.js';
import NewMenu from './NewMenu.js';

import { styled } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Badge from '@mui/material/Badge';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';

import { formatDate, formatNumber, formatAmount } from '../utils.js';

import API from '../controllers/secureApi.js';
import NoMarketData from './common/noMarketData.js';
import RegisterExtended from './common/register-extended.js';
import ScrollToTop from './common/ScrollToTop.js';


const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  const { hostname } = new URL(href);
  console.log(attributes);
  if (hostname) {
    return <b><Link href={href} target={'_blank'} {...props}>{hostname}</Link></b>;
  }
  return <b><Link href={href} target={'_blank'} {...props}>{'Link'}</Link></b>;
};


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'rgb(240, 68, 56)',
    color: 'rgb(240, 68, 56)',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const statusToColor = (status) => {
  switch (status) {
    case 'COMPLETED':
      return 'success';
    case 'ERROR':
      return 'error';
    default:
      return 'info';
  }
}

function Row(props) {
  const { property: item } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let [property, setProperty] = useState(item);

  useEffect(() => {
    console.log('rerender tem')
    if (property.status !== 'COMPLETED') {
      // fetch data each 5 seconds
      const interval = setInterval(async () => {
        const { data } = await API.get(`/marketdata/${property.id}`);
        setProperty(data);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [property])

  useEffect(() => {
    if (searchParams.get('offer') === item?.id.toString()) {
      setOpen(true);
    }
  }, [searchParams])

  useEffect(() => {
    const fetchMessages = async () => {
    }
    fetchMessages();
  }, [open])

  return (
    <>
      <TableRow
        key={item.id.toString()}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell scope="row" >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 2 }}>
              <Typography variant='h3' fontWeight={'800'} color={'black'} pb={0.5}>{property?.project?.name}</Typography>
              <Typography variant="h5">Fetched: {formatDate(property?.createdAt)}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="right">
          <Typography variant='h4' fontWeight={'800'} pb={1}>{`${formatAmount(property?.salesData?.minPrice)} - ${formatAmount(property?.salesData?.maxPrice)} AED`}</Typography>
          <Typography variant='subtitle1' fontWeight={'400'}>
            {`${formatNumber(property?.salesData?.totalOffers)} Ads: `}
            {formatNumber(property?.salesData?.minBedrooms)} - {formatNumber(property?.salesData?.maxBedrooms)} bed(s) ● {formatNumber(property?.salesData?.minSize)} - {formatNumber(property?.salesData?.maxSize)} sqft
          </Typography>
        </TableCell>
        <TableCell align="right" width={160}>
          <Chip label={property?.status} color={statusToColor(property?.status)} sx={{ fontWeight: 800, textTransform: 'uppercase' }} />
        </TableCell>
        <TableCell width={50} sx={{ textAlign: 'center' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            disabled={property.status !== 'COMPLETED'}
            onClick={
              () => {
                navigate(`/market-data/${property.id}`)
              }
            }
          >
            <KeyboardArrowRightIcon />
          </IconButton>


        </TableCell>
      </TableRow>
    </>
  );

}

const MarketData = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [mdrequests, setMdRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [error, setError] = useState(null);


  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [projectIdError, setProjectIdError] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [projectId, setProjectId] = useState({});

  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch, reset } = useForm({
    defaultValues: {},
  });
  const onSubmit = async data => {
    if (!projectId.id || !projectId.mappiId) {
      setProjectIdError(true)
      return
    }
    const finalData = {
      projectId: projectId.mappiId,
      pfProjectId: projectId.id,
    }
    const { data: newData } = await API.post('/marketdata', finalData);
    if (newData.error) {
      setError(newData.error)
      return
    }
    setError(null)
    console.log([newData.createdJob, ...mdrequests])
    setMdRequests([newData.createdJob, ...mdrequests]);
    reset()
  }


  useEffect(() => {
    const fetchOffers = async () => {
      if (dbUser) {
        setLoading(true);
        const { data } = await API.get('/marketdata');
        console.log(data);
        setMdRequests(data);
        setLoading(false);
      }
    }
    fetchOffers();
    console.log(dbUser)
  }, [dbUser]);

  useEffect(() => {
    const fetchProjects = async () => {
      const { data } = await API.get(`/pfsearch?q=${inputValue}`)
      const hits = data.hits || []
      setOptions(hits);
    }
    fetchProjects()
  }, [inputValue, searchValue]);

  return (
    <>
      <NewMenu />
      <Menu />
      <ScrollToTop />
      <Box container sx={{ pt: 13, marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>

            <Grid2 container xs={12} md={12} pb={3}>
              <Grid2 container xs={12} md={12} >
                <Grid2 xs={12} md={12} pb={1}>
                  <Typography variant="h1" sx={{
                    fontSize: '2rem',
                    pb: 1,
                  }}>
                    {t('project.marketData.title')}
                  </Typography>
                  <Typography variant="subtitle1" mb={1}>{t('project.marketData.subTitle')}
                    <CustomizedTooltips title={t('project.marketData.tooltip')} />
                  </Typography>
                </Grid2>
                <Divider sx={{ width: '100%' }} />
              </Grid2>
              <Divider />

              {dbUser === undefined ? (null) : !dbUser ? null : (
                <Grid2 container xs={12} md={12} component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}>

                  {error && <Grid2 item xs={12} md={12}><Alert severity="error" sx={{ mt: 3 }}>{error}</Alert></Grid2>}
                  <Grid2 item xs={12} md={4}>
                    <Autocomplete
                      id="asynchronous-demo"
                      sx={{
                        width: 300, '& MuiAutocomplete-popupIndicator': {
                          margin: '0px !important'
                        }
                      }}
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={(option) => option.name}
                      options={options}
                      noOptionsText="Start typing to search for a project"
                      filterOptions={(x) => x}
                      onChange={(event, newValue) => {
                        console.log('newValue')
                        console.log(newValue)
                        setOptions(newValue ? [newValue, ...options] : options);
                        setSearchValue(newValue);
                        setProjectId(newValue || {})
                        if (!newValue?.id) {
                          setProjectIdError(true)
                        } else {
                          setProjectIdError(false)
                        }
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="normal"
                          label={t('addspecial.project-name')}
                          sx={{
                            '& button': {
                              marginTop: '0px !important'
                            }
                          }}
                          {...register("projectName", { required: { value: true, message: t('addspecial.error.project-is-required') } })}
                          error={Boolean(errors.projectName)} helperText={errors.projectName ? errors.projectName.message : ''}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}

                      renderOption={(props, option) => {

                        return (
                          <div key={option.projectId}>
                            <ListItemButton {...props}>
                              <ListItemText
                                sx={{ color: 'black' }}
                                primary={option.name}
                                secondary={''}
                              />
                            </ListItemButton>
                            <Divider component="li" />
                          </div>
                        );
                      }}
                    />

                  </Grid2>
                  <Grid2 item xs={12} md={8} alignSelf={'center'}>
                    <Button type="submit" variant="contained" color="primary" mt={'6px'}>{t('project.marketData.infoBox.cta')}</Button>
                  </Grid2>
                  <Alert severity="warning">{t('project.marketData.infoBox.context')}</Alert>
                </Grid2>
              )}



            </Grid2>
            <Grid2 container xs={12} md={12} p={0}>
              <Grid2 item xs={12} md={12} p={2} pb={10}>
                {dbUser === undefined ? (null) : !dbUser ? <RegisterExtended forpro={false} preview={'empty'} height={'500px'} intro={t('project.marketData.explain')} cta={t('project.marketData.explainCta')} ctaLink={t('project.marketData.ctaLink')} />
                  : loading ? (
                    <Box container sx={{ width: '100%' }}>
                      <Skeleton height={55} count={1} width={'100%'} />
                      <Skeleton height={125} count={3} width={'100%'} />
                    </Box>
                  ) : !mdrequests.length ? (
                    <NoMarketData />
                  ) : (
                    <>
                      <TableContainer container>
                        <Grid2 container xs={12} md={12}>
                          <Table sx={{ minWidth: 650, overflow: 'hidden' }} aria-label="simple table">
                            <TableBody>
                              {mdrequests.map((item) => (
                                <Row property={item} key={item.id.toString()} />
                              ))}
                            </TableBody>
                          </Table>
                        </Grid2>
                      </TableContainer>


                      {mdrequests.length > 100 && (
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                          <Pagination count={10} page={page} variant="outlined" shape="rounded"
                          // onChange={handleChange} 
                          />
                        </Box>
                      )}

                    </>
                  )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Container>
      </Box >
    </>
  );
}

export default MarketData;