import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Grid, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import CustomizedTooltips from '../../common/tooltip';
import Divider from '@mui/material/Divider';
import Register from '../../common/register';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import { trimString, formatDate, renderCompletion } from '../../../utils';

const renderStatus = (value) => {
  return (
    <Chip label={`${value}`} color={(value === 'Finished' || value === 'Active') ? 'success' : 'error'} size="small" variant="outlined" style={{ fontWeight: 600 }} />
  )
}

const DeveloperPropertiesSection = (props) => {
  const { t } = useTranslation();
  const { developerId, user, selectedId, setProject } = props;
  // Rating
  const [rating, setRating] = useState(null);
  const [ratingLoading, setRatingLoading] = useState(true);
  // Developer projects
  const [loadingDP, setDPLoading] = useState(true);
  const [developerProjects, setDeveloperProjects] = useState(null);

  useEffect(() => {
    async function fetchRating() {
      if (!selectedId) {
        setRatingLoading(false);
        setRating({ rating: '-' })
        return
      }
      if (developerId) {
        setRatingLoading(true);
        const { data: ratingData } = await API.get(`/developer/${developerId}/rating`)
        setRating(ratingData)
        setRatingLoading(false);
      } else {
        setRatingLoading(false);
        setRating({ rating: '-' })
      }
    }
    async function fetchBasicData() {
      if (!selectedId) {
        setDeveloperProjects([])
        setDPLoading(false);
        return
      }
      setDPLoading(true);

      if (developerId) {
        const { data: dpData } = await API.get(`/developer/${developerId}/projects?exclude=${selectedId}`)
        setDeveloperProjects(dpData)
        console.log('dpData', dpData)
        setDPLoading(false);
      } else {
        setDeveloperProjects([])
        setDPLoading(false);
      }
    }
    fetchRating();
    fetchBasicData();
  }, [selectedId, developerId]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={12} pt={3}>
          <Typography variant="h1">{t('project.developerProjectsSection.title')}</Typography>
          <Typography variant="subtitle1" mb={1}>{t('project.developerProjectsSection.subTitle')}
            <CustomizedTooltips title={t('project.developerProjectsSection.tooltip')} />
          </Typography>
          <Divider light />
        </Grid2>
      </Grid2>

      <Grid2 xs={12} md={12} container spacing={2}>
        <Grid2 xs={12} md={12}>
          {!user ? <Register height={'250px'} preview={'pro-project-developer'} /> : loadingDP ? (
            <Skeleton style={{ height: 300, marginTop: 20 }} />
          ) : (
            <>
              <Grid2 xs={12} md={12}>
                <Box>
                  <Typography variant="subtitle2">Mappi rating</Typography>
                </Box>
                <Box>
                  <Typography variant="rating">{ratingLoading ? <Skeleton /> : `${rating.rating ? rating.rating : '-'}`}</Typography>
                </Box>
              </Grid2>

              <Grid2 xs={12} md={12}>
                <DataGrid
                  rows={developerProjects}
                  columns={[
                    {
                      field: 'nameColumn', flex: 1, minWidth: 200, headerName: t('district.upcomingProjectsSection.table.name'), valueGetter: (params) => params.row?.project, renderCell: ({ value }) => {
                        return (
                          <Link
                            component="button"
                            onClick={() => {
                              console.log('value', value)
                              console.log('value', value)
                              setProject({ id: value.id, name: value.name });
                            }}
                          >
                            {value?.name}
                          </Link>
                        )
                      }
                    },
                    { field: 'completionDate', minWidth: 100, headerName: t('district.upcomingProjectsSection.table.expectedAt'), valueGetter: (params) => params.row?.dldProjectData?.title?.completionDate, valueFormatter: ({ value }) => formatDate(value) },
                    { field: 'status', flex: 1, minWidth: 200, headerName: t('district.upcomingProjectsSection.table.developer'), valueGetter: (params) => params.row?.project?.status, valueFormatter: ({ value }) => trimString(value) },
                    { field: 'completion', minWidth: 50, headerName: t('district.upcomingProjectsSection.table.completion'), valueGetter: (params) => params.row?.project?.completion, renderCell: ({ value }) => renderCompletion(value) },
                  ]}
                  rowHeight={40}
                  slots={{ noRowsOverlay: () => <Typography variant="subtitle1" pt={5} mb={1}>{t('district.analyticsSection.distribution.tableNoData')}</Typography> }}
                  columnHeaderHeight={40}
                  pageSizeOptions={[10, 20, 50]}
                  initialState={{
                    ...developerProjects.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  sx={{
                    boxShadow: 0,
                    border: 0,
                    '& .MuiDataGrid-columnHeader:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitleContainer:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 600,
                    },
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minHeight: 100,
                      textAlign: 'center',
                    }
                  }}
                />
              </Grid2>
            </>
          )}
        </Grid2>
      </Grid2>
    </>
  );
}

export default DeveloperPropertiesSection;