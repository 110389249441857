import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";

import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Register from './common/register';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import Lottie from "lottie-react";
import success from "../lottie/success.json";

import API from '../controllers/secureApi';

const steps = ['Get ready', 'Broker number', 'Recieve OTP', 'Done!'];

const Verify = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser, refetchDBUser } = useContext(SubscriptionContext);

  const [activeStep, setActiveStep] = useState(0);
  const [brokerNumber, setBrokerNumber] = useState(null);
  const [brokerNumberError, setBrokerNumberError] = useState(null);
  const [phonePostfix, setPhonePostfix] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const [canResendCode, setCanResendCode] = useState(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      otp: ""
    }
  });

  const onSubmit = async (data) => {
    const {data: apidata} = await API.get(`/broker/verify/${brokerNumber}/${data.otp}`);
    if (apidata.error) {
      setOtpError(apidata.error);
      return;
    }
    handleNext();
  };

  const handleNext = (step) => {
    if (!step) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep(step);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSetBrokerNumber = async () => {
    console.log(brokerNumber);
    const isnum = /^\d+$/.test(brokerNumber);
    if (!isnum) {
      setBrokerNumberError('Broker number should be numbers only');
      return;
    }

    const {data} = await API.get(`/broker/verify/${brokerNumber}`);

    if (data.error) {
      setBrokerNumberError(data.error);
      return;
    }
    setPhonePostfix(data.phonePostfix);
    handleNext(2);
  }


  useEffect(() => {
  }, []);

  return (
    <>
      <NewMenu />
      <Menu />
      <Box sx={{ marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>

            <Grid2 container xs={12} md={12} p={0} pt={3}>
            {dbUser === undefined ? (null) : !dbUser ? <Register forpro={false} preview={'empty'} height={'500px'} /> : dbUser.type !== 'USER' ? (
            <Grid2 item xs={12} md={12} p={0}>
              <Typography variant="h1" pb={2}>{t('verify.herebymistake')}</Typography>
              <Typography variant="body2" sx={{fontSize: '1rem'}} pb={3}>{t('verify.alreadypassed')}</Typography>
              <Button variant="contained" sx={{ mt: 2 }} onClick={()=> navigate('/')}>
                {t('verify.continueexplore')}
              </Button>
            </Grid2>
            ) : (
              <Grid2 item xs={12} md={6} p={0} mdOffset={3}>
                <Stepper activeStep={activeStep} sx={{ paddingBottom: 5 }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {activeStep === 0 && (
                  <Grid2 item xs={12} md={12} p={0}>
                    <Typography variant="h1" pb={2}>What will you need?</Typography>
                    <Typography variant="h3" pb={1}>1. Your Broker number (BNR#) registered with DLD</Typography>
                    <Typography variant="body2" sx={{fontSize: '1rem'}} pb={3}>This is something you should know, but in case you forgot it, it can be found <Link href='https://dubailand.gov.ae/en/eservices/licensed-real-estate-brokers/licensed-real-estate-brokers-list/#/'>here.</Link></Typography>
                    <Typography variant="h3" pb={1}>2. Have your mobile phone ready</Typography>
                    <Typography variant="body2" sx={{fontSize: '1rem'}} pb={3}>One time code will be send to your official mobile number, recorded in DLD.</Typography>
                    <Typography variant="h3" pb={1}>3. Around 2 minutes of your time</Typography>

                    <Button variant="contained" sx={{ mt: 2 }} onClick={()=> handleNext(1)}>
                      Get started
                    </Button>
                  </Grid2>
                )}
                {activeStep === 1 && (
                  <Grid2 item xs={12} md={12} p={0}>
                    <Typography variant="h1" pb={2}>Enter your Broker Number</Typography>
                    <Typography variant="body2" sx={{fontSize: '1rem'}} pb={3}>The Broker number (BNR#) is assigned to you by DLD. It can be found <Link href='https://dubailand.gov.ae/en/eservices/licensed-real-estate-brokers/licensed-real-estate-brokers-list/#/'>here.</Link></Typography>
                    
                    <Box sx={{ width: '300px' }}>
                    <TextField label="Broker number" error={Boolean(brokerNumberError)} helperText={brokerNumberError ? brokerNumberError : ''} variant="outlined" placeholder="Broker number" margin="normal" fullWidth onChange={(e) => setBrokerNumber(e.target.value)}/>
                    <Button  sx={{ mt: 2, mr: 5 }} onClick={()=> handleBack()}>
                      Back
                    </Button>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={()=> handleSetBrokerNumber()}>
                      Next
                    </Button>
                    </Box>
                  </Grid2>
                )}

                {activeStep === 2 && (
                  <Grid2 item xs={12} md={12} p={0}>
                    <Typography variant="h1" pb={2}>Enter the code you recieved in SMS</Typography>
                    <Typography variant="body2" sx={{fontSize: '1rem'}} pb={3}>
                      {`We have sent a one time code to your mobile number ***${phonePostfix}.`}
                    </Typography>
                    <Box sx={{ width: '200px' }}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                          name="otp"
                          control={control}
                          rules={{ validate: (value) => value.length === 4 }}
                          render={({ field, fieldState }) => (
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                              <MuiOtpInput sx={{ gap: 1 }} {...field} length={4} />
                              {fieldState.invalid ? (
                                <FormHelperText error>OTP invalid</FormHelperText>
                              ) : null}
                               {otpError ? (
                                <FormHelperText error>{otpError}</FormHelperText>
                              ) : null}
                              <Button variant='text' disabled={!canResendCode} sx={{ mt: 2}} onClick={()=> handleBack()}>
                      Resend the code
                    </Button>
                            </Box>
                          )}
                        />
                        <div>
                          <Button  sx={{ mt: 2, mr: 5 }} onClick={()=> handleBack()}>
                            Back
                          </Button>
                          <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                            Validate
                          </Button>
                        </div>
                      </form>
                    </Box>
                  </Grid2>
                )}

                {activeStep === 3 && (
                  <Grid2 container xs={12} md={12} p={0}>
                    <Grid2 container xs={12} md={12} p={0} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
                  <Lottie animationData={success} loop={true} style={{ width: '50%', marginTop: '30px' }} />
                  <Typography background={'white'} variant="h1">Verified!</Typography>
                      <Typography variant="subText" background={'white'} textAlign={'center'} mb={1} pt={2} pb={5} sx={{ fontSize: '1rem', maxWidth: '50%' }}>Now, you can enjoy all the benifits been verified Broker on MAPPI.</Typography>
                      <Button variant="contained" onClick={async () => {
                        navigate('/specials')
                        await refetchDBUser();
                      }}>Explore Special offers</Button>
                      </Grid2>
                  </Grid2>
                )}

              </Grid2>
            )}
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default Verify;