import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
import sortBy from 'lodash/sortBy';

import CustomizedTooltips from "../../common/tooltip.js";
import Divider from '@mui/material/Divider';
import { PatternLines } from '@nivo/core'
import API from '../../../controllers/api';
import GoPro from '../../common/goPro';
import Register from '../../common/register';

const ResaleSection = (props) => {
  const { t } = useTranslation();
  const { user, dbUser, selectedId, selectProject } = props;

  const [loadingTR, setTRLoading] = useState(false);
  const [topResale, setTopResale] = useState([]);
  const [topResaleGroups, setTopResaleGroups] = useState([]);

  useEffect(() => {
    async function fetchTopResaleData() {
      if (!selectedId) {
        setTRLoading(false);
        return
      }
      setTRLoading(true);
      // Top Resellaers
      const { data: trData } = await API.get(`/districts/${selectedId}/topresellers`)
      console.log('trData', trData);
      await setTopResale(trData);
      setTRLoading(false);
      // find unique values in array under object key rooms
      const uniqueRooms = [...new Set(trData.map(item => item.rooms).sort((a, b) => a - b))];
      // order uniqueRooms by name
      console.log('uniqueRooms', uniqueRooms);
      const uniqueRoomsSort = sortBy(uniqueRooms, [function (o) { return o; }])

      console.log('uniqueRoomsSort', uniqueRoomsSort);
      await setTopResaleGroups(uniqueRoomsSort);
    }
    fetchTopResaleData();
  }, [selectedId, user, dbUser])

  const BackgroundLayer = ({ xScale, yScale, innerHeight, innerWidth }) => (
    <>
      <defs>
        <PatternLines
          id="linesPattern"
          spacing={10}
          rotation={-45}
          background="rgba(255, 82, 82, 0.05)"
          color="rgba(255, 82, 82, 0.05)"
          lineWidth={4}
        />
      </defs>
      <rect
        x={0}
        y={0}
        width={innerWidth}
        height={yScale(100)}
        fill="url(#linesPattern)"
      />
    </>
  )

  return (
    <Grid2 container spacing={2}>
    <Grid2 xs={12}>
      <Box>
        <Grid2 xs={12} container>
          <Grid2 xs={7} lg={9}>
            <Typography variant="h1">{t('district.analyticsSection.resale.title')}</Typography>
            <Typography variant="subtitle1" mb={1}>
              {t('district.analyticsSection.resale.subTitle')}
              <CustomizedTooltips title={t('district.analyticsSection.resale.tooltip')} />
            </Typography>
          </Grid2>
        </Grid2>
        <Grid2 xs={12} md={12} pl={0}>
          <Divider flexItem />
        </Grid2>
        <Box style={{ height: 430 }}>
          {!user ? <Register height={'450px'} forpro={true} preview={'pro-district-topresale'} /> : dbUser?.tier !== 'pro' ? <GoPro height={'450px'} preview={'pro-district-topresale'} /> : loadingTR ? <Skeleton style={{ height: 410, marginTop: 20 }} /> : topResale.length > 0 ? (
            <ResponsiveSwarmPlot
              data={topResale}
              groups={topResaleGroups}
              groupBy="rooms"
              identity="id"
              value="rate"
              valueFormat=">-.0f"
              colors={{ scheme: 'nivo' }}
              onClick={(node, event) => {
                selectProject({ id: node.data.projectid, name: node.data.name });
              }}
              tooltip={node => {
                return <div style={{
                  background: 'white',
                  padding: '3px',
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: '2px',
                  filter: 'drop-shadow(2px 2px 5px #a3a3a3)'
                }}>
                  <b>{node.data.name}</b>
                  <br />
                  Resale Rate: {node.data.rate}%<br />
                  out of {node.data.exists} available apartments
                  <span style={{ display: 'block', marginTop: 3, fontSize: 11, color: '#a3a3a3' }}>Tip: Click the dot to see more details</span>
                </div>
              }}
              valueScale={{ type: 'linear', min: topResale[topResale.length - 1].rate, max: topResale[0].rate, reverse: false }}
              layers={['grid', 'axes', BackgroundLayer, 'circles', 'annotations']}
              size={{
                key: 'sold',
                values: [
                  0,
                  1000
                ],
                sizes: [
                  6,
                  20
                ]
              }}
              forceStrength={4}
              simulationIterations={100}
              borderColor={{
                from: 'color',
                modifiers: [
                  [
                    'darker',
                    0.6
                  ],
                  [
                    'opacity',
                    0.5
                  ]
                ]
              }}
              margin={{ top: 40, right: 50, bottom: 40, left: 50 }}
              axisTop={{
                orient: 'top',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -46
              }}
              axisRight={{
                orient: 'right',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 76
              }}
              axisBottom={{
                orient: 'bottom',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 46
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -76
              }}
            />
          ) : (null)}
        </Box>
      </Box>
    </Grid2>
  </Grid2>
  )
}

export default ResaleSection;