import React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { auth } from "./firebase";
import Menu from './components/Menu';
import GoogleButton from './components/common/googleBtn/GoogleButton';
import { trackEvent } from './components/common/plausible';

import {
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

export default function SignIn() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = React.useState(null);

  const { register, handleSubmit, watch, formState: { errors } } = useForm(
    {
      mode: "onSubmit",
      reValidateMode: "onSubmit",
    }
  );
  const redirect = searchParams.get('redirect');

  const onSubmit = data => {
    // name, email, password
    const username = data.firstName + ' ' + data.lastName
    registerWithEmailAndPassword(username, data.emailRequired, data.passwordRequired);
  }

  // const googleProvider = new GoogleAuthProvider();
  // const signInWithGoogle = async () => {
  //   try {
  //     setErrorMessage(null);
  //     await signInWithPopup(auth, googleProvider);
  //     const newUser = auth.currentUser.metadata.creationTime === auth.currentUser.metadata.lastSignInTime;
  //     console.log("existing", newUser)
  //     console.log("auth.currentUser.metadata.lastSignInTime", auth.currentUser.metadata.creationTime, auth.currentUser.metadata.lastSignInTime)
  //     if (!newUser) {
  //       navigate(redirect ? `/${redirect}` : '/');
  //     } else {
  //       navigate('/checkout');
  //     }
  //   } catch (err) {
  //     console.log(err)
  //     setErrorMessage('Could not sign in with Google');
  //   }
  // };
  // const logInWithEmailAndPassword = async (email, password) => {
  //   try {
  //     setErrorMessage(null);
  //     await signInWithEmailAndPassword(auth, email, password);
  //     navigate(redirect ? `/${redirect}` : '/');
  //   } catch (err) {
  //     setErrorMessage('Something is wrong with your email or password...worth checking.');
  //   }

  // };
  const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      if (user) {
        console.log(user)
        await updateProfile(user, { displayName: name });
      }
      const newUser = auth.currentUser.metadata.creationTime === auth.currentUser.metadata.lastSignInTime;
      console.log("existing", newUser)
      console.log("auth.currentUser.metadata.lastSignInTime", auth.currentUser.metadata.creationTime, auth.currentUser.metadata.lastSignInTime)
      if (!newUser) {
        trackEvent('Login', { method: 'email' })
        navigate(redirect ? `/${redirect}` : '/');
      } else {
        trackEvent('Register', { method: 'email' })
        navigate('/checkout');
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const googleProvider = new GoogleAuthProvider();
  const signInWithGoogle = async () => {
    try {
      setErrorMessage(null);
      await signInWithPopup(auth, googleProvider);
      const newUser = auth.currentUser.metadata.creationTime === auth.currentUser.metadata.lastSignInTime;
      console.log("existing", newUser)
      console.log("auth.currentUser.metadata.lastSignInTime", auth.currentUser.metadata.creationTime, auth.currentUser.metadata.lastSignInTime)
      if (!newUser) {
        console.log("redirect", redirect)
        navigate(redirect ? `${redirect}` : '/');
        trackEvent('Login', { method: 'google' })
      } else {
        navigate('/checkout');
        trackEvent('Register', { method: 'google' })
      }
    } catch (err) {
      console.log(err)
      setErrorMessage('Could not sign in with Google');
    }
  };
  // const sendPasswordReset = async (email) => {
  //   try {
  //     await sendPasswordResetEmail(auth, email);
  //     alert("Password reset link sent!");
  //   } catch (err) {
  //     setErrorMessage(err.message);
  //   }
  // };
  // const logout = () => {
  //   signOut(auth);
  //   navigate('/');
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   logInWithEmailAndPassword(data.get("email"), data.get("password"));
  // };

  return (
    <>
      <Menu />
      <Container component="main" maxWidth="xs" sx={{ mt: 20 }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h1">
            Sign up
          </Typography>
        </Box>

        <GoogleButton type={'light'} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16, marginTop: 24 }}
          onClick={signInWithGoogle}
        />

        <Divider>or use your email</Divider>

        {errorMessage ? (
          <Alert severity="error" variant="filled" >{errorMessage}</Alert>
        ) : null}

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 1 }}
          pt={1}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                helperText={errors.firstName && 'This field is required'}
                {...register("firstName", { required: true })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                helperText={errors.lastName && 'This field is required'}
                {...register("lastName", { required: true })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                helperText={errors.emailRequired && (errors.emailRequired.message || 'This field is required')}
                autoComplete="email"
                {...register("emailRequired", {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format"
                  }
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                helperText={errors.passwordRequired && 'This field is required'}
                {...register("passwordRequired", { required: true })}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? <b>Sign in</b>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
