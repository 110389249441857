
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';


const ManagementSection = (props) => { 
  const { t } = useTranslation();
  const { loadingB, mollakData, selectedId } = props;
  // Basic Data
  // const [basicData, setBasicData] = useState(null);
  // const [loadingB, setBLoading] = useState(true);

  // useEffect(() => {
  //   async function fetchBasicData() {
  //     if (!selectedId) {
  //       setBLoading(false);
  //       return
  //     }
  //     setBLoading(true);
  //     // Basic Data
  //     // const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?year=${resolveYear(year)}`, controller.signal)
  //     const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?yearstart=${year.start}&yearend=${year.end}`)
  //     await setBasicData(bData);
  //     console.log('bData', bData);
  //     setBLoading(false);
  //   }
  //   fetchBasicData();
  // }, [selectedId, year]);

  return (
    <>
  {mollakData && mollakData.items && mollakData.items.length > 0 ? (
                        <>
                          <Grid2 container spacing={2}>
                            <Grid2 xs={12} md={12} pt={3}>
                              <Typography variant="h1">{t('project.managmentCompanySection.title')}</Typography>
                              <Typography variant="subtitle1" mb={1}>{t('project.managmentCompanySection.subTitle')}</Typography>
                              <Divider light />
                            </Grid2>
                          </Grid2>
                          <Grid2 xs={12} md={12} container spacing={2}>
                            <Grid2 xs={12} md={12} pt={2}>
                              <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={2}
                                pb={2}
                              >
                                <Avatar
                                  variant="square"
                                  sx={{ width: 80, height: 80 }}
                                >
                                  {mollakData?.items[0]?.company.nameEn.charAt(0) || 'NA'}
                                </Avatar>
                                <Box>
                                  <Typography variant="h3">{loadingB ? <Skeleton /> : `${mollakData?.items[0]?.company.nameEn}`}</Typography>
                                  <Typography variant="h3">{loadingB ? <Skeleton /> : `${mollakData?.items[0]?.company.nameAr}`}</Typography>
                                </Box>
                              </Stack>
                              <Typography variant="body1"><b>{t('project.managmentCompanySection.licenseNumber')}: </b>{loadingB ? <Skeleton /> : mollakData?.items[0]?.licenseNumber}</Typography>
                              <Typography variant="body1"><b>{t('project.managmentCompanySection.companyId')}: </b>{loadingB ? <Skeleton /> : mollakData?.items[0]?.company.identity}</Typography>
                              <Typography variant="body1"><b>{t('project.managmentCompanySection.email')}: </b>{loadingB ? <Skeleton /> : mollakData?.items[0]?.email}</Typography>
                              <Typography variant="body1"><b>{t('project.managmentCompanySection.phone')}: </b>{loadingB ? <Skeleton /> : mollakData?.items[0]?.phone}</Typography>
                              <Typography variant="body1"><b>{t('project.managmentCompanySection.mobile')}: </b>{loadingB ? <Skeleton /> : mollakData?.items[0]?.mobile}</Typography>
                            </Grid2>
                          </Grid2>
                        </>
                      ) : (null)}
                      </>
  );
}

export default ManagementSection;