
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';
import Divider from '@mui/material/Divider';

const EscrowSection = (props) => { 
  const { t } = useTranslation();
  const { loadingB, dldProjectData, selectedId } = props;
  // Basic Data
  // const [basicData, setBasicData] = useState(null);
  // const [loadingB, setBLoading] = useState(true);

  // useEffect(() => {
  //   async function fetchBasicData() {
  //     if (!selectedId) {
  //       setBLoading(false);
  //       return
  //     }
  //     setBLoading(true);
  //     // Basic Data
  //     // const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?year=${resolveYear(year)}`, controller.signal)
  //     const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?yearstart=${year.start}&yearend=${year.end}`)
  //     await setBasicData(bData);
  //     console.log('bData', bData);
  //     setBLoading(false);
  //   }
  //   fetchBasicData();
  // }, [selectedId, year]);

  return (
    <>
    <Grid2 container spacing={2}>
                        <Grid2 xs={12} md={12} pt={3}>
                          <Typography variant="h1">{t('project.escrowAccountSection.title')}</Typography>
                          <Typography variant="subtitle1" mb={1}>{t('project.escrowAccountSection.subTitle')}</Typography>
                          <Divider light />
                        </Grid2>
                      </Grid2>
                      <Grid2 xs={12} md={12} container spacing={2}>
                        <Grid2 xs={12} md={6}>
                          <Box>
                            <Typography variant="subtitle2">{t('project.escrowAccountSection.bank')}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h3">{loadingB ? <Skeleton /> : `${dldProjectData?.title?.escrowAgent?.englishName || ' - '}`}</Typography>
                          </Box>
                        </Grid2>
                        <Grid2 xs={12} md={6}>
                          <Box>
                            <Typography variant="subtitle2">{t('project.escrowAccountSection.accountNumber')}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h3">{loadingB ? <Skeleton /> : `${dldProjectData?.title?.escrowAccount || ' - '}`}</Typography>
                          </Box>
                        </Grid2>
                      </Grid2>
                      </>
  );
}

export default EscrowSection;