import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Divider,
  Link,
  Badge,
  Avatar,
  IconButton,
} from "@mui/material";
import Grid2 from '@mui/material/Unstable_Grid2';
import SendIcon from "@mui/icons-material/Send";
import AuthContext from '../../contexts/AuthContext.js';
import SubscriptionContext from '../../contexts/SubscriptionContext.js';
import { styled } from '@mui/material/styles';
import API from '../../controllers/api.js';
import apiInstance from '../../controllers/secureApi.js';
import { format, parseISO, formatDistance } from 'date-fns';
import Linkify from "linkify-react";
import Modal from '@mui/material/Modal';
import Register from "../common/register.js";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));


const Chat = (props) => {
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [inputFocused, setInputFocused] = useState(false)
  const [sending, setSending] = useState(false)

  const [open, setOpen] = React.useState(false);

  const [input, setInput] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchData = async () => {
    const response = await API.get(`/news/project/${props.projectId}`)
    console.log(response.data)
    await setMessages(response.data)
    setDataLoading(false)
  }

  const handleSend = async () => {
    await setSending(true)
    if (input.trim() !== "") {
      console.log(input);
      await apiInstance.post(`/news/project/${props.projectId}`, {
        description: input,
      });
      setInput("");
    }
    await setSending(false)
    await handleClose()
    // timeout 1 sec to allow for db to update
    setTimeout(() => {
      fetchData()
    }, 1000)
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };


  useEffect(() => {
    setDataLoading(true)
    fetchData()
  }, [props.projectId]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={12} container spacing={1} sx={{ mb: 12, }}>
          <Box sx={{ flexGrow: 1, overflow: "auto", pb: 10, pt: 0, p: 2 }}>
            {messages.length === 0 && !dataLoading && (
              <StyledGridOverlay>
                <svg
                  width="120"
                  height="100"
                  viewBox="0 0 184 152"
                  aria-hidden
                  focusable="false"
                >
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                      <ellipse
                        className="ant-empty-img-5"
                        cx="67.797"
                        cy="106.89"
                        rx="67.797"
                        ry="12.668"
                      />
                      <path
                        className="ant-empty-img-1"
                        d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                      />
                      <path
                        className="ant-empty-img-2"
                        d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                      />
                      <path
                        className="ant-empty-img-3"
                        d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                      />
                    </g>
                    <path
                      className="ant-empty-img-3"
                      d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                      <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                  </g>
                </svg>
                <Box sx={{ mt: 1 }}>It is very quite here...</Box>
              </StyledGridOverlay>
            )}
            {dataLoading ? <Skeleton variant="rectangular" width={'100%'} height={250} /> : (
              messages.map((message) => (
                <Message key={message.id} message={message} />
              ))
            )}
          </Box>
        </Grid2>
        <Box sx={{ p: 2, backgroundColor: "background.default" }} style={{ position: 'absolute', bottom: 0, right: 0, left: 0, zIndex: 100 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                size="large"
                color="primary"
                variant="contained"
                onClick={handleOpen}
              >
                {t('project.news.cta')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid2>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid2 xs={12} md={12}>
            <Typography variant="h1">{t('project.news.title')}</Typography>
            <Typography variant="subtitle1" mb={1}>{t('project.news.subTitle')}</Typography>
            <Divider light />
          </Grid2>
          <Grid container spacing={2} pt={2}>
            {!user ? <Register height={'300px'} preview={'empty'} /> : (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onFocus={() => setInputFocused(true)}
                    multiline
                    rows={10}
                    placeholder="Type a message"
                    value={input}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                  <Button
                    fullWidth
                    disabled={sending}
                    size="large"
                    color="primary"
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={handleSend}
                  >
                    {t('project.news.button')}
                  </Button>
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="subtitle1" mb={1}>{t('project.news.note')}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  const { hostname } = new URL(href);
  console.log(attributes);
  if (hostname) {
    return <b><Link href={href} target={'_blank'} {...props}>{hostname}</Link></b>;
  }
  return <b><Link href={href} target={'_blank'} {...props}>{'Link'}</Link></b>;
};

const Message = ({ message }) => {

  const [countUp, setCountUp] = useState(0)
  const [countDown, setCountDown] = useState(0)

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        mb: 2,
      }}
    >
      <Grid2 item xs={12} md={12} container spacing={1} sx={{ mb: 12, }} direction={'row'}>
        <Grid2 item xs={12} md={10} lg={11} container spacing={1} sx={{ mb: 12, }} direction={'row'}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              backgroundColor: "#f7f7f7",
              border: 'none',
              borderRadius: '10px',
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              <Linkify options={{ render: renderLink }} sx={{ whiteSpace: 'pre-line' }}>
                {message.description}
              </Linkify>
            </Typography>
          </Paper>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 1, justifyContent: 'flex-end', width: '100%' }}>
            {/* <IconButton aria-label="close" size="meddium" component="label" sx={{ p: 0, fontSize: 16 }}
              onClick={() => {
                setCountUp(countUp + 1)
              }} p={0} mr={0}>
              <ThumbUpIcon sx={{color: 'rgb(33, 140, 116)', fontSize: 'inherit'}} />
            </IconButton>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1 }} variant="subtitle1" textAlign={'right'}>{`${countUp === 0 ? ' ' : countUp}`}</Typography>
            <IconButton aria-label="close" size="meddium" component="label" sx={{ p: 0, fontSize: 16 }}
              onClick={() => {
                setCountDown(countDown + 1)
              }} p={0} mr={0}>
              <ThumbDownIcon sx={{color: 'rgb(255, 82, 82)', fontSize: 'inherit'}} />
            </IconButton>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1 }} variant="subtitle1" textAlign={'right'}>{`${countDown === 0 ? ' ' : countDown}`}</Typography> */}
            <Typography sx={{ paddingLeft: 1.5 }} variant="subtitle1" textAlign={'right'} fontStyle={'italic'}>
              Posted {formatDistance(parseISO(message.createdAt), new Date(), { addSuffix: true })}</Typography>
          </Box>
        </Grid2>
        <Grid2 item xs={0} md={2} lg={1} container spacing={1} sx={{ mb: 12, alignItems: 'flex-end', pb: '35px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', pt: 1, pl: 2, height: '50px' }}>
            <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ "& .MuiBadge-badge": { fontSize: 8, height: 15 } }} color="primary" badgeContent={'Pro'} invisible={message.user?.tier !== 'pro'}>
              <Avatar alt={message.user?.name || 'M'} src={message.user?.avatar} sx={{ width: 40, height: 40 }} />
            </Badge>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Chat;