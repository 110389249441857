import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { resolveYear, resolveCalHeight } from '../../../utils';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton';
import Divider from '@mui/material/Divider';
import CustomizedTooltips from "../../common/tooltip";
import { ResponsiveCalendar } from '@nivo/calendar'
import API from '../../../controllers/api';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';


const TransactionCalendarSection = (props) => {
  const { t } = useTranslation();
  const { year, propType, regType, resolveFilters, roomsType } = props;
  // Calendar
  const [calendarData, setCalendarData] = useState([]);
  const [loadingC, setCLoading] = useState(true);

  useEffect(() => {

    async function fetchCalData() {
      setCLoading(true);
      // Calendar
      const { data: cData } = await API.get(`/dubai/calendaraggregate?yearstart=${year.start}&yearend=${year.end}&propFilter=${propType.filter}&regTypeFilter=${regType.filter}&roomsTypeFilter=${roomsType.filter}`)
      await setCalendarData(cData);
      console.log('cData', cData);
      setCLoading(false);
    }
    fetchCalData();
  }, [year, propType, regType, roomsType])

  return (
    <Grid2 xs={12} md={12} pt={3}>
      <Box>
        <Typography variant="h1">
          {t('dubai.transactionCalendarSection.title')} {resolveYear(year)}
          <Tooltip title={`${resolveFilters().filters} filters applied.`}>
            <Badge badgeContent={resolveFilters().value} color="info" style={{ transform: 'translate(11px, -10px)' }}></Badge>
          </Tooltip>
        </Typography>
        <Typography variant="subtitle1" mb={1}>{t('dubai.transactionCalendarSection.subTitle')}<CustomizedTooltips title={t('dubai.transactionCalendarSection.tooltip')} /></Typography>
        <Divider light />
        <Box style={{ height: resolveCalHeight(year) }}>
          {loadingC ? <Skeleton style={{ height: 110, marginTop: 20 }} /> : (
            <ResponsiveCalendar
              data={calendarData}
              from={`${year.start}-01-01`}
              to={`${year.end}-12-31`}
              align={'start'}
              emptyColor="#eeeeee"
              yFormat=" >-,.0f"
              colors={['#20bf6b', '#f7b731', '#fed330', '#fc5c65']}
              margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
              yearSpacing={40}
              monthBorderColor="#ffffff"
              dayBorderWidth={2}
              dayBorderColor="#ffffff"
              styles={{ marginTop: 20 }}
            />
          )}
        </Box>
      </Box>
    </Grid2>
  );
}

export default TransactionCalendarSection;