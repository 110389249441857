import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

import Autocomplete from '@mui/material/Autocomplete';
import { useForm } from 'react-hook-form';
import CustomizedTooltips from './common/tooltip.js';

import Menu from './Menu.js';
import NewMenu from './NewMenu.js';

import { styled } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Badge from '@mui/material/Badge';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Alert from '@mui/material/Alert';
import Pagination from '@mui/material/Pagination';

import { calculatePercentChange, formatNumber, formatAmount, formatDateTime } from '../utils.js';

import API from '../controllers/secureApi.js';
import NoMarketData from './common/noMarketData.js';
import RegisterExtended from './common/register-extended.js';
import ScrollToTop from './common/ScrollToTop.js';


const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  const { hostname } = new URL(href);
  console.log(attributes);
  if (hostname) {
    return <b><Link href={href} target={'_blank'} {...props}>{hostname}</Link></b>;
  }
  return <b><Link href={href} target={'_blank'} {...props}>{'Link'}</Link></b>;
};


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'rgb(240, 68, 56)',
    color: 'rgb(240, 68, 56)',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const statusToColor = (status) => {
  switch (status) {
    case 'sale':
      return 'success';
    case 'rent':
      return 'error';
    default:
      return 'info';
  }
}

function Row(props) {
  const { property } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (searchParams.get('offer') === property?.id.toString()) {
      setOpen(true);
    }
  }, [searchParams])


  return (
    <>
      <Grid2
        container
        item
        key={property.id.toString()}
        sx={{ borderBottom: 1, borderColor: 'divider', padding: 2 }}
      >
        <Grid2 item xs={12} sm={12} md={1} sx={{alignContent: {xs: 'center'}, textAlign: { xs: 'left'}, paddingBottom: {xs: 2, md: 0}}}>
          <Chip label={property?.type} color={statusToColor(property?.type)} sx={{ fontWeight: 800, textTransform: 'uppercase' }} />
        </Grid2>
        <Grid2 item xs={12} sm={12} md={8} sx={{paddingBottom: {xs: 2, md: 0}}}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h3' fontWeight={'800'} color={'black'} pb={0.5}>
                {property?.project ? (
                  <Link style={{ textDecoration: 'underline' }} to={`/district/${property?.project?.districtId}/project/${property?.project?.id}`} target='_blank'>{property?.projectName}</Link>
                ) : (
                  property?.projectName
                )}
              </Typography>
              {/* <Alert icon={false} variant="outlined" severity="info">
                <Typography variant='subtitle1' fontSize={14} fontWeight={'400'} pb={0.5}>Our: {property?.projectProbability} {property?.projectName} {'-->'} {property?.project?.name}</Typography>
                <Typography variant='subtitle1' fontSize={14} fontWeight={'400'} pb={0.5}>PF: {property?.projectPFProbability} {property?.projectName} {'-->'} {property?.projectPF?.name}  {property?.projectPF?.projectId}</Typography>
              </Alert> */}
              <Typography variant='subtitle1' fontSize={14} fontWeight={'400'} pb={0.5} pt={1}>{property?.details} <CustomizedTooltips title={`Original: ${property?.rawMessage}`} /></Typography>
              <Typography variant="h6" fontStyle={'italic'}>Posted: {formatDateTime(property?.message?.postedAt)} | Ref.: {property?.id}</Typography>
            </Box>
          </Box>
          <Box mt={2} sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <Box>
              {property?.pricePerSqft && property?.projectLastPrice && property?.type === 'sale' ? (
                <Typography variant="h2" whiteSpace={'nowrap'} mr={4}>
                  <Typography variant="subtitle1" mb={1}>{'History'}
                    <CustomizedTooltips title={`Based on data: ${property?.project?.name} (${Math.round(property?.projectProbability * 100, 1)}% match)`} />
                  </Typography>
                  {calculatePercentChange(property?.pricePerSqft, property?.projectLastPrice) > 5 ? (
                    <NorthEastIcon color={'error'} fontSize="10" sx={{ marginRight: 0.5 }} />
                  ) : calculatePercentChange(property?.pricePerSqft, property?.projectLastPrice) > -5 ? (
                    <ArrowForwardRoundedIcon color={'warning'} fontSize="10" sx={{ marginRight: 0.5 }} />
                  ) : (
                    <SouthEastIcon color={'success'} fontSize="10" sx={{ marginRight: 0.5 }} />
                  )}
                  {calculatePercentChange(property?.pricePerSqft, property?.projectLastPrice)}%
                  <Typography variant="subtitle2">
                    {formatNumber(Number(property?.projectLastPrice))} AED/Ft²
                  </Typography>
                </Typography>
              ) : null}
            </Box>

            <Box>
              {property?.pricePerSqft && property?.pfData?.middle && property?.type === 'sale' ? (
                <Typography variant="h2" whiteSpace={'nowrap'}>
                  <Typography variant="subtitle1" mb={1}>{'Market'}
                    <CustomizedTooltips title={`Based on data: ${property?.projectPF?.name} (${Math.round(property?.projectPFProbability * 100, 1)}% match)`} />
                  </Typography>
                  {calculatePercentChange(property?.pricePerSqft, property?.pfData?.middle) > 5 ? (
                    <NorthEastIcon color={'error'} fontSize="10" sx={{ marginRight: 0.5 }} />
                  ) : calculatePercentChange(property?.pricePerSqft, property?.pfData?.middle) > -5 ? (
                    <ArrowForwardRoundedIcon color={'warning'} fontSize="10" sx={{ marginRight: 0.5 }} />
                  ) : (
                    <SouthEastIcon color={'success'} fontSize="10" sx={{ marginRight: 0.5 }} />
                  )}
                  {calculatePercentChange(property?.pricePerSqft, property?.pfData?.middle)}%
                  <Typography variant="subtitle2">
                    {formatNumber(Number(property?.pfData?.middle))} AED/Ft²
                  </Typography>
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Grid2>
        <Grid2 item xs={12} sm={12} md={3} sx={{alignContent: {xs: 'center'}, textAlign: {md: 'right', xs: 'left'}}}>
          <Typography variant='h4' fontWeight={'800'} pb={1}>{`${formatAmount(property?.price)} ${property?.currency || 'AED'}`}</Typography>
          <Typography variant='subtitle1' fontWeight={'400'} whiteSpace={'nowrap'}>{formatAmount(property?.pricePerSqft)} AED/ft²</Typography>
          <Typography variant='subtitle1' fontWeight={'400'} whiteSpace={'nowrap'}>
            {`${formatNumber(property?.bedrooms)} bed(s) ● ${formatNumber(parseInt(property?.size))} ${property?.sizeMeasure || 'sqft'}`}
          </Typography>
        </Grid2>
      </Grid2>
    </>
  );

}

const OffMarket = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [mdrequests, setMdRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);


  useEffect(() => {
    const fetchOffers = async () => {
      if (dbUser) {
        if (!mdrequests.length) {
          setLoading(true);
        }
        const { data } = await API.get('lab/ai/offmarket?page=' + page);
        console.log(data);
        if (mdrequests.length > 0) {
          setMdRequests(mdrequests.concat(data));
        } else {
          setMdRequests(data);
        }
        setLoading(false);
      }
    }
    fetchOffers();
    console.log(dbUser)
  }, [dbUser, page]);

  return (
    <>
      <NewMenu />
      <Menu />
      <ScrollToTop />
      <Box container sx={{ pt: 13, marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>

            <Grid2 container xs={12} md={12} pb={3}>
              <Grid2 container xs={12} md={12} >
                <Grid2 xs={12} md={12} pb={1}>
                  <Typography variant="h1" sx={{
                    fontSize: '2rem',
                    pb: 1,
                  }}>
                    Off-{t('project.marketData.title')}
                  </Typography>
                  <Typography variant="subtitle1" mb={1}>{t('project.marketData.subTitle')}
                    <CustomizedTooltips title={t('project.marketData.tooltip')} />
                  </Typography>
                </Grid2>
                <Divider sx={{ width: '100%' }} />
              </Grid2>
              <Divider />

            </Grid2>
            <Grid2 container xs={12} md={12} p={0}>
              <Grid2 item xs={12} md={12} p={2} pb={10}>
                {dbUser === undefined ? (null) : !dbUser ? <RegisterExtended forpro={false} preview={'empty'} height={'500px'} intro={t('project.marketData.explain')} cta={t('project.marketData.explainCta')} ctaLink={t('project.marketData.ctaLink')} />
                  : loading ? (
                    <Box container sx={{ width: '100%' }}>
                      <Skeleton height={55} count={1} width={'100%'} />
                      <Skeleton height={125} count={3} width={'100%'} />
                    </Box>
                  ) : !mdrequests.length ? (
                    <NoMarketData />
                  ) : (
                    <>
                      <Grid2 container>
                        {mdrequests.map((item) => (
                          <Row property={item} key={item.id.toString()} />
                        ))}
                      </Grid2>

                      {/* Load more Button */}

                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                        <Button variant="contained" color="primary" size="large" sx={{ mt: 2, mb: 2 }} onClick={() => setPage(page + 1)}>
                          Load More
                        </Button>
                      </Box>

                    </>
                  )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Container>
      </Box >
    </>
  );
}

export default OffMarket;
