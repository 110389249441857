import { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import AuthContext from "../contexts/AuthContext.js";
import SubscriptionContext from "../contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './Menu.js';
import NewMenu from './NewMenu.js';
import Typography from "@mui/material/Typography";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import DummyItem from './specials/DummyItem.js';
import FullItem from './specials/FullItem.js';
import MySpecials from './specials/MySpecials.js';

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { NumericFormat } from "react-number-format";

import API from '../controllers/secureApi';
import PublicAPI from '../controllers/api.js';

import InputAdornment from '@mui/material/InputAdornment';
import TabsApple from './common/TabsApple';
import ScrollToTop from './common/ScrollToTop';
import { Badge } from '@mui/material';
import TextField from '@mui/material/TextField';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 300,
      minWidth: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  }
};

function getStyles(name, personName, theme) {
  console.log(personName.map((item) => item.id).includes(name.id))
  return {
    height: 40,
    fontWeight:
      personName.map((item) => item.id).includes(name.id)
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    fontSize: '15px',
  };
}

const Specials = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);
  const { tab } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [offers, setOffers] = useState([]);
  const [page, setPage] = useState(1);

  const [startPrice, setStartPrice] = useState('');
  const [endPrice, setEndPrice] = useState('');

  // Tabs
  const [value, setValue] = useState(tab === 'mydeals' ? 1 : 0);

  const theme = useTheme();
  const [filters, setFilters] = useState([]);
  const [personName, setPersonName] = useState([]);

  const handleChangeDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      value,
    );
  };


  const tabsMapping = [
    "/specials",
    "/specials/mydeals",
  ];

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue);
    navigate(tabsMapping[newValue], { replace: true });
  };

  const handleDelete = (chipToDelete) => () => {
    console.log(chipToDelete)
    setPersonName((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  };

  let reqDelay = useRef();

  const setStart = (price) => {
    clearTimeout(reqDelay.current);

    reqDelay.current = setTimeout(() => {
      // do your fetch or whatever you want here
      setStartPrice(price || '')
      console.log('Make API call')
    }, 1000)
  }

  const setEnd = (price) => {
    clearTimeout(reqDelay.current);

    reqDelay.current = setTimeout(() => {
      // do your fetch or whatever you want here
      setEndPrice(price || '')
      console.log('Make API call')
    }, 1000)
  }



  useEffect(() => {
    const fetchFilters = async () => {
      const { data } = await PublicAPI.get('/filters/offers');
      console.log(data)
      await setFilters(data);
    }
    fetchFilters()
  }, []);

  useEffect(() => {
    const fetchOffers = async () => {
      if (value === 0) {
        if (!offers.length) {
          setLoading(true);
        }
        let data;
        if (dbUser) {
          data = await API.get(`/offers?page=${page}&projectIds=${personName.map((item) => item.id).join(',')}&startPrice=${startPrice}&endPrice=${endPrice}`);
        } else {
          data = await PublicAPI.get(`/offers/public?page=${page}&projectIds=${personName.map((item) => item.id).join(',')}&startPrice=${startPrice}&endPrice=${endPrice}`);
        }
        if (offers.length > 0 && page > 1) {
          setOffers(offers.concat(data.data));
        } else {
          setOffers(data.data);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else if (value === 1) {
        setLoading(false)
      }
    }
    fetchOffers();
  }, [dbUser, value, page, personName, startPrice, endPrice]);

  return (
    <>
      <NewMenu />
      <Menu />
      <ScrollToTop />
      <Box container sx={{ pt: 13, marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>

            <Grid2 container xs={12} md={12} pb={3}>
              <Grid2 container xs={12} md={12} >
                <Grid2 xs={12} md={10} pb={1}>
                  <Typography variant="h1" sx={{
                    fontSize: '2rem',
                    pb: 1,
                  }}>
                    {t('specials.header')}
                    <Badge badgeContent={'Beta'} color="error" sx={{ ml: 3 }} />
                  </Typography>
                  <Typography variant="subtitle1">{t('specials.subheader')}</Typography>
                </Grid2>
                <Grid2 xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Button color="primary" variant='contained' disabled={loading} onClick={() => navigate('/specials/add')}>
                    {t('specials.add-offer')}
                  </Button>
                </Grid2>
                <Divider sx={{ width: '100%' }} />
              </Grid2>
              <Divider />
            </Grid2>
            {dbUser === undefined ? (null)
              // : !dbUser ? <Register forpro={false} preview={'empty'} height={'500px'} /> 
              : (
                <>

                  <Grid2 container xs={12} md={12} p={2} pb={3} pt={1}>
                    <TabsApple handleChange={handleChange} tab={value} />
                  </Grid2>

                  <CustomTabPanel container value={value} index={0} style={{ width: '100%' }}>
                    <Grid2 container xs={12} md={12} p={0}>
                      <Grid2 xs={12} md={6} p={2} pb={3} pt={1}>
                        <FormControl sx={{ width: '100%' }}>
                          <InputLabel id="demo-multiple-chip-label">Project(s)</InputLabel>
                          <Select
                            size="small"
                            sx={{ minHeight: 53 }}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            autoWidth
                            value={personName}
                            onChange={handleChangeDropdown}
                            input={<OutlinedInput id="select-multiple-chip" label="Project" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value.id} label={value.name} onDelete={handleDelete(value)} onMouseDown={(event) => {
                                    event.stopPropagation();
                                  }} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {filters.map((name) => (
                              <MenuItem
                                key={name.id}
                                value={name}
                                style={getStyles(name, personName, theme)}
                              >
                                {name.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid2>

                      <Grid2 xs={12} md={3} p={2} pb={3} pt={1}>
                        <NumericFormat
                          label={'Min price'}
                          customInput={TextField}
                          thousandSeparator={true}
                          onValueChange={(v) => {
                            setStart(v.value)

                          }}
                          // onValueChange={(v) => {
                          //   setValue('price',v.value)
                          // }}
                          // {...register("price", { required: { value: true, message: t('addspecial.is-required') }, min: { value: 80000, message: t('addspecial.the-price-should-be') } })}
                          variant="outlined"
                          placeholder={t('addspecial.price')}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{t('general.aed')}</InputAdornment>,
                          }}
                          name="price"
                          fullWidth
                        />



                      </Grid2>
                      <Grid2 xs={12} md={3} p={2} pb={3} pt={1}>
                        <NumericFormat
                          label={'Max price'}
                          customInput={TextField}
                          thousandSeparator={true}
                          onValueChange={(v) => {
                            setEnd(v.value)
                          }}
                          // onValueChange={(v) => {
                          //   setValue('price',v.value)
                          // }}
                          // {...register("price", { required: { value: true, message: t('addspecial.is-required') }, min: { value: 80000, message: t('addspecial.the-price-should-be') } })}
                          variant="outlined"
                          placeholder={t('addspecial.price')}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{t('general.aed')}</InputAdornment>,
                          }}
                          error={Boolean(startPrice >= endPrice && endPrice !== '')} helperText={startPrice >= endPrice && endPrice !== '' ? 'The price is higher than the start price' : ''}
                          // error={Boolean(errors.price)} helperText={errors.price ? errors.price.message : ''}
                          name="price"
                          fullWidth
                        />
                      </Grid2>

                    </Grid2>


                    <Grid2 container xs={12} md={12} p={0}>
                      {loading ? (
                        <>
                          <DummyItem navigate={navigate} isLoader />
                          <DummyItem navigate={navigate} isLoader />
                          <DummyItem navigate={navigate} isLoader />
                          <DummyItem navigate={navigate} isLoader />
                          <DummyItem navigate={navigate} isLoader />
                          <DummyItem navigate={navigate} isLoader />
                        </>
                      ) : offers?.map((item) => (
                        <>
                          {!item.hidden ? (
                            <FullItem item={item} navigate={navigate} />
                          ) : (
                            <DummyItem navigate={navigate} item={item} />
                          )}
                        </>
                      ))}


                    </Grid2>
                    <Grid2 container xs={12} md={12}>
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                        <Button variant="contained" color="primary" size="large" sx={{ mt: 2, mb: 2 }} onClick={() => setPage(page + 1)}>
                          Load More
                        </Button>
                      </Box>
                    </Grid2>
                  </CustomTabPanel>


                  <CustomTabPanel container value={value} index={1} style={{ width: '100%' }}>
                    <Grid2 container xs={12} md={12} p={0}>
                      <MySpecials />
                    </Grid2>
                  </CustomTabPanel>
                </>)}


          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default Specials;