import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { formatDate, formatNumber } from '../../../utils';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import API from '../../../controllers/api';


const BasicSection = (props) => {
  const { t } = useTranslation();
  const { loadingB, dldData, dldProjectData, selectedId } = props;
  // Basic Data
  // const [basicData, setBasicData] = useState(null);
  // const [loadingB, setBLoading] = useState(true);

  // useEffect(() => {
  //   async function fetchBasicData() {
  //     if (!selectedId) {
  //       setBLoading(false);
  //       return
  //     }
  //     setBLoading(true);
  //     // Basic Data
  //     // const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?year=${resolveYear(year)}`, controller.signal)
  //     const { data: bData } = await API.get(`/districts/${selectedId}/basicaggregate?yearstart=${year.start}&yearend=${year.end}`)
  //     await setBasicData(bData);
  //     console.log('bData', bData);
  //     setBLoading(false);
  //   }
  //   fetchBasicData();
  // }, [selectedId, year]);

  return (
    <Grid2 xs={12} md={12} container spacing={2}>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.status')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${dldData?.status || '-'}`}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.completion')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${dldData?.completionRatio || '-'} %`}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.number')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${dldData?.number || '-'}`}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.startDate')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatDate(dldProjectData?.startDate) || '-'}`}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.completionDate')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatDate(dldProjectData?.completionDate) || '-'}`}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.registrationDate')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatDate(dldProjectData?.registrationDate) || '-'}`}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.totalUnits')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatNumber(dldProjectData?.totalUnits)}`}</Typography>
        </Box>
      </Grid2>
      <Grid2 xs={12} md={4}>
        <Box>
          <Typography variant="subtitle2">{t('project.basicSection.totalArea')}</Typography>
        </Box>
        <Box>
          <Typography variant="h2">{loadingB ? <Skeleton /> : `${formatNumber(dldProjectData?.totalArea)}`}</Typography>
        </Box>
      </Grid2>
    </Grid2>
  );
}

export default BasicSection;