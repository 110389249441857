import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


const Register = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const height = props.height || 300;
  const marginTop = props.marginTop || 0;
  const forpro = props.forpro || false;
  const preview = props.preview || 'empty';

  const handleRegister = () => {
    navigate(`/register?redirect=${encodeURIComponent(location.pathname+location.search)}`);
  }

  const handleLogin = () => {
    navigate(`/login?redirect=${encodeURIComponent(location.pathname+location.search)}`);
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: height,
      mt: marginTop,
      background: `linear-gradient(rgba(255,255,255,.85), rgba(255,255,255,.85)), url(/preview/${preview}.png)`,
      backgroundSize: 'cover',
    }}
    >
      <Typography variant="h1" background={'white'}>{forpro ? t('general.cta.registerPro.title') : t('general.cta.register.title')}</Typography>
      <Typography variant="subText" background={'white'} textAlign={'center'} mb={1} pt={2} fontWeight={600}>{forpro ?  t('general.cta.registerPro.subTitle') : t('general.cta.register.subTitle')}</Typography>
      <Stack spacing={2} direction="row" pt={2}>
        <Button variant="text" onClick={handleLogin}>{t('general.login')}</Button>
        <Button variant="contained" onClick={handleRegister}>{t('general.register')}</Button>
      </Stack>
    </Box>
  );
}

export default Register;