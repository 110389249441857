import { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton'
import AuthContext from "./contexts/AuthContext.js";
import SubscriptionContext from "./contexts/SubscriptionContext.js";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Divider from '@mui/material/Divider';
import Menu from './components/Menu.js';
import NewMenu from './components/NewMenu.js';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Lottie from "lottie-react";
import API from './controllers/secureApi.js';
import success from "./lottie/success.json";
import fail from "./lottie/fail.json";
import { trackEvent } from './components/common/plausible';


const PaymentSuccess = (props) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [sessionStatus, setSessionStatus] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      const sessionId = searchParams.get('session_id');
      if (!sessionId) {
        console.log('no session id');
      }
      const { data } = await API.get(`/billing/session-status?session_id=${sessionId}`)
      console.log('data', data);
      setSessionStatus(data.status);
      trackEvent(`Payment ${data.status}}`)
    }
    fetchSession();
  }, []);

  return (
    <>
      <Box>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>

            <Grid2 container xs={12} md={12} p={0} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              {sessionStatus && (
                <>
                  {sessionStatus === 'complete' ? (
                    <>
                      <Lottie animationData={success} loop={true} style={{ width: '50%', marginTop: '30px' }} />
                      <Typography background={'white'} variant="h1">{t('payment.success.title')}</Typography>
                      <Typography variant="subText" background={'white'} textAlign={'center'} mb={1} pt={2} pb={5} sx={{ maxWidth: '50%' }}>{t('payment.success.subTitle')}</Typography>
                      <Button variant="contained" onClick={() => navigate('/')}>{t('payment.success.cta')}</Button>
                    </>
                  ) : (
                    <>
                      <Lottie animationData={fail} loop={true} style={{ width: '50%', marginTop: '30px' }} />
                      <Typography background={'white'} variant="h1">{t('payment.error.title')}</Typography>
                      <Typography variant="subText" background={'white'} textAlign={'center'} mb={1} pt={2} pb={5} sx={{ maxWidth: '50%' }}>{t('payment.error.subTitle')}</Typography>
                      <Button variant="contained" onClick={() => navigate('/checkout')}>{t('payment.error.cta')}</Button>
                    </>
                  )}
                </>
              )}

            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default PaymentSuccess;