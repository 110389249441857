import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Link from '@mui/material/Link';
import AuthContext from '../contexts/AuthContext.js';
import SubscriptionContext from '../contexts/SubscriptionContext.js';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { formatAmount, formatNumber, capitalizeFirstLetter, formatDate } from '../utils.js';
import Grid2 from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton'
import Register from './common/register';
import CustomizedTooltips from './common/tooltip';
import Container from "@mui/material/Container";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Menu from './Menu';
import NewMenu from './NewMenu';

import NoData from "./common/nodata.js";
import apiInstance from '../controllers/secureApi.js';
import { Button, Divider } from "@mui/material";

import LetsIconsKey from './icons/rentals.js';
import { Bathroom, Bed, Frame } from 'iconoir-react';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
};


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  borderRight: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgb(255 255 255)'
      : 'rgb(255 255 255)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderLeft: 0,
  borderRight: 0,
}));

const MarketDataEntry = (props) => {

  const { id: selectedId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { user: dbUser } = useContext(SubscriptionContext);

  // Price distribution
  const [loading, setLoading] = useState(true);

  const [marketProperties, setMarketProperties] = useState([]);
  const [marketPropertiesRent, setMarketPropertiesRent] = useState([]);
  const [propertyData, setPropertyData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [summary, setSummary] = useState(null);

  const [selectedProperties, setSelectedProperties] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const countRentals = (groupId) => {
    if (marketPropertiesRent.length === 0) return 0;
    const found = marketPropertiesRent.find((item) => item.groupId === groupId);
    if (found) {
      return found.offers;
    }
    return 0;
  }

  const addSelectedProperty = (groupId, index) => {
    const id = `${groupId}-${index}`;
    if (selectedProperties.includes(id)) {
      setSelectedProperties(selectedProperties.filter((item) => item !== id));
    } else {
      setSelectedProperties([...selectedProperties, id]);
    }
    console.log(selectedProperties)
  }


  const renderRentals = (groupId) => {
    // find the group in marketPropertiesRent where the groupId is the same as the one in marketProperties
    if (marketPropertiesRent.length === 0) return false;
    const found = marketPropertiesRent.find((item) => item.groupId === groupId);
    if (found) {
      return (
        <Grid2 xs={12} md={12} container spacing={2} pb={2}>
          <Divider width={'100%'} />
          <Grid2 xs={12} md={12}>
            <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#000' }}>Rent</Typography>
          </Grid2>
          <Grid2 xs={12} md={2}>
            <Box>
              <Typography variant="subtitle2">{t('project.marketData.summary.total')}</Typography>
            </Box>
            <Box>
              <Typography variant="h3">{formatNumber(found.offers)}</Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={2}>
            <Box>
              <Typography variant="subtitle2">{'Avg days listed'}</Typography>
            </Box>
            <Box>
              <Typography variant="h3">{formatNumber(found.avgDays, 0)}</Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={4}>
            <Box>
              <Typography variant="subtitle2">Avg Ft² price</Typography>
            </Box>
            <Box>
              <Typography variant="h3">{formatAmount(found.avgSqm)}  AED/Ft²/Year</Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={4}>
            <Box>
              <Typography variant="subtitle2">Avg price</Typography>
            </Box>
            <Box>
              <Typography variant="h3">{formatNumber(found.avgPrice, 0)} AED/Year</Typography>
            </Box>
          </Grid2>
        </Grid2>
      )
    }
    return false;
  }

  const buyToRent = (price, groupId) => {
    if (marketPropertiesRent.length === 0) return ' - ';
    const found = marketPropertiesRent.find((item) => item.groupId === groupId);
    if (found) {
      return `${formatAmount(found.avgPrice / price * 100)} %`
    }
    return ' - ';
  }


  async function fetchData() {
    if (!dbUser) {
      console.log('No user')
      return
    }
    if (!selectedId) {
      setLoading(false);
      return
    }
    setLoading(true);
    await setMarketProperties([]);
    // Price Distribution
    const { data } = await apiInstance.get(`/marketdata/${selectedId}`)
    console.log(data.groups)
    await setPropertyData({
      name: data.project?.name || data.pfProject?.name,
      id: data.project?.id,
      updatedAt: data.updatedAt,
    });
    await setMarketProperties(data.salesData?.groups || []);
    await setMarketPropertiesRent(data.rentData?.groups || []);
    await setSummary({
      total: data.salesData?.totalOffers,
      min: data.salesData?.minPrice,
      max: data.salesData?.maxPrice,
    });
    setLoading(false);
  }


  useEffect(() => {

    async function fetchStatus() {
      if (!dbUser) {
        return
      }
      if (!selectedId) {
        setLoading(false);
        return
      }
      setLoading(true);

      await fetchData();

      setLoading(false);
    }
    fetchStatus();
  }, [selectedId, dbUser])

  return (
    <>
      <NewMenu />
      <Menu />

      {selectedProperties.length > 0 && (
        <Box sx={{
          position: "fixed",
          bottom: "0",
          height: "60px",
          width: "100%",
          zIndex: "1000",
        }}>
          <Box sx={{
            marginLeft: { xs: 0, sm: '95px' }
          }}>
            <Container component="main" sx={{ padding: { xs: 0 } }}>
              <Grid2 container >
                <Grid2 container xs={12} md={12} sx={{ alignContent: 'center', alignItems: 'center' }}>
                  <Box sx={{
                    width: "100%",
                    height: "60px",
                    background: "#fff",
                    display: "flex",
                    direction: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px 0px #0000001a",
                  }}>
                    <Typography variant="h5">
                      {`${selectedProperties.length} item(s) selected`}
                    </Typography>
                    <Button size='small' variant="contained" sx={{ ml: 3 }} color="primary" onClick={handleOpen}>Validate the prices</Button>
                  </Box>
                </Grid2>
              </Grid2>
            </Container>
          </Box>
        </Box>
      )}
      <Box sx={{ marginLeft: { xs: 0, sm: '95px' } }}>
        <Container component="main" sx={{ mt: 13, padding: { xs: 1 } }}>
          <Grid2 container spacing={2}>
            <Grid2 container xs={12} md={12} pb={1} sx={{ alignContent: 'center', alignItems: 'center' }}>
              <Grid2 item xs={1} md={0.5} sx={{ align: 'left' }} >
                <div style={{ textAlign: 'left' }}>
                  <IconButton aria-label="close" size="meddium" component="label" onClick={() => { navigate('/market-data') }} pb={0}>
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </Grid2>
              <Grid2 xs={12} md={11.5} pb={1}>
                <Typography variant="h1" sx={{
                  fontSize: '2rem',
                  pb: 1,
                }}>
                  {propertyData ? propertyData?.name : <Skeleton width={300} />}
                </Typography>
                <Typography variant="subtitle1">Updated: {propertyData ? formatDate(propertyData?.updatedAt) : <Skeleton width={200} />}</Typography>
              </Grid2>
            </Grid2>
            <Divider sx={{ width: '100%' }} />

            <Grid2 container xs={12} md={12}>


              <Grid2 xs={12} md={12}>
                <Box mt={2}>
                  {dbUser === undefined ? (null) : !dbUser ? <Register forpro={false} preview={'empty'} height={'500px'} /> : loading ? (
                    <>
                      <Skeleton variant="rectangular" width={'100%'} height={70} paddingTop={0} />
                      <Skeleton variant="rectangular" width={'100%'} height={50} paddingTop={0} count={3} />
                    </>
                  ) : marketProperties.length > 0 ? (
                    <>
                      <Grid2 xs={12} md={12} container spacing={2} pb={4}>
                        <Grid2 xs={12} md={4}>
                          <Box>
                            <Typography variant="subtitle2">{t('project.marketData.summary.total')}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h2">{formatNumber(summary.total)}</Typography>
                          </Box>
                        </Grid2>
                        <Grid2 xs={12} md={4}>
                          <Box>
                            <Typography variant="subtitle2">{t('project.marketData.summary.min')}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h2">{formatNumber(summary.min)} <Typography variant="smallCurrency">AED</Typography></Typography>
                          </Box>
                        </Grid2>
                        <Grid2 xs={12} md={4}>
                          <Box>
                            <Typography variant="subtitle2">{t('project.marketData.summary.max')}</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h2">{formatNumber(summary.max)} <Typography variant="smallCurrency">AED</Typography></Typography>
                          </Box>
                        </Grid2>
                      </Grid2>
                      {marketProperties.map((item, index) => (
                        <Accordion >
                          <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Grid2 container sx={{ width: '100%', alignItems: 'center', }} pt={1} pb={1}>
                              <Grid2 item xs={6} md={6}>
                                <Typography variant="h4" sx={{
                                  fontWeight: 'bold', display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  fontWeight: 600,
                                  fontSize: '0.9rem',
                                }}>
                                  {item.properties.length > 1 ? item.type + 's' : item.type}
                                  <Bed strokeWidth={2} color='black' style={{ marginLeft: 10, marginRight: 3, height: 18 }} /> {item.bedrooms} <Frame strokeWidth={2} color='black' style={{ marginLeft: 10, marginRight: 3, height: 18 }} /> {formatNumber(item.size)} Ft²
                                  {countRentals(item.groupId) ?
                                    <>
                                      <LetsIconsKey style={{ marginLeft: 10, color: '#c0392b', fontSize: '25px' }} />
                                      <CustomizedTooltips title={t('project.marketData.key')} customMargin={'0'} sx={{ marginLeft: '0px !important', marginTop: '-2px !important' }} />
                                    </>


                                    : ''}
                                </Typography>
                              </Grid2>
                              <Grid2 item xs={6} md={6} sx={{
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                display: 'flex',
                              }}>
                                <Chip color="info" size="small" label={`${item.offers} offers`} />
                                <Typography ml={1}>
                                  {item.properties.length > 1 ? (
                                    <>
                                      {' '}from <b>{item.minPrice > 0 ? formatAmount(item.minPrice) : 'Ask for price'}</b> to <b>{`${formatAmount(item.maxPrice)} AED`}</b>
                                    </>
                                  ) : (
                                    <>
                                      {' '}at <b>{item.minPrice > 0 ? `${formatAmount(item.minPrice)} AED` : 'Ask for price'}</b>
                                    </>
                                  )}
                                </Typography>
                              </Grid2>
                            </Grid2>
                          </AccordionSummary>
                          <AccordionDetails sx={{ background: '#fbfbfb' }}>
                            <Grid2 container xs={12} md={12} pb={2}>
                              <Grid2 xs={12} md={10}>
                                <Grid2 xs={12} md={12} container spacing={2} pb={2}>
                                  <Grid2 xs={12} md={12}>
                                    <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#000' }}>Sales</Typography>
                                  </Grid2>
                                  <Grid2 xs={12} md={2}>
                                    <Box>
                                      <Typography variant="subtitle2">{t('project.marketData.summary.total')}</Typography>
                                    </Box>
                                    <Box>
                                      <Typography variant="h3">{formatNumber(item.offers)}</Typography>
                                    </Box>
                                  </Grid2>
                                  <Grid2 xs={12} md={2}>
                                    <Box>
                                      <Typography variant="subtitle2">{'Avg days listed'}</Typography>
                                    </Box>
                                    <Box>
                                      <Typography variant="h3">{formatNumber(item.avgDays, 0)}</Typography>
                                    </Box>
                                  </Grid2>
                                  <Grid2 xs={12} md={4}>
                                    <Box>
                                      <Typography variant="subtitle2">Avg price</Typography>
                                    </Box>
                                    <Box>
                                      <Typography variant="h3">{formatAmount(item.avgSqm)}  AED/Ft²</Typography>
                                    </Box>
                                  </Grid2>
                                  <Grid2 xs={12} md={4}>
                                    <Box>
                                      <Typography variant="subtitle2">Avg price</Typography>
                                    </Box>
                                    <Box>
                                      <Typography variant="h3">{formatNumber(item.avgPrice, 0)} AED</Typography>
                                    </Box>
                                  </Grid2>
                                </Grid2>
                                {renderRentals(item.groupId)}
                              </Grid2>
                              <Grid2 xs={12} md={2} spacing={2} pb={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box>
                                  <Typography variant="subtitle2">Rent vs Buy</Typography>
                                  <Typography variant="h2">{buyToRent(item.avgPrice, item.groupId)}</Typography>
                                </Box>
                              </Grid2>
                            </Grid2>
                            <Divider sx={{ width: '100%' }} />
                            {item.properties.map((property, index) => (
                              <Link href={property.url} target="_blank" sx={{ textDecoration: 'none' }}>
                                <Grid2 container sx={{
                                  width: '100%',
                                  padding: 2,
                                  '&:hover': {
                                    background: '#f8f8f8',
                                  }
                                }}>
                                  <Grid2 item xs={1} md={1}>
                                    <Checkbox onClick={() => {
                                      addSelectedProperty(item.groupId, index)
                                    }} checked={selectedProperties.includes(`${item.groupId}-${index}`)} />
                                  </Grid2>
                                  <Grid2 item xs={7} md={7}>

                                    <Typography variant="h5">
                                      {property.title}
                                    </Typography>
                                    <Box display={'flex'} flexDirection={'row'}>
                                      <Typography variant="subtitle1" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        fontWeight: 400,
                                      }}>Listed for {property.publishedDays} days ●</Typography>
                                      <Typography variant="subtitle1" ml={1} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        fontWeight: 400,
                                      }}>

                                        {property.contact?.map((item, index) => (
                                          <>
                                            {item.type !== 'whatsapp' ? (<Link href={item.link} target="_blank" mr={1}>{capitalizeFirstLetter(item.type)}</Link>) : ''}
                                          </>
                                        ))}
                                      </Typography>
                                    </Box>
                                  </Grid2>
                                  <Grid2 item xs={4} md={4} sx={{
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}>
                                    <Typography ><b>{property.price > 0 ? `${formatNumber(property.price)} AED` : 'Ask for price'} </b></Typography>
                                    <Typography variant="subtitle1">{property.sqm > 0 ? `${formatAmount(property.sqm)} AED/Ft²` : ''}</Typography>
                                  </Grid2>
                                </Grid2>
                                {index < item.properties.length - 1 && <Divider />}
                              </Link>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                    <NoData width={400} marginTop={0} />
                  )}
                </Box>
              </Grid2>




              {/* 
              <Grid2 xs={12} md={12}>
                <Typography variant="h1">{t('project.marketData.title')}  <Chip color="error" size="small" label={`Beta`} /></Typography>
                <Typography variant="subtitle1" mb={1}>{t('project.marketData.subTitle')}
                  <CustomizedTooltips title={t('project.marketData.tooltip')} />
                </Typography>
                <Divider light />
                <Box mt={2}>
                  {!user ?
                    <Register height={'300px'} forpro={false} preview={'pro-project-market-data'} />
                    : loading ? (
                      <>
                        <Skeleton variant="rectangular" width={'100%'} height={120} paddingTop={2} />
                      </>
                    ) : fetching ? (
                      <Box mt={10}>
                        <Lottie animationData={loadingData} loop={true} style={{ height: '100px' }} />
                        <Typography variant="h4" sx={{ fontWeight: 'bold', width: '100%', textAlign: 'center' }} pt={2}>
                          {t('project.marketData.fetching')}
                        </Typography>
                      </Box>
                    ) : marketPropertiesRent.length > 0 ? (
                      <>
                        <Grid2 xs={12} md={12} container spacing={2} pb={2}>
                          <Grid2 xs={12} md={4}>
                            <Box>
                              <Typography variant="subtitle2">{t('project.marketData.summary.total')}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="h2">{formatNumber(summary.total)}</Typography>
                            </Box>
                          </Grid2>
                          <Grid2 xs={12} md={4}>
                            <Box>
                              <Typography variant="subtitle2">{t('project.marketData.summary.min')}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="h2">{formatNumber(summary.min)} AED</Typography>
                            </Box>
                          </Grid2>
                          <Grid2 xs={12} md={4}>
                            <Box>
                              <Typography variant="subtitle2">{t('project.marketData.summary.max')}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="h2">{formatNumber(summary.max)} AED</Typography>
                            </Box>
                          </Grid2>
                        </Grid2>
                        {marketPropertiesRent.map((item, index) => (
                          <Accordion expanded={expanded === item.groupId} onChange={handleChange(item.groupId)}>
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Grid2 container sx={{ width: '100%' }}>
                                <Grid2 item xs={6} md={6}>
                                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                                    {item.type}(s)
                                  </Typography>
                                  <Typography variant="subtitle1" mt={1} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    fontWeight: 600,
                                    fontSize: '0.9rem',
                                  }}>
                                    <BedroomParentIcon fontSize="small" sx={{ marginRight: 0.5, color: '#000' }} /> {item.bedrooms} <BathroomIcon fontSize="small" sx={{ marginLeft: 2, marginRight: 0.5 }} /> {item.bathrooms} <SquareFootIcon fontSize="small" sx={{ marginLeft: 2, marginRight: 0.5, color: '#000' }} /> {item.size} Ft²
                                  </Typography>
                                </Grid2>
                                <Grid2 item xs={6} md={6} sx={{
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  display: 'flex',
                                }}>
                                  <Typography >
                                    {item.properties.length > 1 ? (
                                      <>
                                        <Chip color="info" size="small" label={`${item.offers} offers`} />
                                        {' '}from <b>{item.minPrice > 0 ? formatAmount(item.minPrice) : 'Ask for price'}</b> to <b>{`${formatAmount(item.maxPrice)} AED`}</b>
                                      </>
                                    ) : (
                                      <>
                                        <Chip color="info" size="small" label={`${item.offers} offer`} />
                                        {' '}at <b>{item.minPrice > 0 ? `${formatAmount(item.minPrice)} AED` : 'Ask for price'}</b>
                                      </>
                                    )}
                                  </Typography>
                                </Grid2>
                              </Grid2>
                            </AccordionSummary>
                            <AccordionDetails>
                              {item.properties.map((property, index) => (
                                <Link href={property.url} target="_blank">
                                  <Grid2 container sx={{
                                    width: '100%',
                                    padding: 2,
                                    '&:hover': {
                                      background: '#f8f8f8',
                                    }
                                  }}>
                                    <Grid2 item xs={8} md={8}>
                                      <Typography variant="h4">
                                        {property.title}
                                      </Typography>
                                      <Typography variant="subtitle1" mt={1} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        fontWeight: 400,
                                        fontSize: '0.9rem',
                                      }}>
                                        Listed on Property Finder
                                      </Typography>
                                    </Grid2>
                                    <Grid2 item xs={4} md={4} sx={{
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      display: 'flex'
                                    }}>
                                      <Typography ><b>{property.price > 0 ? `${formatNumber(property.price)} AED` : 'Ask for price'} </b></Typography>
                                    </Grid2>
                                  </Grid2>
                                  {index < item.properties.length - 1 && <Divider />}
                                </Link>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </>
                    ) : (
                      <NoData width={400} marginTop={0} />
                    )}
                </Box>
              </Grid2> */}



            </Grid2>
          </Grid2>
        </Container>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Exclusive access only
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Apologies, at the moment, this feature is accessible to a select group of users. To inquire about gaining access, please reach out to us at hello@mappi.ae.
          </Typography>
        </Box>
      </Modal>

    </>
  );
}

export default MarketDataEntry;